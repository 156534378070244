.right-menu > .navbar-nav {
	flex-direction:initial !important;
}
.right-menu > .navbar-nav > .nav-item{
	 padding: 5px;
}
.right-menu > .navbar-nav > .login{
	border: 1px solid #fff;
    border-radius: 5px;
	
}
.right-menu > .navbar-nav > .login > a{
	font-weight:300;
	opacity:1;
}