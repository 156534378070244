a.btn.btn-primary.dropdown-toggle.dropdown-toggless[aria-expanded="true"] {
	background-color: #432135 !important;
  }
  
  .dropdown-toggless[aria-expanded="true"] .right-caret:after {
	border-left: 5px solid #ffffff;
	margin-left: 6em !important;
	float: right;
	margin-top: 3px;
  }
  
  .dropdown-toggless:focus,
  .dropdown-toggless:hover {
	color: #ffffff !important;
  }
  
  ul {
	padding-left: 0;
	list-style-type: none;
	margin-bottom: 0;
  }
  
  *:focus {
	outline: none;
  }
  
  h1 {
	font-size: 40px;
  }
  
  h2 { 
	font-size: 18px;
  }
  
  h3 {
	font-size: 28px;
  }
  
  h4 {
	font-size: 20px;
  }
  
  h5 {
	font-size: 16px;
  }
  
  h6 {
	font-size: 14px;
  }
  
  p {
	font-size: 14px;
  }
  
  b,
  strong {
	font-family: NiveauGroteskBold;
	color: #000;
  }
  
  code {
	padding: 0px 3px;
	border-radius: 3px;
  }
  .card .card-header {
	background-color: transparent;
	border-bottom: none;
	padding: 0px 5px;
	position: relative;
	-webkit-box-shadow: 0px 1px 20px 0px rgba(69, 90, 100, 0.08);
	box-shadow: 0px 1px 20px 0px rgba(69, 90, 100, 0.08);
  }
  
  .modal.show {
	padding-right: 0px !important;
  }
  .card-footer {
	background: transparent;
  }
  
  button.close {
	margin-top: 7px;
	margin-bottom: 0;
  }
  
  .img-circle {
	border-radius: 50%;
  }
  
  .progress {
	height: 1rem;
  }
  
  .progress .progress-bar {
	height: 100% !important;
  }
  
  .nav-tabs .nav-link {
	color: #000;
	font-size: 13px;
	background-color: #fff;
	border-color: #ddd #ddd #ddd;
  }
  
  .tab-pills {
	border: 1px solid #ddd;
	border-top: 0px;
	padding: 10px;
  }
  
  .panel {
	width: 100%;
	border: 1px solid #ddd;
	padding: 5px 10px;
  }
  
  .tab-content {
	padding-top: 15px;
  }
  
  .btn-outline-secondary {
	padding: 6px 10px !important;
	border-radius: 0px !important;
  }
  
  @-moz-document url-prefix() {
	select {
	  -webkit-appearance: none !important;
	  -moz-appearance: none !important;
	  background: #fff url("/src/Assets/Images/select-arrow.png") no-repeat 95%
		center !important;
	}
  }
  
  .text-inverse {
	color: #565656 !important;
  }
  
  label .text-inverse {
	font-size: 12px;
	text-transform: uppercase;
	display: block;
	margin-left: 25px;
  }
  
  .text-muted {
	color: #565656 !important;
  }
  
  .label {
	color: #fff;
	font-size: 12px;
	text-transform: capitalize;
  }
  .label-default {
	background: #ddd;
  }
  .bg-primary {
	background-color: #432135 !important;
	color: #fff;
  }
  .header-navbar {
	height: 45px;
	padding: 0;
	box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%);
  }
  
  .navbar-logo {
	position: relative;
	padding: 0px 10px;
  }
  .navbar-logo > a {
	text-decoration: none;
  }
  
  .header-navbar .navbar-wrapper .navbar-logo h5 {
	margin-bottom: 0;
  }
  
  .header-navbar .navbar-wrapper .navbar-logo .mobile-options,
  .header-navbar .navbar-wrapper .navbar-logo .mobile-search {
	display: none;
  }
  
  .header-navbar .navbar-wrapper .navbar-container .nav-left,
  .header-navbar .navbar-wrapper .navbar-container .nav-right {
	float: left;
	margin-bottom: 0;
  }
  
  .header-navbar .navbar-wrapper .navbar-container .nav-left li,
  .header-navbar .navbar-wrapper .navbar-container .nav-right li {
	/*float: left;*/
	padding: 0 10px;
	position: relative;
  }
  
  .header-navbar .navbar-wrapper .navbar-container .nav-left li .sidebar_toggle a,
  .header-navbar
	.navbar-wrapper
	.navbar-container
	.nav-right
	li
	.sidebar_toggle
	a {
	display: none;
  }
  
  .header-navbar .navbar-wrapper .navbar-container .nav-left li > a,
  .header-navbar .navbar-wrapper .navbar-container .nav-right li > a {
	font-size: 16px;
	display: block;
	text-decoration: none;
  }
  
  .header-navbar .navbar-wrapper .navbar-container .nav-left a,
  .header-navbar .navbar-wrapper .navbar-container .nav-right a {
	padding: 0 0.6rem;
	text-decoration: none;
  }
  
  .header-navbar .navbar-wrapper .navbar-container .nav-right {
	float: right;
	padding: 5px 0px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-left: auto;
  }
  
  .header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile i {
	font-size: 10px;
	margin-left: 10px;
  }
  .header-navbar .navbar-wrapper .navbar-container .badge {
	border-radius: 100px;
	right: 20px;
	position: absolute;
	top: 14px;
	padding: 4px;
  }
  
  .sst {
	max-height: 100px;
	overflow-y: auto;
	padding: 0;
  }
  .btn-default .badge {
	background-color: #fff;
	color: #565656;
  }
  
  .btn {
	border-radius: 2px;
	font-size: 11px;
	padding: 10px 10px;
	cursor: pointer;
  }
  
  .close {
	cursor: pointer;
  }
  .btn i {
	margin-right: 5px;
  }
  
  .btn.btn-icon {
	border-radius: 50%;
	width: 40px;
	line-height: 30px;
	height: 40px;
	padding: 3px;
	text-align: center;
  }
  
  .btn-group,
  .btn-group-vertical {
	display: inline-block;
  }
  
  .btn-group .btn {
	float: left;
  }
  
  .icon-btn i {
	margin-right: 0 !important;
  }
  
  .show > .dropdown-menu {
	overflow: hidden;
  }
  
  .list-group-item.active {
	background-color: #432135;
	border-color: #432135;
  }
  
  .show > .btn-primary.dropdown-toggle,
  .sweet-alert .show > button.dropdown-toggle.confirm,
  .wizard > .actions .show > a.dropdown-toggle {
	background-color: #432135 !important;
	border-color: #432135;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #fff;
  }
  .btn.btn-disabled,
  .dropdown-disabled,
  .dropdown-split-disabled {
	cursor: not-allowed;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .nav-link.active:focus,
  .nav-pills .nav-link.active:hover,
  .nav-pills .nav-link.active,
  .nav-pills .nav-link.active:focus,
  .nav-pills .nav-link.active:hover,
  .nav-pills .nav-link.active.active,
  .nav-pills .nav-link.active.active:focus,
  .nav-pills .nav-link.active.active:hover {
	background: transparent;
	color: #432135;
	text-align: center;
	border-bottom: 1px solid #432135;
  }
  
  .sweet-alert button.confirm,
  .wizard > .actions a {
	background-color: #432135;
	border-color: #432135;
	color: #fff;
	cursor: pointer;
	-webkit-transition: all ease-in 0.3s;
	-o-transition: all ease-in 0.3s;
	transition: all ease-in 0.3s;
	padding: 5px 15px;
  }
  
  .navbar-logo a img {
	width: 130px;
  }
  
  .ti-angle-down {
	vertical-align: middle;
  }
  .mat-stat-card {
	width: 100%;
  }
  
  .sweet-alert button.confirm:hover,
  .wizard > .actions a:hover {
	background-color: #ffffff;
	border: 1px solid #432135 !important;
  }
  
  .btn-group .multiselect {
	background-color: #ffffff !important;
	border-bottom: 1px solid #ddd !important;
	color: #000 !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
  }
  
  .btn-group .multiselect:hover {
	background-color: #ffffff !important;
	border-bottom: 1px solid #ddd !important;
	color: #000 !important;
	-webkit-box-shadow: none;
	box-shadow: none;
  }
  
  .btn-primary:active,
  .sweet-alert button.confirm:active,
  .wizard > .actions a:active {
	background-color: #432135 !important;
	border-color: #432135 !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #fff;
  }
  
  .btn-primary:focus,
  .sweet-alert button.confirm:focus,
  .wizard > .actions a:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #fff;
	background-color: #432135;
  }
  
  .dropdown-primary .dropdown-menu a:hover,
  .dropdown-split-primary .dropdown-menu a:hover {
	background-color: #432135;
	color: #fff;
  }
  
  .form-group {
	margin-bottom: 1.25em;
  }
  
  .form-control {
	font-size: 14px;
	border-radius: 2px;
	border: 1px solid #dddddd;
  }
  
  .form-control:focus {
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #432135;
	color: #000;
  }
  
  .form-control:disabled {
	cursor: not-allowed;
	opacity: 0.5;
  }
  
  .left-img {
	width: 35px;
  }
  .radio-inline {
	display: inline-block;
	margin-right: 20px;
  }
  .checkbox-fade {
	display: inline-block;
  }
  
  .checkbox-fade > label {
	cursor: pointer;
	margin-bottom: 0;
  }
  .checkbox-fade label input[type="checkbox"],
  .checkbox-fade label input[type="radio"] {
	display: none;
  }
  
  .checkbox-fade label input[type="checkbox"]:checked + .cr > .bi-check,
  .checkbox-fade label input[type="radio"]:checked + .cr > .bi-check {
	opacity: 1;
  }
  
  .checkbox-fade label input[type="checkbox"] + .cr > .bi-check,
  .checkbox-fade label input[type="radio"] + .cr > .bi-check {
	opacity: 0;
  }
  
  .checkbox-fade label:after {
	content: "";
	display: table;
	clear: both;
  }
  
  .checkbox-fade.fade-in-disable .cr,
  .checkbox-fade.fade-in-disable label {
	color: #ddd;
	cursor: no-drop;
  }
  
  .checkbox-fade .cr {
	border-radius: 0;
	cursor: pointer;
	float: left;
	height: 16px;
	margin-right: 5px;
	position: relative;
	min-width: 16px;
	top: 1px;
  }
  
  .checkbox-fade .cr .bi-check {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	font-size: 14px;
	color: #432135;
  }
  .checkbox-fade label input[type="checkbox"],
  .checkbox-fade label input[type="radio"] {
	display: none;
  }
  
  .checkbox-fade label input[type="checkbox"]:checked + .cr > .cr-icon,
  .checkbox-fade label input[type="radio"]:checked + .cr > .cr-icon {
	opacity: 1;
  }
  
  .checkbox-fade label input[type="checkbox"] + .cr > .cr-icon,
  .checkbox-fade label input[type="radio"] + .cr > .cr-icon {
	opacity: 0;
  }
  
  .checkbox-fade.fade-in-primary .cr,
  .checkbox-fade.zoom-primary .cr {
	border: 1px solid #432135;
  }
  
  .checkbox-fade.fade-in-primary .cr .cr-icon,
  .checkbox-fade.zoom-primary .cr .cr-icon {
	color: #432135;
	font-size: 10px;
  }
  .checkbox-danger input[type="checkbox"]:checked + label::after,
  .checkbox-info input[type="checkbox"]:checked + label::after,
  .checkbox-primary input[type="checkbox"]:checked + label::after,
  .checkbox-success input[type="checkbox"]:checked + label::after,
  .checkbox-warning input[type="checkbox"]:checked + label::after {
	color: #fff;
  }
  select.form-control,
  select.form-control:focus,
  select.form-control:hover {
	border-top: none;
	border-right: none;
	border-left: none;
	-webkit-box-shadow: none;
	box-shadow: none;
  }
  
  .label {
	border-radius: 4px;
	font-size: 75%;
	padding: 3px 5px;
	margin-right: 3px;
	margin-top: 3px;
	display: inline-block;
  }
  
  .badge {
	border-radius: 10px;
	padding: 3px 7px;
  }
  .label-default {
	background-color: #ddd !important;
	border-color: #ddd;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #000;
  }
  span.badge {
	display: inline-block !important;
  }
  
  .proj-progress-card .progress {
	height: 6px;
	overflow: visible;
	margin-bottom: 10px;
  }
  
  .proj-progress-card .progress .progress-bar {
	position: relative;
  }
  
  .proj-progress-card .progress .progress-bar:after {
	content: "";
	background: #fff;
	position: absolute;
	right: -6px;
	top: -4px;
	border-radius: 50%;
	width: 15px;
	height: 15px;
  }
  
  .alert {
	font-size: 15px;
	margin-bottom: 2rem;
  }
  
  .close {
	font-size: 16px;
	margin-top: 5px;
  }
  
  .border-primary {
	border-color: transparent;
	border: 1px solid #ddd !important;
	border-radius: 0;
  }
  
  .footer {
	background-color: #565656;
	color: #fff;
	padding: 15px 0;
	position: fixed;
	bottom: 0;
	width: 100%;
  }
  .multiselect {
	background: #432135;
	color: #ffffff;
	width: 100%;
  }
  
  .lab {
	font-size: 12px;
  }
  
  .scrl {
	max-height: calc(100vh - 400px);
	overflow-y: auto;
	overflow-x: hidden;
  }
  .quiz {
	font-size: 15px !important;
	color: #432135;
	margin-bottom: 3px;
	margin-top: 10px;
  }
  
  .sub-quiz {
	font-size: 14px !important;
  }
  
  .btn-group {
	width: 100%;
	text-align: left;
  }
  
  .dropdown-toggle {
	text-align: left;
  }
  
  .multiselect-container li a {
	padding-left: 10px;
  }
  
  .multiselect-container {
	width: 100%;
  }
  
  .accordion .card-header:after {
	font-family: "FontAwesome";
	content: "\f068";
	float: right;
	margin-top: -23px;
  }
  
  .accordion .card-header.collapsed:after {
	content: "\f067";
  }
  
  .heads {
	background-color: #432135 !important;
	color: #ffffff;
	padding: 8px 10.5px !important;
  }
  
  .card-title {
	font-size: 15px !important;
  }
  
  .a1:after {
	content: "DE";
	height: 18px;
	background: #ffffff;
	width: 18px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #432135;
	font-size: 11px;
	border-radius: 3px;
	right: 20px;
	top: 3px;
	position: absolute;
  }
  
  .a2:after {
	content: "ES";
	height: 18px;
	background: #ffffff;
	width: 18px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #432135;
	font-size: 11px;
	border-radius: 3px;
	right: 20px;
	top: 3px;
	position: absolute;
  }
  
  .toast-header {
	border-bottom: 1px solid #fff;
	padding: 5px 6px;
	font-size: 12px;
  }
  
  .toast-header button.close {
	margin-top: 3px;
  }
  
  .toast-body {
	padding: 3px 6px;
	text-transform: capitalize;
	font-size: 12px;
  }
  
  .a4:after {
	content: "EX";
	height: 18px;
	background: #ffffff;
	width: 18px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #432135;
	font-size: 11px;
	border-radius: 3px;
	right: 20px;
	top: 3px;
	position: absolute;
  }
  
  .w-cross.a1:after,
  .w-cross.a2:after,
  .w-cross.a4:after {
	right: 5px;
	top: 3px;
  }
  
  .toast {
	margin-bottom: 10px;
  }
  
  .card-titles {
	font-size: 16px;
  }
  
  .square {
	background-color: #ffd2d2;
	width: 10px;
	height: 10px;
	margin-right: 10px;
  }
  
  .square1 {
	background-color: #bafbc3;
	width: 10px;
	height: 10px;
	margin-right: 10px;
  }
  
  .dropdown-menu > li {
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: pointer;
  }
  
  .dropdown-menu .sub-menu {
	left: 100%;
	position: absolute;
	top: 0;
	display: none;
	margin-top: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left-color: #fff;
	-webkit-box-shadow: none;
	box-shadow: none;
  }
  
  .right-caret:after,
  .left-caret:after {
	content: "";
	border-bottom: 5px solid transparent;
	border-top: 5px solid transparent;
	display: inline-block;
	height: 0;
	vertical-align: middle;
	width: 0;
	color: #fff;
  }
  
  .dropdown-toggless::after {
	display: none !important;
  }
  
  .dropdown-menu > li {
	padding: 5px !important;
  }
  
  .dropdown-toggless {
	background-color: #f5e4ec;
	border-color: #f5e4ec;
	border-left: 5px solid #432135;
	width: 100%;
	color: #432135;
	margin-bottom: 10px;
  }
  
  .card-titles .fa-file-alt,
  .card-titles .fa-eye {
	font-size: 14px;
	color: #432135;
	vertical-align: middle;
	margin-right: 10px;
  }
  
  .dropdown-toggless:hover,
  .dropdown-toggless:focus {
	background-color: #432135 !important;
	border-color: #432135 !important;
  }
  
  .show > .btn-primary.dropdown-toggless {
	background-color: #432135 !important;
	border-color: #432135 !important;
	color: #ffffff !important;
  }
  
  .test {
	position: absolute;
	-webkit-transform: translate3d(100%, 44px, 0px);
	transform: translate3d(100%, 44px, 0px);
	top: 0px;
	left: 100% !important;
	float: left;
	top: -47px !important;
	width: 198px;
	padding: 0px 0px 0px 5px !important;
	will-change: transform;
  }
  
  .btn-primary:not([disabled]):not(.disabled).active:focus,
  .btn-primary:not([disabled]):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
  }
  
  .right-caret:after {
	border-left: 5px solid #ffffff;
	margin-left: 6em !important;
	float: right;
	margin-top: 3px;
  }
  
  .left-caret:after {
	border-right: 5px solid #ffffff;
  }
  
  .square2 {
	background-color: #fedaaa;
	width: 10px;
	height: 10px;
	margin-right: 10px;
  }
  
  .square-text {
	font-size: 14px;
  }
  
  .wolli-message {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	margin-left: 20px;
  }
  
  .talk-bubble .talktext {
	display: inline-block;
	position: relative;
	width: 100%;
	height: auto;
	background-color: #ddd;
	border-radius: 3px;
	padding: 5px 10px;
  }
  
  .border {
	border: 8px solid #666;
  }
  
  .round {
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
  }
  
  .tri-right.border.left-in:before {
	content: " ";
	position: absolute;
	width: 0;
	height: 0;
	left: -40px;
	right: auto;
	top: 30px;
	bottom: auto;
	border: 20px solid;
	border-color: #666 #666 transparent transparent;
  }
  
  .talktext p {
	margin-bottom: 0px;
	text-transform: initial;
  }
  
  .tri-right.left-in #basicWonk:after,
  .tri-right.left-in .talktext:after,
  .tri-right.left-in #singleModalWonk:after,
  .tri-right.left-in #wonk:after,
  .tri-right.left-in #treeModalWonk:after,
  .tri-right.left-in #combinationModalWonk:after,
  .tri-right.left-in #alternativeModalWonk:after {
	content: " ";
	position: absolute;
	width: 0;
	height: 0;
	left: -18px;
	right: auto;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	bottom: auto;
	border: 10px solid;
	border-color: transparent #ddd transparent transparent;
  }
  
  .modal-content {
	border: none;
  }
  
  .modal-header,
  .modal-body,
  .modal-footer {
	background-color: #ffffff;
  }
  
  .modal-header {
	border-bottom: transparent;
	border-top-left-radius: 0rem !important;
	border-top-right-radius: 0rem !important;
  }
  
  .autocomplete {
	position: relative;
	display: inline-block;
  }
  
  .autocomplete-items {
	position: absolute;
	z-index: 99;
	top: 100%;
	left: 0;
	right: 0;
	max-height: 300px;
	overflow-y: auto;
  }
  
  .input-popup {
	border-left: transparent;
	border-right: transparent;
	border-top: transparent;
	border-radius: 0px;
  }
  
  .btn-outline-primary {
	color: #432135;
	background-color: #fff;
	background-color: transparent;
	border: 1px solid #432135;
  }
  
  .block {
	display: block;
	border: 1px solid #ddd;
	padding: 5px;
	margin-top: 5px;
  }
  
  .block:first-child {
	margin-top: 0;
  }
  
  .btn-outline-primary:hover {
	color: #ffffff;
	background-color: #fff;
	background-color: #432135;
  }
  
  .list-group-item-action:hover {
	background-color: #ddd !important;
	color: #432135 !important;
	cursor: pointer !important;
  }
  
  .list-group-item-action {
	color: #432135 !important;
	font-size: 14px;
	text-transform: capitalize;
	background-color: #fff !important;
  }
  
  .mbc-3 {
	margin-bottom: 0px !important;
  }
  
  .tree {
	background-image: url("/src/Assets/Images/treec.png");
	background-repeat: no-repeat;
	width: 100%;
	height: 45px;
	background-position: center center;
  }
  
  .tree:hover {
	background-image: url("/src/Assets/Images/treew.png");
	background-repeat: no-repeat;
	width: 100%;
	height: 45px;
	background-position: center center;
  }
  
  footer {
	border-top: 2px solid #432135;
	background-color: #e7dcdf;
  }
  
  #Decor,
  #Decor > ul {
	list-style-type: none;
	margin-left: 0;
	margin-bottom: 5px;
	padding: 0;
	cursor: pointer;
	white-space: nowrap;
	overflow-x: auto;
	max-height: 300px;
  }
  
  #Decor ul.last-list li {
	display: block;
	padding-bottom: 0;
  }
  
  #Decor ul.last-list li .resi {
	margin-bottom: 0;
  }
  
  #Decor ul.last-list li:before {
	display: none;
  }
  #Decor1,
  #Decor1 > ul {
	list-style-type: none;
	margin-left: 0;
	margin-bottom: 5px;
	padding: 0;
	cursor: pointer;
	white-space: nowrap;
	overflow-x: auto;
	max-height: 300px;
  }
  
  #Decor1 ul.last-list li {
	display: block;
	padding-bottom: 0;
  }
  
  #Decor1 ul.last-list li .resi {
	margin-bottom: 0;
  }
  
  #Decor1 ul.last-list li:before {
	display: none;
  }
  
  .tree-blk .icon {
	background: transparent url("/src/Assets/Images/plus-minus.png") no-repeat
	  left top;
	display: block;
	height: 12px;
	width: 12px;
	float: left;
	cursor: pointer;
	margin-top: 3px;
	margin-left: 2px;
  }
  
  .tree-blk .plus {
	background-position: left top;
	margin-top: 3px;
	margin-left: 2px;
  }
  
  .tree-blk .minus {
	background-position: left -10px;
	margin-top: 3px;
	margin-left: 2px;
  }
  
  .remove {
	right: 0;
	position: absolute;
	font-size: 9px;
	padding: 2px;
	color: #fff;
	background-color: #ff0000;
	top: 0;
	width: 13px;
	height: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: initial;
	cursor: pointer;
  }
  
  .remove-btn {
	float: right;
	position: relative;
	bottom: 5px;
	font-size: 9px;
	padding: 2px;
	left: 10px;
	color: #fff;
	background-color: #ff0000;
	cursor: pointer;
	width: 13px;
	height: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: initial;
  }
  
  .t-cpb {
	position: relative;
	left: -44px;
	top: -15px;
	display: flow-root;
	height: 64px;
	width: 40px;
	float: left;
	background-repeat: no-repeat;
	background-image: url("/src/Assets/Images/tree1.PNG");
  }
  
  .btn i.square-pl {
	vertical-align: middle;
	margin-right: 5px;
  }
  
  .add {
	float: left;
	padding-right: 5px;
	display: none;
  }
  
  .block p,
  .block h5,
  .block p label {
	margin-bottom: 0px;
	font-size: 14px;
	text-transform: capitalize;
	word-break: break-word;
  }
  
  .dropdowns-content {
	display: none;
	position: absolute;
	z-index: 999;
	right: -17px;
	border: 1px solid #ddd;
	min-width: 160px;
  }
  
  .dropbtn {
	cursor: pointer;
  }
  
  .show {
	display: block;
  }
  
  .tree-blk,
  .tree-blk ul,
  .tree-blk li {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
  }
  
  .tree-blk {
	margin: 0 0 1em;
	text-align: center;
  }
  
  .tree-blk,
  .tree-blk ul {
	display: block;
  }
  
  .tree-blk ul {
	width: 100%;
  }
  
  .checkbox-fade .pop-cr {
	margin-bottom: 0px;
	margin-top: 10px;
	margin-right: 5px;
  }
  
  .resi .checkbox-fade label {
	margin-bottom: 0px;
	width: 100%;
  }
  
  .tree-blk li {
	display: inline-block;
	padding: 0.5em 0;
	vertical-align: top;
  }
  
  .tree-blk li:before {
	outline: 1px solid #ceb6c1;
	content: "";
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
  }
  
  .tree-blk li:first-child:before {
	left: 50%;
  }
  
  .tree-blk li:last-child:before {
	right: 50%;
  }
  
  .tree-blk .resi,
  .tree-blk .resi {
	border: 1px solid #ceb6c1;
	border-radius: 0px;
	display: inline-block;
	margin: 0 0.2em 0.5em;
	padding: 0.2em 0.5em;
	position: relative;
  }
  
  .tree-blk .resi {
	padding-right: 0;
	padding-top: 0;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	padding-bottom: 0;
	max-width: 529px;
	width: 200px;
	font-size: 12px;
  }
  
  .btn-def {
	padding: 3px;
	border: 1px solid #ceb6c1;
	border-radius: 0px !important;
	border-right: 0px;
	border-top: 0px;
	border-bottom: 0px;
	float: right;
	width: 37px;
  }
  
  .pop-text {
	display: block;
	margin-top: 10px;
	margin-left: 25px;
	width: calc(100% - 30px);
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
  }
  
  .resi .checkbox-fade {
	width: 81%;
	text-align: left;
	margin-right: 0px !important;
	line-height: 30px;
  }
  
  .checkbox-fade .resi-lable {
	line-height: 16px;
  }
  
  .tree-blk ul:before,
  .tree-blk .resi:before,
  .tree-blk .resi:before {
	outline: 1px solid #ceb6c1;
	content: "";
	height: 0.5em;
	left: 50%;
	position: absolute;
  }
  
  .tree-blk ul:before {
	top: -0.5em;
  }
  
  .tree-blk .resi:before,
  .tree-blk .resi:before {
	top: -0.55em;
  }
  
  .cr::after {
	border-top: 0px solid transparent;
  }
  
  .tree-blk > li {
	margin-top: 0;
  }
  
  .tree-blk > li:before,
  .tree-blk > li:after,
  .tree-blk > li > .resi:before,
  .tree-blk > li > .resi:before {
	outline: none;
  }
  
  .tree-blk {
	width: 100%;
	padding: 0px 20px;
  }
  
  ::-webkit-scrollbar {
	width: 8px;
	height: 8px;
  }
  
  ::-webkit-scrollbar-track {
	background-color: #fff;
	border-radius: 10ppx;
  }
  
  ::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #ddd;
  }
  
  #author_bio_wrap_toggle {
	display: block;
	width: 37px;
  }
  
  .tittle-h2 {
	font-size: 16px;
  }
  
  .t1 {
	margin-top: 0px;
  }
  
  /* .slidecontainer {
	width: 80%;
	float: left;
  } */
  
  .slider {
	-webkit-appearance: none;
	width: 100%;
	height: 25px;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: 0.2s;
	-webkit-transition: opacity 0.2s;
	-o-transition: opacity 0.2s;
	transition: opacity 0.2s;
  }
  
  .slider:hover {
	opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	background: #4caf50;
	cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	background: #4caf50;
	cursor: pointer;
  }
  
  .no-pd-border {
	padding: 5px !important;
	border: 1px solid #ddd;
	background-color: #ffffff;
	float: left;
	height: 100%;
  }
  
  .tabl {
	border: 1px solid #c7c8c8;
  }
  
  th {
	background-color: #ddd;
	color: #000;
	font-size: 14px;
	font-weight: normal;
  }
  
  .ch-1,
  .ch-2,
  .ch-3,
  .ch-4 {
	font-size: 13px;
  }
  
  .ch-4 {
	text-align: left;
  }
  
  .float-right {
	float: right !important;
  }
  
  .float-rig {
	float: right !important;
	width: 16%;
  }
  
  .checkbox-fade.fade-in-primary .cr-popup {
	border: 2px solid #432135;
	margin-bottom: 0px;
	top: 2px;
  }
  
  .float-ri {
	float: right !important;
  }
  
  .list-line-active {
	background-color: #ddd;
	padding: 5px 10px;
	border: none !important;
  }
  
  .list-line-ul {
	border: 1px solid #ddd;
	border-radius: 4px;
  }
  
  .list-line-active {
	background-color: #ddd;
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
	border: none !important;
  }
  
  .list-line:last-child {
	margin-bottom: 0;
	border-bottom-right-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
  }
  
  .list-line {
	padding: 5px 10px;
	border: none !important;
	padding-bottom: 0px !important;
  }
  
  .btn-drp {
	width: 43px;
	float: right;
  }
  
  .list-lb {
	font-size: 12px;
	font-family: NiveauGroteskBold;
	float: left;
	width: 25%;
	margin-bottom: 5px;
	word-break: break-word;
  }
  
  .list-labl {
	font-size: 12px;
	width: 75%;
	display: inline-block;
  }
  
  .brdr {
	height: auto;
	overflow: hidden;
  }
  
  .ln {
	margin-top: 4px;
	margin-bottom: 1px;
  }
  
  .checkbox-fade.fade-in-primary .cr-list {
	border: 2px solid #432135;
	margin-bottom: 0px;
	top: 5px;
  }
  
  .list-line-active.active {
	background-color: #ddd !important;
	color: #000 !important;
  }
  
  .list-line.active {
	background-color: #fff !important;
	color: #000 !important;
  }
  
  .slidecontainer p {
	font-size: 13px;
	font-family: NiveauGroteskRegular;
	margin-bottom: 3px;
  }
  
  .float-rt {
	font-size: 12px;
  }
  
  .float-rt p {
	margin-bottom: 0px;
  }
  
  input[type="range"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 64%;
	height: 10px;
	padding: 0;
	border-radius: 2px;
	outline: none;
	cursor: pointer;
  }
  
  .range-text {
	width: 22px;
  }
  
  input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	-moz-appearance: none;
	-webkit-border-radius: 5px;
	height: 16px;
	width: 16px;
	border-radius: 5px;
	background: #000;
	border: 1px solid #c5c5c5;
  }
  
  input[type="range"]::-moz-range-thumb {
	-webkit-appearance: none;
	-moz-appearance: none;
	-moz-border-radius: 5px;
	height: 14px;
	width: 14px;
	border-radius: 5px;
	background: #e7e7e7;
	border: 1px solid #c5c5c5;
  }
  
  input[type="range"]::-ms-track {
	width: 300px;
	height: 6px;
	background: transparent;
	border-color: transparent;
	border-width: 2px 0;
	color: transparent;
  }
  
  input[type="range"]::-ms-thumb {
	height: 14px;
	width: 14px;
	border-radius: 5px;
	background: #e7e7e7;
	border: 1px solid #c5c5c5;
  }
  
  input[type="range"]::-ms-fill-lower {
	background: #432135;
	border-radius: 2px;
  }
  
  input[type="range"]::-ms-fill-upper {
	background: #000;
	border-radius: 2px;
  }
  
  input[type="range"]::-ms-tooltip {
	display: none;
  }
  /* .tooltip .tooltip__content {
	background-color: #432135;
	border-radius: 4px;
	bottom: 150%;
	color: white;
	left: 50%;
	margin-left: -50%;
	opacity: 0;
	position: absolute;
	text-align: center;
	-webkit-transition: opacity 0.5s;
	-o-transition: opacity 0.5s;
	transition: opacity 0.5s;
	visibility: hidden;
	width: 182px !important;
	z-index: 1;
  } */
  
  .tooltip .tooltip__content::after {
	border-width: 5px;
	border-style: solid;
	border-color: #432135 transparent transparent transparent;
	content: "";
	left: 50%;
	margin-left: -5px;
	position: absolute;
	top: 100%;
  }
  
  .tooltip:hover .tooltip__content {
	opacity: 1;
	visibility: visible;
  }
  .dropdown-menu {
	z-index: 9999;
  }
  .dropdown1 {
	width: 100%;
  }
  .heads .card-title {
	font-size: 11px;
  }
  .heads {
	line-height: 17px;
	margin: 0px !important;
	border: 0px !important;
	border-radius: 4px !important;
	-webkit-border-radius: 4px !important;
	-moz-border-radius: 4px !important;
	-ms-border-radius: 4px !important;
	-o-border-radius: 4px !important;
  }
  
  .range-text {
	border: none;
  }
  
  .show {
	display: block;
  }
  
  .dropdown-menu.notify-drop {
	min-width: 300px;
	background-color: #fff;
  }
  
  .btn-outline-secondary.focus,
  .btn-outline-secondary:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
  }
  
  .mx-n1 {
	margin-left: -0.25rem;
	margin-right: -0.25rem;
  }
  
  .btn-secondary.disabled,
  .btn-secondary:disabled {
	color: initial;
  }
  
  .multi-ripple {
	position: absolute;
	top: -15px;
	left: -20px;
  }
  
  .multi-ripple div {
	position: absolute;
	width: 80px;
	height: 15px;
	border-radius: 50%;
	border: 2px solid #bb0e77;
	-webkit-animation: 1.5s ripple ease infinite;
	animation: 1.5s ripple ease infinite;
  }
  
  .multi-ripple div:nth-child(2) {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
  }
  
  @-webkit-keyframes ripple {
	from {
	  -webkit-transform: scale(0);
	  transform: scale(0);
	  opacity: 1;
	}
	to {
	  -webkit-transform: scale(1);
	  transform: scale(1);
	  opacity: 0;
	}
  }
  
  @keyframes ripple {
	from {
	  -webkit-transform: scale(0);
	  transform: scale(0);
	  opacity: 1;
	}
	to {
	  -webkit-transform: scale(1);
	  transform: scale(1);
	  opacity: 0;
	}
  }
  
  .essentialsSubHead {
	text-transform: capitalize;
  }
  .prefSubHead {
	text-transform: capitalize;
  }
  .excSubHead {
	text-transform: capitalize;
  }
  .capatalizedText {
	text-transform: capitalize;
  }
  