
@media only screen   
and (min-width: 1030px)   
and (max-width: 1370px)  
{
  .listitem {
    height: 155px;
  }
  .resolution{
    font-size: 12px;
  }
}
@media screen and (min-width: 1600px) {
  body {
    font-size: 16px;
  }
  .header-navbar {
    height: 60px;
  }
  .header-navbar .navbar-wrapper {
    height: 100%;
  }

  /* style.css file  */
  .header-navbar .navbar-wrapper .navbar-container .nav-left li > a,
  .header-navbar .navbar-wrapper .navbar-container .nav-right li > a {
    font-size: 18px;
  }
  .header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile i {
    font-size: 12px;
  }
  .checkbox-fade .cr .bi-check {
    font-size: 16px;
  }
  .checkbox-fade.fade-in-primary .cr .cr-icon,
  .checkbox-fade.zoom-primary .cr .cr-icon {
    color: #432135;
    font-size: 12px;
  }
  .alert {
    font-size: 16px;
  }

  .quiz {
    font-size: 16px !important;
  }
  .card-titles {
    font-size: 16px;
  }

  .square-text {
    font-size: 15px;
  }

  .remove {
    font-size: 12px;
    width: 16px;
    height: 16px;
  }
  .tree-blk .resi {
    font-size: 14px;
  }
  th {
    font-size: 16px;
  }
  h5 {
    font-size: 16px;
  }
  .ch-1,
  .ch-2,
  .ch-3,
  .ch-4 {
    font-size: 16px;
  }
  .list-lb {
    font-size: 14px;
  }

  .list-labl {
    font-size: 14px;
  }
  .slidecontainer p {
    font-size: 16px;
  }

  .float-rt {
    font-size: 14px;
  }
  .heads .card-title {
    font-size: 14px;
  }

  .dropbtn {
    font-size: 12px;
  }
  .search-count {
    top: 8px;
    font-size: 14px;
  }
  .front-header a.nav-link {
    font-size: 15px;
  }
  .main-body .page-wrapper {
    height: calc(100vh - 98px);
  }
  .carousel h1 {
    font-size: 30px;
  }
  .carousel p {
    font-size: 16px;
  }
  .loginBox .sml-text {
    font-size: 14px;
  }
  .modal-title,
  .modal-header > h5 {
    font-size: 20px;
  }
  /* .listitem .projectLogo .btn-del {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .listitem .projectLogo .btn-clone {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .listitem .projectLogo .project-actions > span.filter {
    height: 30px;
    width: 30px;
    font-size: 16px;
  } */
  .filter1 label > span,
  .filter label > span {
    font-size: 12px;
    line-height: 24px;
    height: 26px;
  }
  .listitem .titleBar .title {
    font-size: 16px;
  }
  /* .listitem .projectLogo {
    height: 120px;
  } */

  .listitem .titleBar .btn-select {
    font-size: 14px;
  }
  .project-info-grid .items h5 {
    font-size: 18px;
  }
  .prjdetail .row label {
    font-size: 14px;
  }
  .tittle-h2 {
    font-size: 18px;
  }
  .tab-content {
    font-size: 16px;
  }
  .output-file div a {
    font-size: 18px;
  }
  .slidercontainer .steps {
    font-size: 14px;
  }
  .soft-footer {
    font-size: 14px;
  }
  .copyright {
    font-size: 14px;
    padding-top: 2px;
  }
  footer {
    font-size: 14px;
  }
  .close {
    font-size: 14px;
  }
  h6 {
    font-size: 16px;
  }
  .confirmTitle {
    font-size: 22px;
  }
  .error-group .error-msg {
    font-size: 14px;
  }
  .permissions a {
    font-size: 1.4em;
  }
  .labelInfo i,
  .tittle i {
    font-size: 16px;
  }
  .label {
    font-size: 14px;
  }
  h2 {
    font-size: 18px;
  }
  .error-left {
    font-size: 14px;
  }
  .error-db {
    font-size: 14px;
  }
  .error-left.cost-error {
    font-size: 14px;
  }
  .error-db.cost-error {
    font-size: 14px;
  }
  p.sliderRight {
    font-size: 18px;
  }
  .btn {
    font-size: 16px;
  }
  .proj-progress-card .btn-group .btn,
  .proj-progress-card .accordion .card-header .card-title {
    font-size: 16px !important;
  }
  .card .card-header span {
    font-size: 16px;
  }
  .card-titles .fa-file-alt,
  .card-titles .fa-eye {
    font-size: 16px;
  }
  .wcard h4.head {
    font-size: 16px;
  }
  label .text-inverse {
    font-size: 14px;
  }
  .search-faq .btn {
    font-size: 14px;
  }
  #combinationModal .nav-tabs .nav-link {
    font-size: 14px;
  }
  .opportunity-count {
    font-size: 14px;
  }

  .posdiv .center {
    top: 170px;
  }

  .opportunity-count span {
    font-size: 16px;
  }
  .suggestion-content .name h5 {
    font-size: 16px;
  }
  .suggestion-content li {
    font-size: 14px;
  }
  .suggestion-content li span.star-value {
    font-size: 16px;
  }
  .center-label h5 {
    font-size: 16px;
  }
  .tabs-right-side p > span {
    font-size: 30px;
  }
  .btn-outline-custom {
    font-size: 15px;
  }
  .header-navbar .navbar-wrapper .navbar-container .badge {
    font-size: 12px;
  }
  .vertical-tabs-wrapper .nav-pills {
    min-width: 200px;
    width: 200px;
  }
  .notify-drop ul li {
    font-size: 15px;
  }
  .more-notifications {
    font-size: 15px;
  }
  .notify-drop .date {
    font-size: 14px;
  }
  .user-grid .date {
    font-size: 14px;
  }
  .btn-default,
  .btn-secondary {
    font-size: 16px;
  }
  .btn-primary {
    font-size: 16px;
  }
  .fs-13 {
    font-size: 15px;
  }
  p {
    font-size: 16px;
  }
  .tooltip > .tooltip-inner,
  .custom-color-tooltip {
    font-size: 15px !important;
  }
  .tooltip > .tooltip-inner {
    font-size: 15px;
  }
  /* .posdiv {
    height: calc(100vh - 222px);
  } */
  .more-info {
    font-size: 16px;
  }
  .no-projects p {
    font-size: 22px;
  }
  .info .lagend-symbol {
    font-size: 14px;
  }
  .main-wrapper {
    height: calc(100vh - 60px);
  }
  .no-projects {
    height: calc(100vh - 206px);
  }
  .card .card-header .info .square-text {
    font-size: 15px;
  }
  .dropdown-item {
    font-size: 16px;
  }
  .header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile i {
    font-size: 16px !important;
  }
  .complex-component .text h6 {
    font-size: 14px;
  }
  .brand-lagend span {
    font-size: 30px;
  }
  .fixed-book-btn i:first-child {
    font-size: 20px;
  }
  .fixed-book-btn {
    right: -145px;
  }
  .guide-box p.message {
    font-size: 16px;
    line-height: 18px;
  }
  .close-guide-box {
    font-size: 24px;
  }
  .st-heading {
    font-size: 22px;
  }
  .st-sub-heading {
    font-size: 18px;
  }
  .nav-tabs .nav-link {
    font-size: 15px;
  }
  .upload-right-link {
    font-size: 16px;
  }
  .upload-right-link img {
    width: 150px;
  }
  .custom-file + label i {
    font-size: 36px;
  }
  .st-heading-small {
    font-size: 22px;
  }

  .st-sub-heading > i.fa-question-circle {
    font-size: 14px;
  }
  .tagline > i {
    font-size: 26px;
  }
  .tagline h3 {
    font-size: 30px;
  }
  .static-tabs.nav-pills .nav-link,
  .static-tabs.nav-pills .nav-link:focus,
  .static-tabs.nav-pills .nav-link:hover,
  .static-tabs.nav-pills .nav-link,
  .static-tabs.nav-pills .nav-link:focus,
  .static-tabs.nav-pills .nav-link:hover,
  .static-tabs.nav-pills .nav-link,
  .static-tabs.nav-pills .nav-link:focus,
  .static-tabs.nav-pills .nav-link:hover {
    font-size: 16px;
  }
  .accordion-faq .card .card-header {
    font-size: 16px;
  }
  .accordion-faq .card .card-header > span {
    font-size: 18px;
  }
  .accordion-faq button.card-header:after {
    font-size: 12px;
  }
  .top-actions p {
    font-size: 15px;
  }
  .top-actions button {
    font-size: 16px;
  }
  .small-btn {
    font-size: 14px;
  }
  .toast-header {
    font-size: 14px;
  }
  .a1:after {
    height: 20px;
    width: 20px;
    font-size: 13px;
  }

  .a2:after {
    height: 20px;
    width: 20px;
    font-size: 13px;
  }
  .a4:after {
    height: 20px;
    width: 20px;
    font-size: 13px;
  }
  .checkbox-style-btn label,
  .radio-style-btn label {
    font-size: 16px;
  }
  .table td {
    font-size: 15px;
  }
  .table th {
    font-size: 14px;
  }
  .Weightage-grid h5 {
    font-size: 15px;
  }
  .toast-body {
    font-size: 14px;
  }
  .Weightage-grid p {
    font-size: 13px;
  }
  .card.card-border h5 {
    font-size: 15px;
  }
  .card.card-border .card-header h4 {
    font-size: 16px;
  }
  .list-group-item-action {
    font-size: 16px;
  }
  .block p,
  .block h5,
  .block p label {
    font-size: 16px;
  }
  .remove-btn {
    font-size: 12px;
    width: 16px;
    height: 16px;
  }
  .watch-out-card .icon {
    font-size: 22px;
  }

  .watch-out-card a {
    font-size: 14px;
  }
  .table-auto {
    height: calc(100vh - 456px);
  }
  .sum-btn-wrapper .icon {
    min-width: 36px;
    height: 36px;
    font-size: 18px;
  }
  .mis-top-info .icon {
    width: 38px;
    height: 38px;
  }
  .mis-top-info .icon > img {
    width: 20px;
    height: 20px;
  }
  .mis-top-info .info h4 {
    font-size: 18px;
  }

  .mis-top-info .info p {
    font-size: 14px;
  }

  .mis-top-info button {
    font-size: 16px;
  }
  form label {
    font-size: 14px;
  }
  .form-control {
    font-size: 16px;
  }
  .lagend-symbol {
    height: 26px;
    font-size: 16px;
  }
  .t-savings-btn p {
    font-size: 16px;
  }

  .t-savings-btn h2 {
    font-size: 22px;
  }

  .contract-detail + .contract-detail {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
  }

  .badge {
    font-size: 13px;
  }
  .funnel-wrap .accretion-rate {
    font-size: 16px;
  }

  .funnel-wrap .total-project {
    font-size: 16px;
  }

  .funnel-wrap .closed-project {
    font-size: 16px;
  }
  .vertical-tabs-wrapper .nav-pills .nav-link {
    font-size: 16px;
  }
  .lab {
    font-size: 14px;
  }
  button.build-grid {
    font-size: 18px;
  }
  .vault-wrapper .card.card-border .card-body .count {
    font-size: 14px;
  }
  .btn-toggle:before,
  .btn-toggle:after {
    font-size: 14px;
  }
  .advance-filter-wrapper .dropdown-label {
    font-size: 14px;
  }
  .advance-filter-wrapper .dropdown > a {
    font-size: 16px;
  }
  .advance-filter-wrapper .dropdown-link {
    font-size: 16px;
  }
  .advance-filter-wrapper .dropdown-title {
    font-size: 15px;
  }
  .advance-filter-wrapper .dropdown-close,
  .advance-filter-wrapper .dropdown-back {
    font-size: 16px;
  }
  .welcome-wrapper .wolli-section .message h2 {
    font-size: 46px;
  }
  .welcome-wrapper .wolli-section .message h4 {
    font-size: 24px;
  }
  .welcome-wrapper .grid-component i {
    font-size: 64px;
  }
  .welcome-wrapper .grid-component h4 {
    font-size: 22px;
  }
  .welcome-wrapper .grid-component {
    width: 280px;
    height: 190px;
  }
  .welcome-wrapper .btn {
    font-size: 20px;
  }
  .progress-circular .progress-value span {
    font-size: 14px;
  }
  .faq-count {
    font-size: 14px;
  }
  .letterError {
    font-size: 14px;
  }
  .sub-quiz {
    font-size: 16px !important;
  }
  .conflict-wrapper .card-header {
    font-size: 18px;
  }
  .navbar-inner h5 button {
    font-size: 16px;
  }
  .navbar-inner h5 button i {
    font-size: 18px;
  }
  .navbar-inner .sub-nav-group ul li button {
    font-size: 16px;
  }
  .navbar-inner .nav-group h5 button:after {
    font-size: 12px;
  }
  .type-conflict > span {
    font-size: 14px;
  }
  .project-detail-modal p {
    font-size: 14px;
  }
  .project-detail-modal .modal-body h5 {
    font-size: 16px;
    margin: 0;
  }
  .contract-list-row label {
    font-size: 14px;
  }
  .navbar-inner {
    height: calc(100vh - 99px);
  }
  .logo-text {
    font-size: 12px;
  }
  .navbar-wrapper .logo-text {
    font-size: 10px;
  }
  .financials-grid h5 {
    font-size: 22px;
  }
  .compare-product-table tbody th {
    font-size: 16px;
  }
  .compare-product-table th {
    font-size: 16px;
  }
  .compare-product-table td {
    font-size: 16px;
  }
  .market-highlights .grid {
    font-size: 16px;
  }
  .market-highlights .grid > i {
    font-size: 22px;
  }
  .we-exist .grid small {
    font-size: 14px;
  }
  .we-exist .grid p {
    font-size: 15px;
  }
  .welcome-wrapper .wolli-section .wolli {
    width: 220px;
    height: 220px;
    border-radius: 300px;
    -webkit-border-radius: 300px;
    -moz-border-radius: 300px;
    -ms-border-radius: 300px;
    -o-border-radius: 300px;
  }
  .show-more-btn {
    font-size: 15px;
  }
  .wheel-lagends li {
    font-size: 14px;
  }
  .imp_pop_cross {
    font-size: 22px;
  }
  .accordion-filter .card h2 .btn-link {
    font-size: 15px;
  }
  .map-view span {
    font-size: 12px;
  }
  .insight-content .graph-grid h5 {
    font-size: 16px;
  }
  .rbc-day-slot .rbc-event {
    font-size: 12px !important;
  }
  .rbc-event-label {
    font-size: 12px !important;
  }
  .show-more-height {
    max-height: 85px;
  }
  .projectLogo img{
    width: 40%;
  }
}

@media screen and (min-width: 1921px) {
  html {
    background-color: #432135;
  }
  body {
    max-width: 1920px;
    margin: 0px auto;
    -webkit-box-shadow: -1px 1px 20px 0px rgba(67, 33, 0, 0.2);
    box-shadow: -1px 1px 20px 0px rgba(67, 33, 0, 0.2);
  }
  .main-wrapper {
    position: relative;
    overflow-x: hidden;
  }
  .unselectable {
    overflow-x: hidden;
    position: relative;
    border-left: 1px solid rgba(67, 33, 0, 1);
    border-right: 1px solid rgba(67, 33, 0, 1);
  }
  .fixed-book-btn {
    position: absolute;
  }
  .main-login {
    height: 100vh;
  }
  .main-wrapper.welcome-layout {
    height: 100vh;
    border-left: 1px solid rgba(67, 33, 0, 1);
    border-right: 1px solid rgba(67, 33, 0, 1);
  }
  body.ReactModal__Body--open {
    position: relative;
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open,
  .ReactModal__Content.ReactModal__Content--after-open {
    position: absolute !important;
  }
  .posdiv .center {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}
