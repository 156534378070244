@font-face {
	font-family: NiveauGroteskBold;
	src: url("fonts/NiveauGroteskBold.ttf");
	src: url("fonts/NiveauGroteskBold.woff2") format("woff2"),
		url("fonts/NiveauGroteskBold.woff") format("woff");
}

@font-face {
	font-family: NiveauGroteskRegular;
	src: url("fonts/NiveauGroteskRegular.ttf");
	src: url("fonts/NiveauGroteskRegular.woff2") format("woff2"),
		url("fonts/NiveauGroteskRegular.woff") format("woff");
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

* {
	margin: 0px;
	padding: 0px;
}

body {
	font-family: "NiveauGroteskRegular";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fff;
	font-size: 14px;
	overflow-x: hidden;
	color: #000;
	padding-right: 0px !important;
}

.main-wrapper {
	height: calc(100vh - 45px);
}

.bold {
	font-family: NiveauGroteskBold;
}

.main-content {
	float: left;
	width: calc(100% - 230px);
	transition: 0.25s linear;
}

.text-bold {
	font-family: "NiveauGroteskBold";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.card .card-block .dropdown-menu {
	left: 1px !important;
}

.proj-progress-card .card-block .dropdown-menu {
	top: 0px !important;
	overflow-y: auto;
	max-height: 220px;
	overflow-x: hidden;
}

.main-body .page-wrapper {
	padding: 10px;
	overflow-x: hidden;
	/* overflow-x: hidden; */
	height: calc(100vh - 76px);
	padding-left: 26px;
}

.center {
	margin: auto;
	width: 100%;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	font-size: 20px;
	z-index: 9;
	background: #fff;
	opacity: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	bottom: 0;
	top: 0px;
}

.modal-content .center {
	top: 0;
}

.center.hidden-heading {
	top: 0;
}

.center1 {
	margin: auto;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 20px;
	z-index: 99;
	background: rgba(255, 255, 255, 0.7);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.posdiv .center {
	z-index: 99;
	background: #fff;
	opacity: 1;
	position: fixed;
	top: 150px;
	left: 255px;
	right: 25px;
	bottom: 40px;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	width: auto;
	height: auto;
	margin: initial;
	margin: initial;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

div.loader .loader-wolli {
	position: relative;
}

.loaded {
	font-size: 1.2em;
	font-family: NiveauGroteskBold;
	margin-bottom: 10px;
}

::-webkit-scrollbar {
	z-index: 9;
	background: #fff;
}

.info {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.qactive {
	background: #84566b !important;
	border-color: #84566b !important;
}

#query {
	margin: 0px !important;
}

.dropdown1 {
	z-index: 2 !important;
}

.prjlogo {
	width: 100px;
}

.autocomplete-items {
	max-height: 240px;
	box-shadow: 0 1px 7px 0 rgb(69 90 100 / 24%);
	border: 1px solid #ddd;
	border-top: none;
}

.autocomplete-items-active {
	max-height: 320px;
	box-shadow: 0 1px 7px 0 rgb(69 90 100 / 24%);
	border: 1px solid #ddd;
	border-top: none;
}

.arow {
	width: 100px;
	float: left;
}

.arow .square1,
.arow .square2 {
	margin-top: 6px;
}

div#holder .block h5::before {
	position: absolute;
	top: -40px;
	height: 65px;
	width: 38px;
	border-bottom: 1px dashed #ddd;
	content: "";
	left: -38px;
	border-left: 1px dashed #ddd;
}

div#holder.nonActive .block h5:before {
	top: 0;
	height: 15px;
}

div#holder.nonActive1 .block h5:before {
	top: 0;
	height: 15px;
	left: -45px;
	width: 45px;
}

.dropbtn {
	margin-right: 5px;
	font-size: 10px;
}

#holder .block {
	position: relative;
}

.activeDose {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-left: 40px;
}

.activeDose input.form-control.input-popup {
	text-align: center;
}

.search-count {
	position: absolute;
	right: 0;
	top: 9px;
	font-size: 12px;
	color: #565656;
	z-index: 2;
	background: #fff;
	padding-left: 5px;
}

/*-----------Header Nav---------------*/

header {
	padding: 30px 15px;
}

.stickey {
	position: absolute;
	top: 0;
	width: 98%;
	z-index: 9;
}

.front-header li.nav-item {
	margin: 0px 0px 0px 40px;
}

.front-header a.nav-link {
	color: #fff !important;
	font-size: 13px;
	text-decoration: none;
	padding-left: 0;
	padding-right: 0;
	padding-top: 3px;
	padding-bottom: 3px;
	opacity: 0.7;
}

.front-header a.nav-link.active {
	border-bottom: 2px solid #fff;
	opacity: 1;
}

.front-header a.nav-link:hover {
	opacity: 1;
}

.front-header a.support-link {
	line-height: 0.85em;
}

.front-header a.support-link:hover {
	text-decoration: none;
	color: #efefef;
}

.front-header a.nav-link .question-mark {
	font-size: 1.65em;
	border: 2px solid #fff;
	border-radius: 50%;
	width: 28px;
	height: 28px;
	text-align: center;
	line-height: 24px;
	float: left;
	margin-right: 3px;
}

.front-header a.nav-link .need-help {
	font-size: 14px;
	text-transform: uppercase;
	font-family: NiveauGroteskBold;
	line-height: 16px;
}

/*----------- /Header Nav---------------*/

.carousel {
	min-height: 600px;
}

.carousel h1 {
	font-family: "NiveauGroteskBold";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-top: 0;
	font-size: 24px;
	margin-bottom: 10px;
	text-transform: none;
}

.carousel p {
	margin: 0;
}

.carousel-caption {
	top: 50%;
	left: initial;
	text-align: right;
	bottom: inherit;
	right: 45%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

/*-----------Login Box---------------*/

.loginBox {
	background: rgba(137, 80, 97, 0.2);
	padding: 15px 20px;
	color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100%;
	border-radius: 4px;
}

.loginBox form label {
	color: #fff;
}

.loginBox .sml-text:hover {
	text-decoration: none;
	color: #a9a9a9;
	cursor: pointer;
}

.loginBox .sml-text {
	font-size: 0.8em;
	text-decoration: none;
	color: #fff;
}

.loginBox .sml-text img {
	vertical-align: baseline;
}

.loginBox .login-btn {
	margin: 10px 0 15px 0;
}

.loginbox .form-control {
	background: none;
	border: 0;
	border-bottom: 1px solid #fff;
	color: #fff;
}

.loginBox .form-control,
.loginBox .form-control:focus {
	background: none;
	border: 0;
	border-bottom: 1px solid #fff;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #fff;
}

.btn-primary:hover {
	background: #84566b;
	border-color: #84566b;
	color: #fff !important;
}

.btn-primary:hover .icon {
	color: #84566b;
}

.btn-primary.disabled,
.btn-primary:disabled {
	background: #432135;
	border-color: #432135;
}

.table td,
.table th {
	text-align: center;
}

.card {
	border: 0;
}

.card-header {
	border: 1px solid #fff;
	margin: 2px 0;
	padding: 0;
}

.card-header button {
	text-align: left;
	display: inline;
}

.card-header h2 span {
	background: #432135;
	text-align: center;
	width: 40px;
	float: left;
	height: 40px;
	padding: 10px;
	border-radius: 50%;
	overflow: hidden;
	vertical-align: middle;
}

.card-header h2 img {
	width: 20px;
}

.btn-link {
	color: #000;
}

.btn-link:hover {
	color: #000;
}

.search-faq input {
	border-radius: 4px;
	padding: 6px 12px;
	margin-right: 10px;
	border: none;
}

.modal-content {
	border-radius: 0;
}

.modal-header .close {
	background: #000;
	color: #fff;
	margin: 0px;
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 14px;
	min-width: 14px;
	height: 14px;
	max-height: 14px;
	padding: 0px;
}

.close {
	font-size: 12px;
	line-height: 0.8em;
	opacity: 1;
	text-shadow: none;
}

.modal-header {
	border: 0;
	position: relative;
	padding-bottom: 12px;
}

.modal-title,
.modal-header>h5 {
	line-height: initial;
	font-family: NiveauGroteskBold;
	margin-bottom: 0px;
	font-size: 18px;
}

.modal-body {
	padding-bottom: 0;
	padding-top: 0px;
}

.modal-footer {
	border: 0;
	padding-top: 3px !important;
}

.form-control {
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid #ddd;
	padding-left: 0;
	color: #000;
}

input[type="text"].form-control {
	height: 34px;
}

.form-control:hover,
.form-control:active {
	border-color: #432135;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.wrapper {
	min-height: 100%;
	position: relative;
}

h1,
h2,
h3 {
	margin: 20px 0;
}

a {
	color: #432135;
	text-decoration: underline;
}

a:hover {
	color: #84566b;
}

a.notification {
	position: relative;
}

.dropdown-menu-right li {
	display: block;
	width: 100%;
}

a {
	cursor: pointer;
}

.newproj-empty {
	padding-top: 150px;
	min-height: 525px;
}

.btn-link {
	text-decoration: underline;
}

/*-----------Project List---------------*/

/* .searchbox {
	background: #fff;
	border: 1px solid #ddd;
	padding: 10px;
	border-radius: 4px;
  }
  
  .searchbox .form-inline label {
	text-align: left !important;
	-webkit-box-pack: left;
	-ms-flex-pack: left;
	justify-content: left;
	padding: 0px 5px 0px 0px;
	width: 100%;
	display: block;
  } */

.listitem {
	background: #fff;
	/* border: 1px solid #f3f3f3; */
	cursor: pointer;
	position: relative;
	border-radius: 20px;
	padding: 8px;
	/*height: 17vh;
	display: flex;
	*/
	display: flex;
	align-items: center;
	height: 183px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
	box-shadow: 0 1px 12px 0 #F8F4F7;
}

.listitem:hover {
	box-shadow: 0 1px 12px 0 rgb(69 90 100 / 10%);
}

.project-actions {
	display: flex;
	/* align-items: center;
	justify-content: flex-start;
	flex-direction: row-reverse;
	position: absolute;
	width: 100%;
	padding: 4px; */
	/* padding-left: 8px; */
	flex-direction: column;
}

.project-actions button {
	/* margin-right: 4px; */
	background-color: transparent;
	border: none;
	color: #565656 !important;
}

/* .listitem:hover {
	-webkit-box-shadow: 3px 3px 10px -5px #ddd;
	box-shadow: 3px 3px 10px -5px #ddd;
  } */
.listitem .projectLogo {
	/* height: 100%; */
	/* background-color: #f3f3f3; */
	/* border-right: 1px solid #f3f3f3; */
	/* padding-right: 8px; */
	min-width: calc(100% - 40px);
	max-width: calc(100% - 40px);
	/* width: 8vw; */
}

/* .listitem .projectLogo > img {
	padding: 7px;
  } */

.listitem .projectLogo .btn-del {
	position: static;
	border-radius: 4px;
	width: 23px;
	height: 23px;
	font-size: 14px;
	background-color: #fff;
}

/* .listitem .btn-del i {
	vertical-align: baseline;
  }
  .listitem .btn-clone {
	width: 23px;
	height: 23px;
	font-size: 14px;
	display: inline-flex;
	justify-content: center;
	padding: 0;
	align-items: center;
	background-color: #fff;
	margin-right: 4px;
  } */
.listitem .project-actions>span.filter {
	background-color: transparent;
	border: none;
	color: #565656 !important;
	font-size: 13px;
	padding: 5px 12px;
	cursor: initial;
	/*Navneet Commented*/
	display: block;
	text-align: center;
}

.listitem span.defining:after {
	content: "\f15c";
	font-family: "Font Awesome 5 Free";
}

.listitem span.feasability:after {
	content: "\f24e";
	font-family: "FontAwesome";
}

.listitem span.playermap:after {
	content: "\f1e5";
	font-family: "FontAwesome";
}

.listitem span.establish:after {
	content: "\f0c1";
	font-family: "FontAwesome";
}

.listitem span.playerevol:after {
	content: "\f0c0";
	font-family: "FontAwesome";
}

.listitem span.partnership:after {
	content: "\f2b5";
	font-family: "FontAwesome";
}

.listitem span.archive:after {
	content: "\f187";
	font-family: "FontAwesome";
}

/* .posdiv {
	overflow-y: auto;
	height: calc(100vh - 196px);
	overflow-x: hidden;
  } */
/* .posdiv .project-grid{
	  flex-basis: 15%;
	  max-width: 17%;
	  min-width: 15%;
  } */
.logomark {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	/* background: #fff; */
	/* border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px; */
}



.projectLogo img {
	width: 50%;
	height: 60px;
}

.searchbox .form-inline input {
	background: none;
}

.filter1 label>input,
.filter label>input {
	display: none;
}

.filter1 label>span,
.filter label>span {
	height: 30px;
	display: block;
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 4px;
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	font-size: 16px;
	width: 100%;
	width: 30px;
	line-height: 30px;
	margin-right: 16px;
	text-align: center;
}

.filter1 label>span:hover,
.filter label>span:hover {
	background-color: #f3f3f3;
}

.filter1 label>span {
	display: block;
	text-align: center;
}

.listitem .titleBar {
	font-size: 14px;
	line-height: 18px;
}

/* .queryInfo i {
	color: #565656;
  } */

.listitem .titleBar .title {
	/* font-size: 14px; */
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	overflow: hidden;
	/* white-space: nowrap; */
	margin: 10px 10px 0px 10px;
	text-align: center;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* number of lines to show */
	-webkit-box-orient: vertical;
	min-height: 38px;
}

.listitem .titleBar .title1 {
	/* font-size: 14px; */
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	overflow: hidden;
	/* white-space: nowrap; */
	margin: 10px 10px 0px 10px;
	text-align: left;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	/* number of lines to show */
	-webkit-box-orient: vertical;
}

.listitem .titleBar .btn-select {
	float: right;
	background: #dddddd;
	padding: 5px 10px;
	font-size: 0.8em;
}

.filter1 label>input:checked+span,
.filter label>input:checked+span {
	background-color: #fff;
	border: 1px solid #432135;
}

.filter,
.filter1 {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: pointer;
	position: relative;
	/* width: 12%; */
}

/*-----------/Project List---------------*/

.infoimage {
	display: inline-block;
	vertical-align: middle;
	margin: auto;
	text-align: center;
	width: 100%;
}

.project-info-grid .items {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 0px;
	flex: 1 1 0px;
	width: 20%;
}

.project-info-grid .items h5 {
	font-size: 14px;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.project-info-grid .items .p-l-0 {
	width: 100%;
}

.project-info-grid .items .d-flex {
	padding: 5px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

/*-----------Upload Template---------------*/

.prjlogo {
	background: #fff;
	border: 1px solid #ddd;
	padding: 10px 10px;
	height: 100px;
	display: table-cell;
	vertical-align: middle;
}

.prjlogo img {
	margin: auto;
}

.prjdetail .row label {
	font-size: 12px;
	display: block;
	margin: 0;
}

.upload-right-link {
	border: 1px solid #432135;
	display: inline-block;
	padding: 20px;
	background: #432135;
	color: #fff;
}

.upload-right-link img {
	padding-left: 20px;
	width: 130px;
}

.upload-right-link:hover {
	color: #fff;
}

/*-----------/Upload Template---------------*/

/*-----------Query Builder---------------*/

.dropdown1 .dropdown-item {
	padding: 0px;
}

.dropdown1 .checkbox-fade {
	margin-right: 0px;
}

.dropdown1 .checkbox-fade label {
	padding: 0.35rem 0.75rem;
	width: 100%;
	margin-bottom: 0px;
}

.panel {
	border: 1px solid #ddd;
	margin-top: 5px;
}

.tab-content {
	font-size: 14px;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.project-detail .tab-content {
	width: calc(100% - 185px);
}


.alert {
	margin: 10px auto;
	border-radius: 0;
}

/*-----------/Query Builder---------------*/

.myleft {
	width: 80%;
	text-align: left;
}

.proj-progress-card .dropdown-menu {
	-webkit-transform: translate(0px, 30px) !important;
	-ms-transform: translate(0px, 30px) !important;
	transform: translate(0px, 30px) !important;
}

.queryIndex .btn:hover {
	background-color: #84566b !important;
	border-color: #84566b !important;
}

/*-----------Create Project ------------*/
.ms-options-wrap>.ms-options>ul input[type="checkbox"] {
	position: static !important;
}

.ms-options-wrap>.ms-options>ul label {
	padding-left: 0 !important;
	margin: 0 !important;
	display: flex !important;
	width: 100% !important;
	align-items: center !important;
	cursor: pointer !important;
}

.ms-options-wrap>button {
	border-top: 0px;
	border-right: 0px;
	border-left: 0px;
	border-bottom: 1px solid #ddd;
}

.ms-options-wrap>.ms-options {
	border: 1px solid #ddd;
	padding-left: 10px;
	margin-top: 0px !important;
	min-height: auto !important;
}

.permissions div[data-toggle="buttons"] label {
	width: 31px !important;
	height: 31px !important;
	padding: 4px 4px;
	border: 0;
	margin-right: 2px;
}

.permissions div[data-toggle="buttons"] .btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
	background-color: #432135;
}

.output-file div a {
	z-index: 999;
	cursor: pointer;
	text-align: center;
	top: 2px;
	right: 8px;
	font-size: 16px;
	position: absolute;
}

.output-file div {
	text-align: center;
	padding: 8px;
	background-color: #43213515;

	display: block;
	color: #432135;
	border-radius: 4px;
	height: 99px;
	position: relative;
}

.output-file div.share-upload {
	border: 2px dashed #432135;
	width: 100%;
}

.output-file div img {
	height: 100%;
	width: 100%;
	object-fit: scale-down;
}

.output-file {
	display: none;
}

/*-----------/Create Project ------------*/

/*-----------Markets---------------*/

.table thead {
	background: #edecec;
}

/* .slidecontainer {
	width: 100%;
  } */
.slidecontainer {
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.slidecontainer:hover {
	cursor: pointer;
	background-color: #f3f3f3;
}

.slidercontainer .steps {
	padding: 0;
	margin: 0;
	list-style: none;
	font-size: 0.8em;
	margin-top: -5px;
}

.slidercontainer .steps li:first-child {
	float: left;
}

.slidercontainer .steps li:last-child {
	float: right;
}

input[type="range"]::-moz-range-progress {
	background-color: #432135;
	height: 10px;
}

/* IE*/

input[type="range"]::-ms-fill-lower {
	background-color: #432135;
	height: 10px;
}

.slider {
	margin-top: 10px;
	width: 100%;
	height: 10px;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: 0.2s;
	-webkit-transition: opacity 0.2s;
	-o-transition: opacity 0.2s;
	transition: opacity 0.2s;
	border-right: 0;
	border-left: 0;
}

.slider:hover {
	opacity: 1;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 13px;
	height: 13px;
	background: #000;
	border-radius: 0;
	cursor: pointer;
	border: 2px solid #d3d3d3;
}

.slider::-moz-range-thumb {
	width: 13px;
	height: 13px;
	background: #000;
	cursor: pointer;
	border-radius: 0;
	border: 2px solid #d3d3d3;
}

.table-bordered thead td,
.table-bordered thead th {
	border-bottom-width: 1px;
}

.table thead th {
	border-bottom: 1px solid #cac7c7;
}

/*-----------Footer---------------*/

.soft-footer {
	background: #ddd;
	color: #432135;
	z-index: 99;
	padding: 7px 10px;
	font-size: 0.9em;
	width: 100%;
	bottom: 0px;
}

.copyright {
	font-size: 12px;
	padding-top: 2px;
}

footer {
	background: #000;
	color: #f3f3f3;
	z-index: 9;
	padding: 8px 10px;
	font-size: 0.9em;
	position: absolute;
	width: 100%;
	bottom: 0px;
}

/*-----------/Footer---------------*/

.close {
	font-size: 12px;
	line-height: 0.8em;
	opacity: 1;
	text-shadow: none;
}

.confirmTitle {
	color: #432135;
	margin-top: 5px;
	font-size: 20px;
	font-family: "Quicksand", sans-serif;
	font-weight: 700;
	margin-bottom: 5px;
}

.btn {
	border-radius: 4px;
	padding: 5px 10px;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
	background: #432135;
	border-color: #432135;
}

.btn-primary:active:hover,
.btn-primary:focus:hover,
.btn-primary:not(:disabled):not(.disabled):active {
	background: #84566b;
	border-color: #84566b;
}

.form-control {
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid #ddd;
	padding-left: 0;
}

.error-group .form-control {
	border-color: #ff0000;
}

.error-group .error-msg {
	color: #ff0000;
	font-size: 12px;
	margin-top: 5px;
}

.error-msg {
	min-height: 17px;
}

.permissions a {
	background: #ebeaea;
	color: #a4a4a4;
	padding: 5px;
	font-size: 1.2em;
}

.permissions a:hover {
	background: #6f3858;
	color: #ffffff;
	padding: 5px;
}

.permissions a.active {
	background: #6f3858;
	color: #ffffff;
	padding: 5px;
}

.permissions a.del {
	background: #d41b1b;
	color: #ffffff;
	padding: 5px;
}

.permissions a.plus {
	background: #3dae15;
	color: #ffffff;
	padding: 5px;
}

.permissions .btn-del {
	padding: 0;
	width: 30px;
	height: 30px;
	background: transparent;
	color: #ff0000;
	position: relative;
	top: initial;
	border-radius: 4px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 5px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: 1px solid #ff0000;
}

.permissions .btn-plus {
	padding: 0;
	width: 30px;
	height: 30px;
	background: transparent;
	color: #3dae15;
	position: relative;
	margin-left: 0.5rem !important;
	top: initial;
	border-radius: 4px;
	border: 1px solid #3dae15;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 5px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.btn-plus:hover {
	background-color: #3dae1525;
}

.custom-select {
	position: relative;
	background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
	background-repeat: no-repeat !important;
	background-repeat: no-repeat !important;
	background-position-x: calc(100% - 5px) !important;
	background-position-y: 8px !important;
	background-size: 20px !important;
	height: 34px !important;
}

.labelInfo i,
.tittle i {
	font-size: 14px;
	margin-left: 5px;
}

.custom-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.custom-file+label {
	text-align: center;
	padding: 8px;
	background-color: #fff;
	border: 2px dashed #ddd;
	display: block;
	color: #565656;
	border-radius: 4px;
	height: 99px;
	cursor: pointer;
}

.custom-file+label i {
	font-size: 30px;
	margin-top: 10px;
}

.custom-file+label span {
	display: block;
	font-size: 14px;
	margin-top: 5px;
}

.custom-file:focus+label,
.custom-file+label:hover {
	background-color: #43213515;
}

.checkbox-style-btn input:disabled+label,
.checkbox-style-btn input:disabled+label::before,
.checkbox-style-btn input:disabled+label::after {
	cursor: not-allowed;
}

.btn.disabled,
.btn:disabled {
	cursor: not-allowed;
}

.disabled,
:disabled {
	cursor: not-allowed !important;
}

.btn-plus {
	padding: 0;
	width: 20px;
	height: 20px;
	background: transparent;
	color: #3dae15;
	position: absolute;
	margin-left: 25px !important;
	top: 10px;
	border-radius: 4px;
	border: 1px solid #3dae15;
}

.btn-plus.doseEnabled {
	background-color: #ddd;
	-webkit-animation: doseEnabled 500ms infinite;
	animation: doseEnabled 500ms infinite;
}

.combinationEnable {
	-webkit-animation: combinationEnable 1s infinite;
	animation: combinationEnable 1s infinite;
}

@-webkit-keyframes combinationEnable {
	50% {
		background-color: #432135;
		color: #fff;
	}
}

@keyframes combinationEnable {
	50% {
		background-color: #432135;
		color: #fff;
	}
}

@-webkit-keyframes doseEnabled {
	50% {
		background-color: #3dae1550;
	}
}

@keyframes doseEnabled {
	50% {
		background-color: #3dae1550;
	}
}

.btn-del {
	padding: 0;
	width: 20px;
	height: 20px;
	background: transparent;
	color: #ff0000;
	position: absolute;
	top: 10px;
	border: 1px solid #ff0000;
	border-radius: 4px;
}

.btn-edit {
	padding: 0;
	width: 20px;
	height: 20px;
	background: transparent;
	position: absolute;
	top: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-del:hover {
	background-color: #ff000025 !important;
}

.front-header {
	padding: 20px 15px 20px 15px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
	transition: padding 0.25s linear;
	position: relative;
	z-index: 99;
}

.header-notification .dropdown-toggle::after {
	content: none !important;
}

.footer-widget {
	margin-bottom: 0;
	height: 100%;
	width: 100%;
	border-radius: 4px;
	position: relative;
	background-color: #ddd;
	overflow: hidden;
}

.blinking {
	-webkit-animation: blinkingText 1s infinite;
	animation: blinkingText 1s infinite;
}

@-webkit-keyframes blinkingText {
	0% {
		color: "#ff0000";
	}

	49% {
		color: "#ff0000";
	}

	60% {
		color: transparent;
	}

	99% {
		color: transparent;
	}

	100% {
		color: transparent;
	}
}

@keyframes blinkingText {
	0% {
		color: "#ff0000";
	}

	49% {
		color: "#ff0000";
	}

	60% {
		color: transparent;
	}

	99% {
		color: transparent;
	}

	100% {
		color: transparent;
	}
}

h2 {
	font-size: 18px;
}

.error-msg .fa {
	margin-left: 5px;
}

.btn i {
	margin-right: 0px;
}

.error-msg .error-left {
	text-align: left;
}

.error-left {
	text-align: left;
	color: #ff0000;
	font-size: 12px;
}

.error-db {
	text-align: left;
	color: #fff;
	font-size: 12px;
}

.error-left.cost-error {
	background: rgba(255, 0, 0, 0.1);
	padding: 5px;
	text-align: center;
	font-size: 13px;
	border-radius: 4px;
	margin-bottom: 10px;
}

.error-db.cost-error {
	background: #421c35;
	padding: 5px;
	text-align: center;
	font-size: 13px;
	border-radius: 4px;
	margin-bottom: 10px;
}

.form-group {
	margin-bottom: 0.8rem;
}

.dropdown-menu.notify-drop {
	min-width: 235px;
}

.user-profile .img-radius {
	border-radius: 50%;
	width: 35px;
	height: 35px;
	display: inline-block;
	overflow: hidden;
	position: relative;
	vertical-align: middle;
	margin-right: 10px;
	background-color: #f0f0f0;
}

.user-profile .img-radius img {
	width: 100%;
	height: 100%;
	object-fit: scale-down;
}

.dropdown-menu button div {
	width: 100%;
}

.dropdowna .dropdown-menu li a {
	width: 100%;
	display: inline-block;
}

.leftWidth {
	width: 90%;
	display: inline-block;
}

.rightWidth {
	width: 10%;
	float: right;
}

.btn {
	font-size: 14px;
}

.heads {
	min-height: 43px;
}

.intervenList .checkbox-fade {
	margin-right: 0;
	width: 100%;
}

.intervenList {
	height: 260px;
	overflow-y: auto;
	overflow-x: hidden;
}

.intervenList label .text-inverse {
	text-transform: capitalize;
	width: 84% !important;
}

.intervenList button {
	padding: 0;
}

.intervenList label {
	width: 100%;
	padding: 0.25rem 0.5rem;
	text-align: left;
	margin: 0;
}

.intervenList label .cr {
	margin-right: 10px;
}

#combinationModal .intervenBlock {
	top: 45px;
}

#combinationModal .intervenList {
	height: 200px;
}

.dropdowna .btn-primary {
	margin-left: 0px;
}

.list-group-item.active {
	z-index: unset;
	text-transform: capitalize;
}

.slidecontainer input[type="range"] {
	width: 85%;
}

p.sliderLeft {
	margin-top: 5px;
	text-transform: capitalize;
	color: #565656;
}

p.sliderRight {
	font-size: 16px;
	color: #565656;
}

.table>thead>tr>th {
	text-align: center;
}

.staticPage footer {
	position: relative;
}

.staticPage {
	position: relative;
	min-height: 100vh;
}

header#stickey {
	position: sticky;
	top: 0px;
	z-index: 999;
}

.front-header.active {
	padding: 10px 15px;
	transition: padding 0.25s linear;
}

.infoimage {
	height: 50px;
}

/*------------------------------------------------------------Css Nav--------------------------------*/

/* * {
	-webkit-user-select: none;
	-webkit-touch-callout: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
} */

/* login  */

.main-login {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.login-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-height: calc(100vh - 140px);
}

.main-login .carousel {
	min-height: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.main-login .carousel-item:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(67, 33, 53, 0.9);
}

.main-login .carousel img {
	height: 100vh;
	object-fit: cover;
}

.loginBox .form-group span.icon-eye {
	position: absolute;
	top: 30px;
	right: 5px;
	cursor: pointer;
}

.loginBox p {
	margin-bottom: 0;
}

.error-msg:empty {
	display: none;
}

.dropdown-menu-right {
	left: initial;
	right: 0;
}

.card.proj-progress-card .card-block {
	padding: 10px;
}

.card.proj-progress-card .card-block .card-body {
	padding: 0;
	margin-top: 10px;
}

p.text-danger:empty {
	display: none;
}

.btn {
	font-size: 12px;
}

.form-group.text-right .btn-primary {
	margin-left: 7px;
}

.proj-progress-card .btn-group .btn,
.proj-progress-card .accordion .card-header .card-title {
	font-size: 14px !important;
	background: #432135;
	border-radius: 0px;
	color: #fff !important;
	border: 1px solid #432135;
	border-radius: 4px !important;
	-webkit-border-radius: 4px !important;
	-moz-border-radius: 4px !important;
	-ms-border-radius: 4px !important;
	-o-border-radius: 4px !important;
}

.proj-progress-card .accordion .card-header {
	min-height: initial;
	line-height: initial;
	cursor: pointer;
}

.proj-progress-card .accordion .card-header:after {
	margin-top: 3px;
}

.query_filter {
	margin-bottom: 10px;
}

.card .card-header span {
	display: flex;
	font-size: 14px;
	align-items: center;
	line-height: initial;
}

.m-b-0 {
	margin-bottom: 0px;
}

.query_filter.card .card-header {
	padding: 5px 5px;
}

.query_filter .toast-header .leftWidth {
	width: 85%;
}

.wcard h4.head {
	font-size: 14px;
	margin-bottom: 5px;
}

.wcard .dropdown-toggless {
	margin-bottom: 3px;
}

.wcard .dropdown-toggless.btn-primary {
	background: #432135;
	border-color: #432135;
}

.wcard .dropdown-toggless .right-caret:after {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	margin-top: 5px;
}

.wcard .test {
	-webkit-transform: initial !important;
	-ms-transform: initial !important;
	transform: initial !important;
	top: 28px !important;
	right: -1px !important;
	left: -1px !important;
	width: initial;
}

.search-faq .btn {
	font-size: 14px;
}

#holder .block {
	margin-top: 5px;
	margin-left: 30px;
}

#combinationModal .nav-tabs .nav-link {
	font-size: 12px;
}

#combinationModal .nav-tabs .nav-link>button {
	background: #d41b1b;
	border: none;
	width: 18px;
	height: 18px;
	color: #fff;
	border-radius: 3px;
	margin-right: 10px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

#combinationModal .btn-outline-secondary {
	padding: 2px 5px !important;
}

.optionBox label:empty {
	display: none;
}

/* profile page css */

.profile-pic {
	height: 150px;
	width: 100%;
	border: 1px solid #ddd;
	display: flex;
}

.profile-main .profile-pic img {
	margin: auto;
}

form .profile-main input[type="file"] {
	display: none;
	cursor: pointer;
}

.no-padding .checkbox-fade,
.no-padding .checkbox-fade label {
	width: 100%;
}

.blinkinfinite {
	background-color: #ddd;
	-webkit-animation: blink 800ms infinite;
	animation: blink 800ms infinite;
}

@-webkit-keyframes blink {
	50% {
		background-color: #f3f36d;
	}
}

@keyframes blink {
	50% {
		background-color: #f3f36d;
	}
}

.blinkinfinite:after {
	border-color: transparent #f3f36d transparent transparent;
	-webkit-animation: blinkafter 800ms infinite;
	animation: blinkafter 800ms infinite;
}

@-webkit-keyframes blinkafter {
	50% {
		border-color: transparent #f3f36d transparent transparent;
	}
}

@keyframes blinkafter {
	50% {
		border-color: transparent #f3f36d transparent transparent;
	}
}

.blinkonce {
	background-color: #ddd;
	-webkit-animation: blink 800ms 5;
	animation: blink 800ms 5;
}

@-webkit-keyframes blink {
	50% {
		background-color: #f3f36d;
		border-color: #f3f36d;
	}
}

@keyframes blink {
	50% {
		background-color: #f3f36d;
		border-color: #f3f36d;
	}
}

.blinkonce:after {
	border-color: transparent #ddd transparent transparent;
	-webkit-animation: blinkafter 800ms 5;
	animation: blinkafter 800ms 5;
}

@-webkit-keyframes blinkafter {
	50% {
		border-color: transparent #f3f36d transparent transparent;
	}
}

@keyframes blinkafter {
	50% {
		border-color: transparent #f3f36d transparent transparent;
	}
}

/* scheduler css  */

#contain {
	position: relative;
}

/* #scheduler {
				position: absolute;
				width: 570px;
				z-index: 1;
				background: #fff;
				top: -180px;
				left: calc(100% + 35px);
				display: none
			} */

.fc-daygrid-event-dot {
	display: none;
}

#scheduler::after {
	content: "";
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-right: 10px solid white;
	border-bottom: 10px solid transparent;
	position: absolute;
	top: 50%;
	left: -10px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

#scheduler .fc .fc-scroller {
	overflow: initial !important;
}

#scheduler .fc .fc-scrollgrid-section table {
	width: 100% !important;
}

.fc .fc-toolbar.fc-header-toolbar {
	margin-bottom: 10px !important;
	padding: 10px;
	background: #ddd;
	color: #000;
}

.fc .fc-button-group>.fc-button {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	background: transparent;
	color: #432135;
	border-color: #432135;
	padding: 3px 5px;
	outline: none;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.fc .fc-button-primary:disabled,
.fc .fc-button-primary:hover {
	background-color: #432135 !important;
}

.fc .fc-button .fc-icon {
	vertical-align: middle;
	font-size: 16px;
	height: auto;
	width: auto;
	line-height: 16px;
	margin-top: -3px;
}

#scheduler .fc .fc-button-primary:not(:disabled):active,
#scheduler .fc .fc-button-primary:not(:disabled).fc-button-active {
	background-color: #432135;
	border-color: #432135;
}

#closeCalendar {
	display: none;
}

.fc th,
.fc td {
	border: none;
	background: #fff;
}

.fc .fc-view-harness {
	position: relative;
	padding: 0 10px 10px 10px;
}

.fc-daygrid-event-dot {
	border-color: #fff !important;
}

.fc .fc-daygrid-event {
	z-index: 6;
	background: #432135;
	margin: 1px 2px 0px 2px !important;
	color: #fff;
	padding: 3px 5px;
	border-radius: 4px;
}

.fc-daygrid-dot-event {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start !important;
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}

.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
	background: rgb(90 10 47) !important;
}

.fc-event-time,
.fc-event-title {
	text-align: center;
	width: 100%;
}

.modal-content label .text-inverse {
	white-space: normal;
	width: 90%;
}

.card {
	-webkit-box-shadow: 0 1px 7px 0 rgba(69, 90, 100, 0.24);
	box-shadow: 0 1px 7px 0 rgba(69, 90, 100, 0.24);
	margin-bottom: 10px;
}

.opportunity-count {
	font-size: 12px;
	color: #565656;
}

.opportunity-count span {
	font-size: 14px;
}

.updated-value {
	color: #432135;
}

.rounded-tabs-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.rounded-tabs-wrapper-positioning {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.suggestion-tabs .nav-link {
	padding: 7px 12px;
	text-decoration: none;
}

.suggestion-tabs .nav-item.show .nav-link,
.suggestion-tabs .nav-link.active {
	color: #432135;
}

.suggestion-tabs.tabs .nav-item.show .nav-link,
.suggestion-tabs.tabs .nav-link.active {
	color: #fff;
	background-color: #432135;
	border-color: #432135;
	text-decoration: none;
	border-color: #ddd #ddd #ddd;
}

.combinationActiveTab {
	color: #fff !important;
	background-color: #432135 !important;
	border-color: #432135 !important;
	text-decoration: none !important;
}

.suggestion-tabs.tabs .nav-item .nav-link>button {
	background: #ddd;
	color: #000;
	width: 20px;
	height: 20px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 3px;
	margin-right: 5px;
	padding: 0;
}

.suggestion-tabs.tab-pills {
	border: 1px solid #ddd;
	border-top: 0;
	border-radius: 0 0 5px 5px;
	padding: 10px !important;
}

.round-checkbox {
	position: relative;
	width: 30px;
	min-width: 30px;
	height: 30px;
	margin-right: 10px;
}

.round-checkbox label {
	background-color: #e0b7cf;
	border-radius: 50%;
	cursor: pointer;
	height: 30px;
	left: 0;
	position: absolute;
	top: 0;
	width: 30px;
}

.round-checkbox label:after {
	border: 2px solid #fff;
	border-top: none;
	border-right: none;
	content: "";
	height: 6px;
	left: 9px;
	opacity: 0;
	position: absolute;
	top: 11px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	width: 12px;
}

.round-checkbox input[type="checkbox"] {
	visibility: hidden;
}

.round-checkbox input[type="checkbox"]:checked+label {
	background-color: #432135;
}

.round-checkbox input[type="checkbox"]:checked+label:after {
	opacity: 1;
}

.suggestion-box {
	margin-left: 10px;
}

.suggestion-item+.suggestion-item {
	margin-top: 3px;
}

.suggestion-item {
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.suggestion-content ul {
	line-height: initial;
}

.suggestion-item.with-hover {
	display: block;
}

.suggestion-item.disabled,
.suggestion-item:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

.suggestion-item.disabled .round-checkbox label,
.suggestion-item:disabled .round-checkbox label {
	cursor: not-allowed;
}

.suggestion-content {
	width: 100%;
}

.suggestion-content .name {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.suggestion-content .name h5 {
	margin: 0;
	color: #432135;
	font-size: 14px;
}

.suggestion-content li+li {
	padding-left: 5px;
	margin-left: 3px;
	border-left: 2px solid #ddd;
}

.suggestion-content li {
	display: inline;
	color: #565656;
	font-size: 12px;
}

.suggestion-content li span {
	color: #000;
	font-family: NiveauGroteskBold;
}

.suggestion-content li span.star-value {
	font-size: 14px;
	color: #432135;
	font-size: 20px;
	color: #432135;
	vertical-align: middle;
}

.suggestion-item.with-hover:hover {
	cursor: pointer;
	background-color: #ddd;
}

/* circle tabs style  */

.center-label {
	width: 180px;
	height: 180px;
	background: #e4e4e4;
	text-align: center;
	border-radius: 50%;
	overflow: hidden;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.center-label img {
	width: 40px;
}

.center-label h5 {
	margin-bottom: 0;
	margin-top: 5px;
	padding: 0px 5px;
	font-size: 14px;
}

.rounded-tabs .circle {
	position: relative;
	padding: 0;
	margin: 1em auto;
	width: 270px;
	height: 270px;
	border-radius: 50%;
	list-style: none;
	overflow: hidden;
}

.rounded-tabs .circle li {
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 50%;
	-webkit-transform-origin: 0% 100%;
	-ms-transform-origin: 0% 100%;
	transform-origin: 0% 100%;
	border: 2px solid #fff;
}

.center-label {
	width: 180px;
	height: 180px;
	background: #fff;
	text-align: center;
	border-radius: 50%;
	overflow: hidden;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.center-label img {
	width: 40px;
}

.center-label h5 {
	margin-bottom: 0;
	margin-top: 5px;
	padding: 0px 10px;
	font-size: 14px;
}

.rounded-tabs .circle .text {
	position: absolute;
	left: -50%;
	width: 150%;
	height: 200%;
	text-align: center;
	-webkit-transform: skewY(0deg) rotate(30deg);
	-ms-transform: skewY(0deg) rotate(30deg);
	transform: skewY(0deg) rotate(30deg);
	padding-top: 9px;
}

.rounded-tabs .circle>li:first-child {
	-webkit-transform: rotate(63.42deg) skewY(-38.5deg);
	-ms-transform: rotate(63.42deg) skewY(-38.5deg);
	transform: rotate(63.42deg) skewY(-38.5deg);
}

.rounded-tabs .circle>li:nth-child(2) {
	-webkit-transform: rotate(114.84deg) skewY(-38.5deg);
	-ms-transform: rotate(114.84deg) skewY(-38.5deg);
	transform: rotate(114.84deg) skewY(-38.5deg);
}

.rounded-tabs .circle>li:nth-child(3) {
	-webkit-transform: rotate(166.26deg) skewY(-38.5deg);
	-ms-transform: rotate(166.26deg) skewY(-38.5deg);
	transform: rotate(166.26deg) skewY(-38.5deg);
}

.rounded-tabs .circle>li:nth-child(4) {
	-webkit-transform: rotate(217.68deg) skewY(-38.5deg);
	-ms-transform: rotate(217.68deg) skewY(-38.5deg);
	transform: rotate(217.68deg) skewY(-38.5deg);
}

.rounded-tabs .circle>li:nth-child(5) {
	-webkit-transform: rotate(269.1deg) skewY(-38.5deg);
	-ms-transform: rotate(269.1deg) skewY(-38.5deg);
	transform: rotate(269.1deg) skewY(-38.5deg);
}

.rounded-tabs .circle>li:nth-child(6) {
	-webkit-transform: rotate(320.52deg) skewY(-38.5deg);
	-ms-transform: rotate(320.52deg) skewY(-38.5deg);
	transform: rotate(320.52deg) skewY(-38.5deg);
}

.rounded-tabs .circle>li:nth-child(7) {
	-webkit-transform: rotate(12deg) skewY(-38.5deg);
	-ms-transform: rotate(12deg) skewY(-38.5deg);
	transform: rotate(12deg) skewY(-38.5deg);
}

.rounded-tabs .circle li a i {
	-webkit-transform: rotate(-30deg) skewY(38.5deg);
	-ms-transform: rotate(-30deg) skewY(38.5deg);
	transform: rotate(-30deg) skewY(38.5deg);
	font-size: 20px;
	position: relative;
	top: 55px;
	left: -15px;
}

.rounded-tabs.nine-portions .circle>li:first-child {
	-webkit-transform: rotate(70deg) skewY(-50deg);
	-ms-transform: rotate(70deg) skewY(-50deg);
	transform: rotate(70deg) skewY(-50deg);
}

.rounded-tabs.nine-portions .circle>li:nth-child(2) {
	-webkit-transform: rotate(110deg) skewY(-50deg);
	-ms-transform: rotate(110deg) skewY(-50deg);
	transform: rotate(110deg) skewY(-50deg);
}

.rounded-tabs.nine-portions .circle>li:nth-child(3) {
	-webkit-transform: rotate(150deg) skewY(-50deg);
	-ms-transform: rotate(150deg) skewY(-50deg);
	transform: rotate(150deg) skewY(-50deg);
}

.rounded-tabs.nine-portions .circle>li:nth-child(4) {
	-webkit-transform: rotate(190deg) skewY(-50deg);
	-ms-transform: rotate(190deg) skewY(-50deg);
	transform: rotate(190deg) skewY(-50deg);
}

.rounded-tabs.nine-portions .circle>li:nth-child(5) {
	-webkit-transform: rotate(230deg) skewY(-50deg);
	-ms-transform: rotate(230deg) skewY(-50deg);
	transform: rotate(230deg) skewY(-50deg);
}

.rounded-tabs.nine-portions .circle>li:nth-child(6) {
	-webkit-transform: rotate(270deg) skewY(-50deg);
	-ms-transform: rotate(270deg) skewY(-50deg);
	transform: rotate(270deg) skewY(-50deg);
}

.rounded-tabs.nine-portions .circle>li:nth-child(7) {
	-webkit-transform: rotate(310deg) skewY(-50deg);
	-ms-transform: rotate(310deg) skewY(-50deg);
	transform: rotate(310deg) skewY(-50deg);
}

.rounded-tabs.nine-portions .circle>li:nth-child(8) {
	-webkit-transform: rotate(350deg) skewY(-50deg);
	-ms-transform: rotate(350deg) skewY(-50deg);
	transform: rotate(350deg) skewY(-50deg);
}

.rounded-tabs.nine-portions .circle>li:nth-child(9) {
	-webkit-transform: rotate(30deg) skewY(-50deg);
	-ms-transform: rotate(30deg) skewY(-50deg);
	transform: rotate(30deg) skewY(-50deg);
}

.rounded-tabs.nine-portions .circle li a i {
	-webkit-transform: rotate(0deg) skew(50deg);
	-ms-transform: rotate(0deg) skew(50deg);
	transform: rotate(0deg) skew(50deg);
	top: 60px;
	left: -25px;
}

.rounded-tabs .circle li a {
	background-color: #ddd;
	color: #565656;
}

.rounded-tabs .circle li.active a {
	background-color: #432135;
	color: #fff;
}

.tabs-right-side {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.tabs-right-side p>span {
	color: #432135;
	font-size: 26px;
	font-family: NiveauGroteskBold;
	vertical-align: middle;
	line-height: 0;
	margin-right: 7px;
	margin-top: 9px;
	display: inline-block;
}

.btn-outline-custom {
	background: transparent;
	color: #432135;
	font-size: 13px;
	padding: 3px 7px;
	border: 1px solid #432135;
	border-radius: 4px;
}

/* .btn-outline-custom:hover,
.btn-outline-custom:focus {
	outline: none;
	background-color: #ddd;
	-webkit-box-shadow: none;
	box-shadow: none;
} */


.btn-outline-custom:hover {
	outline: none;
	background-color: #ddd;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-outline-custom.compareButton {
	border: 1px solid #432135;
	background-color: #432135;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: white;
}


.tooltip .tooltip__content {
	background-color: #ffffff;
	border-radius: 4px;
	bottom: 100%;
	color: #565656;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	margin-left: initial;
	opacity: 0;
	position: absolute;
	text-align: initial;
	-webkit-transition: opacity 0.5s;
	-o-transition: opacity 0.5s;
	transition: opacity 0.5s;
	visibility: hidden;
	width: 210px !important;
	z-index: 1;
	padding: 5px 10px;
	-webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.22);
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.22);
}

.tooltip .tooltip__content li+li {
	border-top: 1px solid #ddd;
	padding-top: 5px;
}

.tooltip .tooltip__content li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 5px 0px;
}

.tooltip .tooltip__content li>span {
	color: #000;
}

.tooltip .tooltip__content::after {
	border-color: #ffffff transparent transparent transparent;
}

.header-navbar .navbar-wrapper .navbar-container .badge {
	border-radius: 100px;
	right: 10px;
	position: absolute;
	top: -5px;
	padding: initial;
	background: #432135;
	width: 16px;
	height: 16px;
	color: #fff;
	font-size: 10px;
	font-family: NiveauGroteskBold;
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.notify-drop.dropdown-menu {
	left: initial;
	right: 0;
	border: none;
	-webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.22);
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.22);
	padding: 0;
	margin: 0;
	width: 270px;
	margin-top: 10px;
}

.notify-drop.dropdown-menu ul {
	padding: 5px 5px 5px 5px;
	height: auto;
	overflow: hidden;
}

.notify-drop.dropdown-menu.keep-open {
	max-height: 400px;
	overflow-y: auto;
}

.notify-drop ul li+li {
	margin-top: 3px;
}

.notify-drop ul li {
	line-height: initial !important;
	font-size: 13px;
	color: #565656;
	background: whitesmoke;
	border-radius: 4px;
	cursor: pointer;
}

.notify-drop ul li.new {
	background-color: #fff1f7;
}

.notify-drop ul li:hover {
	background-color: #ddd;
}

.more-notifications {
	display: block;
	background: #fff;
	font-size: 13px;
	color: #432135;
	width: 100%;
	border: none;
	padding: 5px 5px 10px 5px;
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	right: 0;
	bottom: 0px;
	cursor: pointer;
}

.notify-drop li img {
	width: 30px;
	height: 30px;
}

.notify-drop li b {
	color: #000;
}

.notify-drop .date {
	font-size: 12px;
	margin-top: 3px;
	color: #b7b7b7;
}

.user-grid .info img {
	width: 30px;
	height: 30px;
}

.user-grid .info {
	color: #000;
}

.user-grid .date {
	font-size: 12px;
	color: #565656;
}

.user-grid+.user-grid {
	margin-top: 5px;
}

#saved-suggestions .modal-body,
#allsaved-suggestions .modal-body {
	max-height: 400px;
	overflow-y: auto;
}

.btn-default,
.btn-secondary {
	background-color: #ddd;
	color: #000 !important;
	font-size: 13px;
	padding: 5px 12px;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-default:hover,
.btn-secondary:hover {
	background-color: #84566b;
	border-color: #84566b;
	color: #ffffff !important;
}

.btn-primary {
	background: #432135;
	color: #fff !important;
	font-size: 13px;
	padding: 5px 12px;
	border: 1px solid #432135;
	border-radius: 4px;
	-webkit-box-shadow: none;
	box-shadow: none;
	text-decoration: none;
	font-family: NiveauGroteskRegular;
}

.dropup .dropdown-toggle:after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.125em;
	content: "";
	border-top: 0;
	position: static;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
	font-size: initial;
	color: #432135;
}

.btn-default:hover:after {
	color: #fff;
}

.fs-13 {
	font-size: 13px;
}

.tooltip>.tooltip-inner,
.custom-color-tooltip {
	font-size: 13px !important;
	padding: 3px 5px !important;
	background: #000 !important;
	opacity: 1 !important;
	color: #fff !important;
	z-index: 999 !important;
	font-family: NiveauGroteskRegular;
	white-space: normal;
	text-align: left;
}

.custom-color-tooltip.place-top::after {
	border-top-color: #000 !important;
}

.slider-new {
	pointer-events: none;
}

.ui-slider-horizontal {
	border-radius: 30px;
}

.ui-slider-horizontal .ui-slider-handle:last-child {
	display: none;
}

.ui-widget-content {
	border: none;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	border: 1px solid #565656;
}

.ui-slider-horizontal .ui-slider-handle {
	top: -3px;
	background-color: #ddd;
	border-color: #565656;
}

.range-text {
	text-align: right;
}

/* project list css  */

button.clear-icon {
	position: absolute;
	right: 15px;
	background: none;
	border: none;
	outline: none;
	padding: 0;
	margin: 0;
	color: #455a73;
	top: 5px;
	cursor: pointer;
}

.searchtext:not(:valid)~.clear-icon {
	display: none;
}

.tooltip>.tooltip-inner {
	font-size: 13px;
	padding: 2px 5px 3px 5px;
	text-align: left;
	color: #fff;
	line-height: 20px;
}

.tooltip-inner,
.custom-color-tooltip {
	max-width: 400px !important;
}

.custom-color-tooltip.insights-tooltip {
	max-width: 200px !important;
}

.st-heading .tooltip-inner,
.st-heading .custom-color-tooltip {
	text-transform: initial;
}

.more-info {
	border: none;
	background-color: transparent;
	font-size: 14px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #432135;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-left: 5px;
	vertical-align: middle;
	cursor: pointer;
}

ul.default-list {
	padding-left: 20px;
	list-style-type: disc;
	margin-bottom: 0;
}

.carousel-inner {
	background: #432135;
}

.tooltip.tooltip-custom {
	z-index: 2 !important;
	position: relative;
	opacity: 1;
}

.tooltip {
	z-index: 9999 !important;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 5px;
}

.no-projects {
	height: calc(100vh - 195px);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.no-projects p {
	margin-top: 15px;
	margin-bottom: 0;
	color: #000000;
	font-size: 16px;
}

/* Edit for export */

#imageContainer .tooltip__content li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 5px 0px;
}

#imageContainer .tooltip .tooltip__content li+li {
	border-top: 1px solid #ddd;
	padding-top: 5px;
}

#imageContainer .tooltip__content li>span {
	color: #000;
	background: #ddd;
	display: inline-block;
	padding: 0px 10px;
	border-radius: 30px;
}

.btn-outline-custom.color-grey {
	border-color: #ddd;
	color: #565656;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.btn-outline-custom.color-grey.selected-btn {
	background-color: #ddd;
	color: #432135;
	border-color: #432135;
}

.btn-outline-custom.color-grey svg {
	margin-right: 5px;
}

.btn-outline-custom.color-grey.selected-btn svg path {
	fill: #432135;
}

.info .lagend-symbol {
	width: 18px;
	height: 18px;
	border-radius: 4px;
	font-size: 10px;
	margin-right: 3px;
	border: 1px solid #ddd;
	line-height: initial;
	display: flex;
	align-items: center;
	justify-content: center;
}

.card .card-header .info .square-text {
	font-size: 13px;
}

.table-auto {
	height: calc(100vh - 423px);
	position: relative;
	overflow: auto;
	display: block;
	min-height: 200px;
}

.table-auto1 {
	height: calc(100vh - 450px);
	position: relative;
	overflow: auto;
	display: block;
	min-height: 200px;
}

.table-auto2 {
	height: calc(100vh - 350px);
	position: relative;
	overflow: auto;
	display: block;
	min-height: 200px;
}

.table-toggle {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 99999;
	background: #fff;
	padding: 15px;
}

.table-toggle .table-auto {
	height: calc(100vh - 60px);
}

.table-toggle .table-auto1 {
	height: calc(100vh - 110px);
}

.graph-height {
	/* height: calc(100vh - 345px); */
	height: calc(100vh - 367px);
	position: relative;
}

.graph-height.filter-applied {
	/* height: calc(100vh - 370px); */
	height: calc(100vh - 389px);
	position: relative;
}

.ps-graph-height {
	height: calc(100vh - 364px);
	/* height: calc(100vh - 345px); */
	position: relative;
}

.ps-graph-height.filter-applied {
	/* height: calc(100vh - 370px); */
	height: calc(100vh - 389px);
	position: relative;
}

.landscape-assets {
	height: calc(100vh - 220px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.landscape-assets1 {
	height: calc(100vh - 180px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

/*ideaation graph height by Navneet*/
.opportunity-capability-eco-comside .card-body {
	height: calc(100vh - 228px);
	overflow-y: auto;
	overflow-x: hidden;
}

.graph-toggle .opportunity-capability-eco-comside .card-body {
	height: calc(100vh - 90px);
	overflow-y: auto;
	overflow-x: hidden;
}

.opportunity-capability-eco {
	height: calc(100vh - 255px);
	width: 100%;
	overflow: hidden;
}

.graph-toggle .opportunity-capability-eco {
	height: calc(100vh - 120px);
	width: 100%;
	overflow: hidden;
}

.graph-toggle .opportunity-capability-eco.filter-applied {
	height: calc(100vh - 140px);
	width: 100%;
	overflow: hidden;
}

.opportunity-capability-eco.filter-applied {
	height: calc(100vh - 280px);
	width: 100%;
	overflow: hidden;
}

.landscape-product-graph {
	height: calc(100vh - 350px);
	width: 100%;
	overflow: hidden;
}

.ideation-graph-height {
	height: calc(100vh - 330px);
	width: 100%;
	overflow: hidden;
}

.ideation-graph-height-vault {
	height: calc(100vh - 330px);
	width: 100%;
	overflow: hidden;
}

.graph-toggle .ideation-graph-height {
	height: calc(100vh - 145px);
}

.ideation-graph-height.filter-applied {
	height: calc(100vh - 350px);
	width: 100%;
	overflow: hidden;
}

.ideation-graph-height-vault.filter-applied {
	height: calc(100vh - 350px);
	width: 100%;
	overflow: hidden;
}

.graph-toggle .ideation-graph-height.filter-applied {
	height: calc(100vh - 165px);
	width: 100%;
	overflow: hidden;
}

.landscape-comparecomp-side .card-body {
	height: calc(100vh - 303px);
	overflow-y: auto;
	overflow-x: hidden;
}

.landscape-comparecomp-side-st .card-body {
	height: calc(100vh - 278px);
	overflow-y: auto;
	overflow-x: hidden;
}

.graph-toggle .landscape-comparecomp-side .card-body {
	height: calc(100vh - 115px);
	overflow-y: auto;
	overflow-x: hidden;
}

.vm-graph-height {
	height: calc(100vh - 244px);
	width: 100%;
	overflow: hidden;
}

.graph-toggle .vm-graph-height {
	height: calc(100vh - 106px);
}

.vm-graph-height-st {
	height: calc(100vh - 284px);
	width: 100%;
	overflow: hidden;
}

.graph-toggle .vm-graph-height-st.filter-applied {
	height: calc(100vh - 205px);
}


.pc-graph-height {
	height: calc(100vh - 342px);
	overflow-y: auto;
}

.graph-toggle .pc-graph-height {
	height: calc(100vh - 98px);
}

.created-by {
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 2;
	font-size: 11px;
	color: #565656;
	font-style: italic;
	padding: 5px 0px;
	min-width: 60px;
	min-height: 30px;
}

a[href="https://www.amcharts.com/"] {
	display: none;
	visibility: hidden;
	opacity: 0;
}

.graph-toggle {
	position: fixed !important;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 999;
	background: #fff;
	padding: 15px;
}

.graph-toggle .graph-height {
	/* height: calc(100vh - 102px); */
	height: calc(100vh - 120px);
}

.graph-toggle .graph-height.filter-applied {
	height: calc(100vh - 140px);
	/* height: calc(100vh - 128px); */
}

.graph-toggle .ps-graph-height {
	/* height: calc(100vh - 102px); */
	height: calc(100vh - 120px);
}

.graph-toggle .ps-graph-height.filter-applied {
	height: calc(100vh - 140px);
	/* height: calc(100vh - 128px); */
}

/* scroll to css  */

.scroll-to-top {
	position: fixed;
	z-index: 999999;
	width: 45px;
	height: 45px;
	background: #432135;
	border-radius: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	right: 15px;
	bottom: 45px;
	color: #fff;
	cursor: pointer;
}

.scroll-to-top:hover {
	background-color: #84566b;
}

.table-bordered td,
.table-bordered th {
	border: 1px solid #cac7c7;
}

.dropdown-item:focus,
.dropdown-item:hover {
	color: #000;
	text-decoration: none;
	background-color: #ddd;
}

.essentialsSubHead .dropdown-menu {
	width: 100%;
	padding: 0;
	margin: 0;
}

.dropdown-item {
	color: #000;
	padding: 5px 7px;
	text-decoration: none;
	font-size: 14px;
}

/* modal css  */

.animated-steps {
	padding: 50px;
}

.animated-steps .item {
	width: 100px;
	height: 100px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 50px;
	background: #f3f3f3;
	position: relative;
	opacity: 0.2;
}

.animated-steps .item .name {
	position: absolute;
	bottom: -25px;
	white-space: nowrap;
}

.animated-steps .item .check-icon {
	position: absolute;
	top: 2px;
	right: 6px;
	display: none;
}

.animated-steps .line {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	border-top: 3px dashed #f3f3f3;
}

.animated-steps .item.active,
.animated-steps .item.done {
	opacity: 1;
}

.animated-steps .item.active .check-icon {
	display: block;
}

.bar-fill {
	display: block;
	height: 5px;
	background-color: #98366f;
	-webkit-animation: bar-fill 7s linear;
	animation: bar-fill 7s linear;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 9;
}

@keyframes bar-fill {
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}

@-webkit-keyframes bar-fill {
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}

/*Trade off & complexity*/

.ui-slider-horizontal .ui-slider-handle:last-child:first-child {
	display: block;
}

.ruler ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 25px 0px 20px 0px;
}

.ruler ul li {
	background: #ccc;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	height: 1px;
	text-align: center;
	position: relative;
}

.ruler ul li:after {
	content: "";
	position: absolute;
	width: 1px;
	height: 10px;
	background: #ccc;
	top: -5px;
	left: 0;
}

.ruler ul li:before {
	content: "";
	position: absolute;
	width: 1px;
	height: 10px;
	background: #ccc;
	top: -5px;
	right: 0;
}

.ruler ul li span {
	position: absolute;
	left: 50%;
	display: block;
	transform: translateX(-50%);
	top: -25px;
	width: 100%;
	white-space: nowrap;
	font-size: 12px;
}

#custom-handle.custome-handle-big {
	min-width: 28px;
	height: 24px;
	top: 50%;
	margin-top: -12px;
	text-align: center;
	background: #fff;
	color: #432135;
	font-size: 13px;
	width: auto;
	line-height: inherit;
}

.auto-counter {
	counter-reset: my-sec-counter;
}

.complex-component {
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 7px;
}

.complex-component .item-name {
	border-bottom: 1px solid #ddd;
	padding: 0px 7px;
}

.complex-component .item-name h6::before {
	counter-increment: my-sec-counter;
	content: counter(my-sec-counter) ". ";
	margin-right: 3px;
}

.text-primary {
	color: #432135 !important;
}

.complex-component .text h6 {
	font-size: 12px;
}

.flex-grow-1 {
	-ms-flex-positive: 1 !important;
	-webkit-box-flex: 1 !important;
	flex-grow: 1 !important;
}

.overflow-auto-x {
	overflow-x: auto;
}

.addReadMore.showlesscontent .SecSec,
.addReadMore.showlesscontent .readLess {
	display: none;
}

.addReadMore.showmorecontent .readMore {
	display: none;
}

.addReadMore .readMore,
.addReadMore .readLess {
	font-weight: 600;
	margin-left: 2px;
	color: #432135;
	cursor: pointer;
}

.addReadMoreWrapTxt.showmorecontent .SecSec,
.addReadMoreWrapTxt.showmorecontent .readLess {
	display: block;
}

.custom-div .col-md-3 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 calc(25% - 6px);
	flex: 0 0 calc(25% - 6px);
}

.custom-div .col-md-3+.col-md-3 {
	margin-left: 6px;
}

.confirmation-modal .modal-dialog {
	max-width: 420px;
}

.brand-lagend span {
	color: #432135;
	font-size: 26px;
	font-family: NiveauGroteskBold;
	vertical-align: middle;
	line-height: 0;
	margin-right: 7px;
	margin-top: 9px;
	display: inline-block;
}

.table>thead>tr>th {
	vertical-align: middle;
}

.table td,
.table th {
	vertical-align: middle;
}

.negative-lagend span {
	width: 12px;
	height: 12px;
	background-color: #ffdcdc;
	display: inline-block;
	margin-right: 5px;
}

.bg-restrictive {
	background-color: #ffdcdc;
}

.complexity-table {
	table-layout: fixed;
}

.complexity-table td>div {
	color: #421c35;
}

.complexity-table td>div[data-tip="true"] {
	cursor: pointer;
}

/***** RADIO BUTTON STYLES *****/
.rdio {
	position: relative;
}

.rdio input[type="radio"] {
	opacity: 0;
}

.rdio label {
	padding-left: 10px;
	cursor: pointer;
	margin-bottom: 0px !important;
}

.rdio label:before {
	width: 18px;
	height: 18px;
	position: absolute;
	top: 1px;
	left: 0;
	content: "";
	display: inline-block;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	border-radius: 50px;
	border: 1px solid #432135;
	background: #fff;
}

.rdio input[type="radio"] {
	margin: 0px;
}

.rdio input[type="radio"]:disabled+label {
	color: #999;
}

.rdio input[type="radio"]:disabled+label:before {
	background-color: #432135;
}

.rdio input[type="radio"]:checked+label::after {
	content: "";
	position: absolute;
	top: 5px;
	left: 4px;
	display: inline-block;
	font-size: 11px;
	width: 10px;
	height: 10px;
	background-color: #432135;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	border-radius: 50px;
}

.rdio-default input[type="radio"]:checked+label:before {
	border-color: #432135;
}

.rdio-primary input[type="radio"]:checked+label:before {
	border-color: #432135;
}

.rdio-primary input[type="radio"]:checked+label::after {
	background-color: #432135;
}

/*After login header*/

.fixed-book-btn {
	position: fixed;
	right: -130px;
	background: #432135;
	width: auto;
	height: 40px;
	border: none;
	color: #fff !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	top: 110px;
	border-radius: 4px;
	padding: 0px 20px;
	cursor: pointer;
	-webkit-transition: right 0.3s linear;
	-o-transition: right 0.3s linear;
	transition: right 0.3s linear;
	text-decoration: none !important;
	z-index: 999;
}

.fixed-book-btn i:first-child {
	position: absolute;
	left: 5px;
	font-size: 16px;
	opacity: 1;
	-webkit-transition: opacity 0.3s linear;
	-o-transition: opacity 0.3s linear;
	transition: opacity 0.3s linear;
}

.fixed-book-btn:hover {
	background-color: #84566b;
	right: -4px;
	-webkit-transition: right 0.3s linear;
	-o-transition: right 0.3s linear;
	transition: right 0.3s linear;
}

.fixed-book-btn:hover i:first-child {
	opacity: 0;
	-webkit-transition: opacity 0.3s linear;
	-o-transition: opacity 0.3s linear;
	transition: opacity 0.3s linear;
}

/* Appreciation Milestones css  */

/* scree 1 animation */

#clock {
	position: relative;
	-webkit-transform: translate(-0.491px, 101.158px);
	-ms-transform: translate(-0.491px, 101.158px);
	transform: translate(-0.491px, 101.158px);
	-webkit-animation: clock 5s linear infinite;
	animation: clock 5s linear infinite;
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	transform-box: fill-box;
}

.small-chart.chart-animated {
	position: absolute;
	top: 20%;
	right: 20px;
	-webkit-transform: scale(0.25);
	-ms-transform: scale(0.25);
	transform: scale(0.25);
}

@-webkit-keyframes clock {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes clock {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

#line-top .path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	-webkit-animation: dash 15s linear forwards;
	animation: dash 15s linear forwards;
	/* animation-delay: 1s; */
}

@-webkit-keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}

#line-top .arrow {
	opacity: 0;
	-webkit-animation: arrow-opacity 0.2s linear 4s forwards;
	animation: arrow-opacity 0.2s linear 4s forwards;
}

@-webkit-keyframes arrow-opacity {
	to {
		opacity: 1;
	}
}

@keyframes arrow-opacity {
	to {
		opacity: 1;
	}
}

/* scree 2 animation */

#tick1,
#tick2,
#tick3 {
	opacity: 0;
}

#circle {
	-webkit-transform: matrix(0.996, 0.087, -0.087, 0.996, 445.175, 173.824);
	-ms-transform: matrix(0.996, 0.087, -0.087, 0.996, 445.175, 173.824);
	transform: matrix(0.996, 0.087, -0.087, 0.996, 445.175, 173.824);
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	transform-box: fill-box;
}

#line-1 {
	-webkit-transform: matrix(0.996, 0.087, -0.087, 0.996, 584.813, 174.183);
	-ms-transform: matrix(0.996, 0.087, -0.087, 0.996, 584.813, 174.183);
	transform: matrix(0.996, 0.087, -0.087, 0.996, 584.813, 174.183);
}

#line-2 {
	-webkit-transform: matrix(0.996, 0.087, -0.087, 0.996, 621.813, 195.183);
	-ms-transform: matrix(0.996, 0.087, -0.087, 0.996, 621.813, 195.183);
	transform: matrix(0.996, 0.087, -0.087, 0.996, 621.813, 195.183);
}

#tick1 {
	-webkit-animation: opacity 0.5s linear 1s forwards;
	animation: opacity 0.5s linear 1s forwards;
}

#tick2 {
	-webkit-animation: opacity 0.5s linear 2s forwards;
	animation: opacity 0.5s linear 2s forwards;
}

#tick3 {
	-webkit-animation: opacity 0.5s linear 3s forwards;
	animation: opacity 0.5s linear 3s forwards;
}

@-webkit-keyframes opacity {
	to {
		opacity: 1;
	}
}

@keyframes opacity {
	to {
		opacity: 1;
	}
}

#circle {
	-webkit-animation: circle 3s linear forwards;
	animation: circle 3s linear forwards;
}

@-webkit-keyframes circle {
	to {
		-webkit-transform: matrix(0.996, 0.087, -0.087, 0.996, 472.175, 194.824);
		transform: matrix(0.996, 0.087, -0.087, 0.996, 472.175, 194.824);
	}
}

@keyframes circle {
	to {
		-webkit-transform: matrix(0.996, 0.087, -0.087, 0.996, 472.175, 194.824);
		transform: matrix(0.996, 0.087, -0.087, 0.996, 472.175, 194.824);
	}
}

#line-1 {
	-webkit-animation: line1 3s linear forwards;
	animation: line1 3s linear forwards;
}

@-webkit-keyframes line1 {
	to {
		-webkit-transform: matrix(0.996, 0.087, -0.087, 0.996, 530.813, 189.183);
		transform: matrix(0.996, 0.087, -0.087, 0.996, 530.813, 189.183);
	}
}

@keyframes line1 {
	to {
		-webkit-transform: matrix(0.996, 0.087, -0.087, 0.996, 530.813, 189.183);
		transform: matrix(0.996, 0.087, -0.087, 0.996, 530.813, 189.183);
	}
}

#line-2 {
	-webkit-animation: line2 3s linear forwards;
	animation: line2 3s linear forwards;
}

@-webkit-keyframes line2 {
	to {
		-webkit-transform: matrix(0.996, 0.087, -0.087, 0.996, 530.852, 207.935);
		transform: matrix(0.996, 0.087, -0.087, 0.996, 530.852, 207.935);
	}
}

@keyframes line2 {
	to {
		-webkit-transform: matrix(0.996, 0.087, -0.087, 0.996, 530.852, 207.935);
		transform: matrix(0.996, 0.087, -0.087, 0.996, 530.852, 207.935);
	}
}

/* screen 3 amimation */

.circles-bg {
	margin: 0;
	padding: 0;
	background-color: #421c32;
	width: 145px;
	height: 145px;
	border-radius: 100%;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.circles-bg li {
	position: absolute;
	border: 1px solid #ddd;
	border-radius: 100%;
}

.circles-bg li:nth-child(1) {
	width: 80%;
	height: 80%;
}

.circles-bg li:nth-child(2) {
	width: 60%;
	height: 60%;
}

.circles-bg li:nth-child(3) {
	width: 40%;
	height: 40%;
}

.circles-bg li:nth-child(4) {
	width: 20%;
	height: 20%;
}

.circles-bg li:nth-child(5) {
	width: 80%;
	height: 80%;
}

.circles-bg li.blink-animation {
	background-color: #ffffff70;
	width: 10px;
	height: 10px;
	border: none;
	-webkit-animation: circle-scale 1s linear 0.5s infinite alternate;
	animation: circle-scale 1s linear 0.5s infinite alternate;
}

@-webkit-keyframes circle-scale {
	to {
		-webkit-transform: scale(1.8);
		transform: scale(1.8);
	}
}

@keyframes circle-scale {
	to {
		-webkit-transform: scale(1.8);
		transform: scale(1.8);
	}
}

/* screen 4 animation  */

#graph-user {
	position: relative;
	left: -20px;
}

.chart-animated {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.chart-animated li {
	height: 160px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.chart-animated span {
	display: block;
	background: #432135;
	-webkit-animation: draw 2s linear;
	animation: draw 2s linear;
	border-radius: 4px;
	width: 20px;
}

@-webkit-keyframes draw {
	0% {
		height: 0;
	}
}

@keyframes draw {
	0% {
		height: 0;
	}
}

/* screen 5 animation */

.animated-boxes ul {
	margin: 0;
	padding: 0;
	width: 180px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.animated-boxes ul li {
	width: 60px;
	height: 60px;
	background-color: #ddd;
}

.animated-boxes ul li:nth-child(1) {
	-webkit-transform: translate(-20px, -10px);
	-ms-transform: translate(-20px, -10px);
	transform: translate(-20px, -10px);
}

.animated-boxes ul li:nth-child(2) {
	-webkit-transform: translate(-10px, 0px);
	-ms-transform: translate(-10px, 0px);
	transform: translate(-10px, 0px);
}

.animated-boxes ul li:nth-child(3) {
	-webkit-transform: translate(10px, 0px);
	-ms-transform: translate(10px, 0px);
	transform: translate(10px, 0px);
}

.animated-boxes ul li:nth-child(4) {
	-webkit-transform: translate(-40px, 0px);
	-ms-transform: translate(-40px, 0px);
	transform: translate(-40px, 0px);
}

.animated-boxes ul li:nth-child(5) {
	-webkit-transform: translate(-20px, -10px);
	-ms-transform: translate(-20px, -10px);
	transform: translate(-20px, -10px);
}

.animated-boxes ul li:nth-child(6) {
	-webkit-transform: translate(-10px, 0px);
	-ms-transform: translate(-10px, 0px);
	transform: translate(-10px, 0px);
}

.animated-boxes ul li:nth-child(7) {
	-webkit-transform: translate(-20px, 20px);
	-ms-transform: translate(-20px, 20px);
	transform: translate(-20px, 20px);
}

.animated-boxes ul li:nth-child(8) {
	-webkit-transform: translate(-10px, 0px);
	-ms-transform: translate(-10px, 0px);
	transform: translate(-10px, 0px);
}

.animated-boxes ul li:nth-child(9) {
	-webkit-transform: translate(0px, 20px);
	-ms-transform: translate(0px, 20px);
	transform: translate(0px, 20px);
}

.animated-boxes ul li {
	-webkit-animation: box-animation 2s linear 0.5s forwards;
	animation: box-animation 2s linear 0.5s forwards;
}

@-webkit-keyframes box-animation {
	to {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		background-color: #421c32;
	}
}

@keyframes box-animation {
	to {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		background-color: #421c32;
	}
}

.tick-animation {
	width: 80px;
	height: 80px;
	background: #ddd;
	border-radius: 60px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #421c32;
	font-size: 40px;
	position: absolute;
	z-index: 9;
	left: 50%;
	top: 50%;
	margin-top: -40px;
	margin-left: -40px;
	opacity: 0;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-animation: tick-animation 0.2s linear 2.5s forwards;
	animation: tick-animation 0.2s linear 2.5s forwards;
}

@-webkit-keyframes tick-animation {
	to {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes tick-animation {
	to {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.nav-link:hover .fa-expand {
	-webkit-animation: expand 0.5s linear alternate infinite;
	animation: expand 0.5s linear alternate infinite;
}

@-webkit-keyframes expand {
	to {
		-webkit-transform: scale(1.15);
		transform: scale(1.15);
	}
}

@keyframes expand {
	to {
		-webkit-transform: scale(1.15);
		transform: scale(1.15);
	}
}

/* navigation guide css */

.guide-icon-animation {
	-webkit-animation: guide-icon-animation 400ms linear alternate infinite;
	animation: guide-icon-animation 400ms linear alternate infinite;
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	transform-box: fill-box;
}

@-webkit-keyframes guide-icon-animation {
	to {
		color: #84566b;
		-webkit-transform: scale(0.6);
		transform: scale(0.6);
	}
}

@keyframes guide-icon-animation {
	to {
		color: #84566b;
		-webkit-transform: scale(0.6);
		transform: scale(0.6);
	}
}

.guide-overlay {
	background-color: rgba(0, 0, 0, 0.55);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9999;
}

.guide-box {
	background: white;
	width: 270px;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 7px 0 rgba(69, 90, 100, 0.24);
	box-shadow: 0 1px 7px 0 rgba(69, 90, 100, 0.24);
	padding: 10px 10px 0 10px;
	z-index: 9999999;
	opacity: 0;
	visibility: hidden;
	text-align: left;
	font-family: NiveauGroteskRegular;
	white-space: normal;
}

.guide-box:after {
	content: " ";
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid white;
	position: absolute;
}

.guide-box img.guide-wolli {
	background-color: #ddd;
	min-width: 50px;
	height: 50px;
	border-radius: 100px;
	padding: 5px;
	-o-object-fit: contain;
	object-fit: contain;
}

.guide-box p.message {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	font-size: 14px;
	line-height: 16px;
	color: #000;
}

.close-guide-box {
	font-size: 20px;
	cursor: pointer;
	color: #ddd;
	background-color: transparent;
	border: none;
	float: right;
	margin-top: -10px;
	margin-right: -2px;
}

.guide-checkbox {
	margin: 10px -10px 0 -10px;
	padding: 10px;
	border-top: 1px solid #ddd;
	color: #565656;
}

.guide-checkbox .form-group label {
	cursor: pointer;
}

.guide-checkbox .form-group label small {
	margin-left: 25px;
	display: block;
	margin-top: -20px;
}

.guide-checkbox .form-group label:before {
	content: "";
	background-color: transparent;
	border: 2px solid #ddd;
	padding: 6px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 5px;
}

.guide-checkbox .form-group input:checked+label:after {
	content: "";
	display: block;
	position: absolute;
	top: 4px;
	left: 5.5px;
	width: 5px;
	height: 9px;
	border: solid #432135;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	cursor: pointer;
}

.guide-checkbox .form-group input:checked+label::before {
	border-color: #432135;
}

.ex-project-guide {
	top: 52px;
	right: 15px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.ex-project-guide.fadeup-animation {
	opacity: 1;
	visibility: visible;
	top: 42px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.guide-box.top-right-arrow:after {
	top: -7px;
	right: 10px;
}

.guide-box.top-left-arrow:after {
	top: -7px;
	left: 10px;
}

.my-collection-guide {
	top: 52px;
	left: 20px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.my-collection-guide.fadeup-animation {
	top: 42px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.right-ok-btn-guide {
	top: 50%;
	right: 70px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.right-ok-btn-guide.faderight-animation {
	right: 60px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.right-ok-btn-guide-2 {
	top: 50%;
	right: 50px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.Adjacency-btn-guide {
	top: 50%;
	right: 250px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.Adjacency-btn-guide.faderight-animation {
	right: 240px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.Complexity-btn-guide {
	top: 50%;
	right: 390px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.Complexity-btn-guide.faderight-animation {
	right: 380px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.compitetor-btn-guide {
	top: 50%;
	right: 50px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.compitetor-btn-guide.faderight-animation {
	right: 40px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.product-btn-guide {
	top: 50%;
	right: 170px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.product-btn-guide.faderight-animation {
	right: 160px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.right-ok-btn-guide-2.faderight-animation {
	right: 40px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.guide-box.bottom-center-arrow:after {
	bottom: -7px;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	left: 50%;
	margin-left: -8px;
}

.guide-box.top-center-arrow:after {
	top: -7px;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
	left: 50%;
	margin-left: -8px;
}

.essentials-first-guide {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	right: -290px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.essentials-first-guide.fadeleft-animation {
	right: -280px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.guide-box.right-center-arrow:after {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	margin-top: -4px;
	right: -12px;
	top: 50%;
}

.query-info-guide {
	top: -60px;
	left: 50px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.to-left-center {
	opacity: 0;
	visibility: hidden;
	left: 50%;
	transform: translateX(-50%);
	top: 0;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transition: all 0.25s linear;
}

.to-left-center.fadeleft-animation {
	opacity: 1;
	visibility: visible;
	top: 10px;
	transition: all 0.25s linear;
}

.query-info-guide.fadeleft-animation {
	left: 40px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.clone-guide {
	top: -60px;
	left: 50px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.clone-guide.fadeup-animation {
	left: 40px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.guide-box.left-center-arrow:after {
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	top: 50%;
	left: -12px;
	margin-top: -4px;
}

.query-filter-guide {
	left: 50%;
	margin-left: -135px;
	top: -110px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.query-filter-guide.fadedown-animation {
	top: -100px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.fameindex-heading-guide {
	top: -52px;
	left: 120px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.fameindex-heading-guide.fadeleft-animation {
	opacity: 1;
	visibility: visible;
	left: 110px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.fameindex-box-guide {
	bottom: 100px;
	left: 50%;
	margin-left: -135px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.fameindex-box-guide.fadedown-animation {
	bottom: 90px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.queryIndex-box-guide {
	bottom: 60px;
	left: 50%;
	margin-left: -135px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.queryIndex-box-guide.fadedown-animation {
	bottom: 50px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.market-table-guide {
	left: 50%;
	margin-left: -135px;
	top: -120px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.market-table-guide.fadedown-animation {
	top: -110px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.export-btn-guide {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 80px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.save-server-btn-guide {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 180px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.add-vault-guide {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 270px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.export-btn-guide.fadeleft-animation {
	left: 70px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.save-server-btn-guide.fadeleft-animation {
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	left: 170px;
}

.add-vault-guide.fadeleft-animation {
	left: 260px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.popup-search {
	bottom: -150px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.popup-search.fadedown-animation {
	bottom: -160px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.alter-button {
	right: 0px;
	bottom: -150px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.alter-button.fadedown-animation {
	bottom: -160px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.opp-count {
	right: 0px;
	bottom: -150px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.opp-count.fadedown-animation {
	bottom: -140px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.popup-heading {
	left: 0px;
	bottom: -160px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.popup-heading.fadedown-animation {
	bottom: -170px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.guide-box.bottom-right-arrow:after {
	transform: rotate(180deg);
	right: 10px;
}

.guide-box.bottom-left-arrow:after {
	transform: rotate(180deg);
	left: 10px;
}

.popup-buttons {
	bottom: 50px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.popup-buttons.fadeup-animation {
	bottom: 60px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.to-right-center {
	right: -270px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	top: 50%;
	transform: translateY(-50%);
}

.to-right-center.faderight-animation {
	right: -280px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.to-down-center {
	bottom: -140px;
	-webkit-transition: bottom 0.25s linear;
	-o-transition: bottom 0.25s linear;
	transition: bottom 0.25s linear;
	left: 50%;
	transform: translateX(-50%);
	visibility: hidden;
}

.to-down-center.fadedown-animation {
	bottom: -150px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.to-up-left {
	top: -140px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	left: 0px;
}

.to-up-left.fadeup-animation {
	top: -150px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.to-up-right {
	top: -140px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	right: 0px;
}

.to-up-right.fadeup-animation {
	top: -150px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.to-up-center {
	top: -140px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	left: 50%;
	transform: translateX(-50%);
}

.to-up-center.fadeup-animation {
	top: -150px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.top-ten-box {
	top: 130px;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	left: 48%;
	transform: translateX(-50%);
}

.top-ten-box.fadeup-animation {
	top: 120px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.to-down-left {
	left: 0;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	bottom: -130px;
}

.to-down-left.fadedown-animation {
	bottom: -140px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.to-down-right {
	right: 0;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
	bottom: -140px;
}

.to-down-right.fadedown-animation {
	bottom: -150px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	transition: all 0.25s linear;
}

.st-heading {
	font-size: 20px;
	font-family: NiveauGroteskBold;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0 0 5px 0;
	text-transform: capitalize;
}

.st-sub-heading {
	font-size: 16px;
	font-family: NiveauGroteskBold;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0 0 5px 0;
	text-transform: capitalize;
}

.st-heading-small {
	font-size: 20px;
	font-family: NiveauGroteskBold;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0 0 5px 0;
}

.st-sub-heading>i.fa-question-circle {
	font-size: 14px;
}

.tagline {
	padding: 15px 15px 60px 15px;
	background-color: #ddd;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.tagline>i {
	font-size: 24px;
	color: #432135;
}

.tagline h3 {
	margin: 10px 0px;
	color: #432135;
	font-size: 26px;
	font-family: "NiveauGroteskBold";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.static-tabs.nav-pills .nav-link.active,
.static-tabs.nav-pills .nav-link.active:focus,
.static-tabs.nav-pills .nav-link.active:hover,
.static-tabs.nav-pills .nav-link.active,
.static-tabs.nav-pills .nav-link.active:focus,
.static-tabs.nav-pills .nav-link.active:hover,
.static-tabs.nav-pills .nav-link.active.active,
.static-tabs.nav-pills .nav-link.active.active:focus,
.static-tabs.nav-pills .nav-link.active.active:hover {
	padding: 0;
	padding-bottom: 5px;
	border-bottom: 2px solid #432135;
	border-radius: 0px;
	font-family: "NiveauGroteskRegular";
}

.static-tabs.nav-pills .nav-link,
.static-tabs.nav-pills .nav-link:focus,
.static-tabs.nav-pills .nav-link:hover,
.static-tabs.nav-pills .nav-link,
.static-tabs.nav-pills .nav-link:focus,
.static-tabs.nav-pills .nav-link:hover,
.static-tabs.nav-pills .nav-link,
.static-tabs.nav-pills .nav-link:focus,
.static-tabs.nav-pills .nav-link:hover {
	padding: 0;
	padding-bottom: 5px;
	border-radius: 0px;
	border-bottom: 2px solid transparent;
	text-decoration: none;
	color: #565656;
	margin: 0px 12px;
	font-size: 14px;
	font-family: "NiveauGroteskRegular";
}

.accordion-faq .card {
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid #ddd;
	margin-bottom: 12px;
}

.accordion-faq .card .card-header {
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
	border-radius: 5px 5px 0 0;
	font-size: 14px;
	color: #000;
	text-decoration: none;
	margin: 0px;
	white-space: normal;
}

.accordion-faq .card .card-body {
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #565656;
}

.accordion-faq .card .card-header>span {
	margin-right: 10px;
	color: #432135;
	font-size: 16px;
}

.accordion-faq .card .card-header>span img {
	width: 100%;
	padding: 10px;
}

.accordion-faq button.card-header:after {
	font-family: "FontAwesome";
	content: "\f068";
	position: absolute;
	right: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #565656;
	font-size: 10px;
}

.accordion-faq .card-header.collapsed:after {
	content: "\f067";
}

.modal-list {
	list-style-type: disc;
	padding-left: 30px;
}

/* module 3 css  */

.color-light-text {
	color: #565656 !important;
}

.color-more-light-text {
	color: lightgray !important;
}

.top-actions p {
	font-size: 13px;
	margin-bottom: 2px;
}

.top-actions button {
	font-size: 14px;
	height: 100%;
}

.applied-filters {
	margin-bottom: 3px;
}

.top-actions button .image {
	width: 40px;
	height: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #432135;
	margin-right: 10px;
	border-radius: 100px;
	opacity: 0.4;
}

.Competitor-bg {
	background-color: #247679 !important;
}

.Competitor-color {
	color: #247679 !important;
}

.Competitor-color-border {
	border-color: #247679 !important;
}

.Competitor-btn:hover {
	background-color: #44a1a5 !important;
	border-color: #44a1a5 !important;
}

.small-bar-bg {
	width: 100%;
	height: 7px;
	background: #ddd;
	border-radius: 100px;
}

.small-bar-filled {
	height: 7px;
	background: #432135;
	z-index: 1;
	border-radius: 100px;
	top: 0;
}

.border {
	border: 1px solid #ddd !important;
}

.small-btn {
	padding: 3px 7px;
	font-size: 12px;
}

.checkbox-style-btn label,
.radio-style-btn label {
	color: #000;
	font-size: 14px;
	cursor: pointer;
}

.checkbox-style-btn label:before {
	content: "";
	background-color: transparent;
	border: 1px solid #432135;
	padding: 8px;
	display: inline-block;
	position: relative;
	vertical-align: -4px;
	cursor: pointer;
	margin-right: 5px;
}

.checkbox-style-btn input:checked+label:after {
	content: "";
	display: block;
	position: absolute;
	top: 2.5px;
	left: 5.5px;
	width: 6px;
	height: 10px;
	border: solid #432135;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	cursor: pointer;
}

.checkbox-style-btn input:checked+label::before {
	border-color: #432135;
}

.player-table {
	table-layout: fixed;
}

.player-table tr {
	position: relative;
}

.player-table tr td {
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: relative;
}

.player-table tr td.disabled .round-checkbox label {
	opacity: 0.4;
	cursor: not-allowed;
}

.player-table tr td a.player {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: calc(100% - 50px);
	display: inline-block;
	vertical-align: bottom;
	margin-left: 5px;
}

.cc-height {
	height: calc(100vh - 339px);
	overflow-y: auto;
}

.table-icons img {
	cursor: pointer;
	padding: 0px 3px;
}

.table-icons img:hover {
	opacity: 0.7;
}

.table b,
.table strong {
	font-family: NiveauGroteskBold;
	color: #000;
}

.table .hiddenRow {
	border: none !important;
	padding: 0 !important;
}

.table .cluster-table {
	table-layout: fixed;
	background-color: #f5f5f5;
	border: none;
}

.player-table .fa-plus-square,
.player-table .fa-minus-square {
	cursor: pointer;
}

.table td {
	font-size: 13px;
	line-height: initial;
}

.table th {
	font-size: 12px;
	font-family: NiveauGroteskBold;
	line-height: initial;
}

.cluster-table thead {
	visibility: hidden;
}

.table .cluster-table tr td {
	padding: 2px 0.5em;
	overflow: initial;
	-o-text-overflow: initial;
	text-overflow: initial;
	white-space: initial;
}

.table .cluster-table tr td a:hover {
	text-decoration: underline;
}

/* .table .cluster-table tr td:first-child {
	border-left: none;
  } */
.table .cluster-table tr:first-child td,
.table .cluster-table tr:last-child td {
	border-top: none;
}

.table .cluster-table tr:last-child td {
	border-bottom: 1px solid #cac7c7;
}

.bg-primary-100 {
	background-color: #432135;
}

.bg-primary-75 {
	background-color: #43213575;
}

.bg-primary-50 {
	background-color: #43213550;
}

.bg-primary-25 {
	background-color: #43213525;
}

.Weightage-grid {
	border-radius: 4px;
	-ms-flex-preferred-size: 49%;
	flex-basis: 49%;
}

.weightage-slider.ui-widget-content {
	background: #ddd;
}

.weightage-slider .ui-widget-header {
	background: #432135;
}

.custom-handle.ui-slider-handle,
.custom-handle.ui-slider-handle {
	width: 20px;
	height: 20px;
	top: 50%;
	margin-top: -10px;
	text-align: center;
	line-height: 20px;
	background: #fff;
	color: #432135;
	font-size: 10px;
	cursor: pointer;
	margin-left: -10px;
}

.Weightage-grid h5 {
	font-size: 13px;
}

.Weightage-grid p {
	font-size: 11px;
}

.modal-xl {
	max-width: 90%;
}

#contextMenu {
	position: absolute;
	display: none;
}

/* profile page css */

.card.card-border {
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid #ddd;
}

.card.card-border h5 {
	font-size: 13px;
	font-family: NiveauGroteskBold;
}

.card.card-border .card-header {
	padding: 6px 7px;
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin: 0;
	background-color: #ddd;
	color: #432135;
	font-family: "NiveauGroteskBold";
}

.card.card-border .card-body {
	padding: 4px 7px;
}

.card.card-border .card-header h4 {
	font-size: 14px;
	color: #432135;
	font-family: "NiveauGroteskBold";
}

.watch-out-card+.watch-out-card {
	margin-top: 7px;
}

.watch-out-card .icon {
	width: 35px;
	height: 40px;
	background: #ddd;
	border-radius: 4px;
	margin-right: 7px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 20px;
	color: #432135;
}

.watch-out-card a {
	font-size: 12px;
	color: #432135;
}

.watch-out-card a:hover {
	text-decoration: underline;
}

.card.card-border h6::before {
	content: "";
	width: 8px;
	height: 8px;
	background-color: #000;
	border-radius: 100px;
	display: inline-block;
	margin-right: 9px;
}

.table td,
.table th {
	padding: 2px 0.5em;
}

.table.table-lg td,
.table.table-lg th {
	padding: 7px 10px;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
	background-color: #84566b !important;
}

.table-filter-dropdown.dropdown-toggle::after {
	display: none;
}

.table-fixed {
	table-layout: fixed;
}

.bg-transparent {
	background-color: transparent !important;
}

/* My Collection CSS*/

.mis-top-info .icon {
	width: 32px;
	height: 32px;
	background: -webkit-gradient(linear,
			left top,
			right top,
			from(#84566b),
			to(#432135));
	background: -o-linear-gradient(left, #84566b, #432135);
	background: linear-gradient(to right, #84566b, #432135);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 4px;
}

.sum-btn-wrapper .icon {
	min-width: 30px;
	height: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: #fff;
	color: #432135;
	font-size: 16px;
	margin-right: 10px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 4px;
}

.mis-top-info .icon>img {
	width: 16px;
	height: 16px;
}

.mis-top-info .info {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-left: 10px;
}

.mis-top-info .info h4 {
	margin-bottom: 3px;
	font-size: 16px;
	color: #000;
	font-family: NiveauGroteskBold;
}

.mis-top-info .info p {
	margin: 0;
	text-transform: uppercase;
	font-size: 12px;
	font-family: NiveauGroteskBold;
	color: #565656;
	letter-spacing: 1px;
}

.mis-top-info button {
	height: 40px;
	font-size: 14px;
}

.search label {
	text-align: left !important;
	-webkit-box-pack: left;
	-ms-flex-pack: left;
	justify-content: left;
	padding: 0px 5px 0px 0px;
	margin: 0;
}

.filter1 label>input,
.filter label>input {
	display: none;
}

.searchbox .form-inline input {
	background: none;
}

.filter1 label>input:checked+span,
.filter label>input:checked+span {
	background-color: #fff;
	border: 1px solid #432135;
}

form label {
	margin: 0 0 0 0;
	font-size: 12px;
	letter-spacing: 0.2px;
	color: #565656;
}

/* .lagend-symbol {
	height: 18px;
	background: #fff;
	margin: 0px 3px 0px -5px;
	width: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #432135;
	font-size: 14px;
	border-right: 1px solid #ddd;
	min-width: 30px;
	float: left;
  } */

table .project-logo {
	min-width: 30px;
	height: 30px;
	width: 30px;
	background: #ddd;
	overflow: hidden;
	border-radius: 4px;
	margin-right: 7px;
}

table .project-logo img {
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}

.badge {
	border-radius: 10px;
	padding: 5px 12px;
}

.badge-primary {
	background: #432135;
}

.t-savings-btn {
	width: 100%;
	text-align: left;
	padding: 10px !important;
	margin-right: 15px;
}

.t-savings-btn p {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 0;
	line-height: initial;
}

.t-savings-btn h2 {
	font-size: 20px;
	font-family: NiveauGroteskBold;
}

.contract-detail+.contract-detail {
	border-top: 1px solid #ddd;
	padding-top: 10px;
	margin-top: 10px;
}

.badge {
	font-size: 11px;
	border-radius: 30px;
	font-weight: normal;
}

.summary-user-image {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	border: 2px solid #ddd;
	overflow: hidden;
}

.summary-user-image img {
	width: 100%;
	height: 100%;
}

/*Remove user*/
.card.card.card-border .remove-user {
	position: absolute;
	right: 5px;
	top: 5px;
	color: #565656;
	font-style: normal;
	cursor: pointer;
	visibility: hidden;
}

.card.card-border:hover .remove-user {
	visibility: visible;
}

.blink-btn {
	-webkit-animation: glowing 0.5s infinite alternate linear;
	-moz-animation: glowing 0.5s infinite alternate linear;
	-o-animation: glowing 0.5s infinite alternate linear;
	animation: glowing 0.5s infinite alternate linear;
}

@-webkit-keyframes glowing {
	0% {
		background-color: #432135;
		border-color: #432143;
	}

	100% {
		background-color: #ad8c9b;
		border-color: #ad8c9b;
	}
}

@-moz-keyframes glowing {
	0% {
		background-color: #432135;
		border-color: #432143;
	}

	100% {
		background-color: #ad8c9b;
		border-color: #ad8c9b;
	}
}

@-o-keyframes glowing {
	0% {
		background-color: #432135;
		border-color: #432143;
	}

	100% {
		background-color: #ad8c9b;
		border-color: #ad8c9b;
	}
}

@keyframes glowing {
	0% {
		background-color: #432135;
		border-color: #432143;
	}

	100% {
		background-color: #ad8c9b;
		border-color: #ad8c9b;
	}
}

/* funnel css  */

.funnel-wrap:after {
	content: " ";
	border-top: 2px dashed #565656;
	position: absolute;
	width: 565px;
	top: 48px;
	left: 50%;
	-webkit-transform: translateX(-50%) rotate(10deg);
	-ms-transform: translateX(-50%) rotate(10deg);
	transform: translateX(-50%) rotate(10deg);
}

.funnel-wrap .accretion-rate {
	position: absolute;
	width: 565px;
	top: 22px;
	left: 50%;
	-webkit-transform: translateX(-50%) rotate(10deg);
	-ms-transform: translateX(-50%) rotate(10deg);
	transform: translateX(-50%) rotate(10deg);
	font-family: NiveauGroteskBold;
	text-align: center;
	z-index: 9;
	font-size: 15px;
}

.funnel-wrap .total-project {
	position: absolute;
	top: 27%;
	left: 15%;
	color: #565656;
	font-family: NiveauGroteskBold;
	font-size: 15px;
	margin-top: 30px;
}

.funnel-wrap .closed-project {
	position: absolute;
	top: 27%;
	right: 14%;
	color: #565656;
	font-family: NiveauGroteskBold;
	font-size: 15px;
	margin-top: 30px;
}

.funnel-wrap ul {
	position: relative;
	text-align: center;
	width: 310px;
	-webkit-transform: rotate(-90deg) translateX(110px);
	-ms-transform: rotate(-90deg) translateX(110px);
	transform: rotate(-90deg) translateX(110px);
	margin: 0 auto;
}

.funnel-wrap ul:before,
.funnel-wrap ul:after {
	border-color: #fff;
	border-style: solid;
	border-top-color: transparent;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	content: "";
	position: absolute;
	top: 0;
}

.funnel-wrap ul:before {
	border-width: 0 100px 580px 0;
	border-right-color: transparent;
	left: -1px;
	z-index: 9999;
}

.funnel-wrap ul:after {
	border-width: 0 0 580px 100px;
	border-left-color: transparent;
	right: -1px;
}

.funnel-wrap ul li {
	padding: 10px 50px;
	margin-bottom: 20px;
	position: relative;
	height: 95px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.funnel-wrap ul li p {
	font-family: NiveauGroteskBold;
	line-height: normal;
	white-space: normal;
	font-size: 14px;
}

.funnel-wrap ul li:nth-child(1) {
	background-color: rgba(67, 33, 53, 0.2);
}

.funnel-wrap ul li:nth-child(2) {
	background-color: rgba(67, 33, 53, 0.4);
}

.funnel-wrap ul li:nth-child(3) {
	background-color: rgba(67, 33, 53, 0.6);
}

.funnel-wrap ul li:nth-child(4) {
	background-color: rgba(67, 33, 53, 0.8);
	padding: 10px 80px;
}

.funnel-wrap ul li:nth-child(5) {
	background-color: rgba(67, 33, 53, 1);
	padding: 10px 100px;
}

.funnel-wrap ul li a.ratio {
	position: relative;
}

.funnel-wrap ul li a.ratio::before {
	content: "";
	position: absolute;
	width: 153px;
	background: #565656;
	right: 50%;
	height: 0px;
	border-top: 1px dashed #565656;
	top: 87px;
	z-index: 9999;
}

.funnel-wrap ul li a.ratio .ratio-value {
	position: absolute;
	left: -230px;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	top: 60px;
	z-index: 9999;
	text-align: -webkit-center;
	width: 120px;
	white-space: nowrap;
	cursor: pointer;
}

.funnel-wrap ul li a.ratio .ratio-value.disabled {
	opacity: 0.6;
}

.funnel-wrap {
	height: 400px;
}

.funnel-wrap ul li a.ratio .ratio-value span {
	width: 35px;
	height: 35px;
	min-width: 35px;
	min-height: 35px;
	background: #432135;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: NiveauGroteskBold;
	color: #fff;
	border-radius: 50%;
}

.funnel-wrap ul li a.ratio:hover .ratio-value span {
	background: #84566b;
}

.funnel-wrap ul li span.funnel-icon {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 25px;
	min-width: 25px;
	height: 25px;
	min-height: 25px;
	background-color: #fff;
	color: #000;
	border-radius: 50px;
}

.vertical-tabs-wrapper .nav-pills {
	min-width: 170px;
	width: 170px;
	padding: 7px;
	background: #ddd;
	border-radius: 4px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.vertical-tabs-wrapper .nav-pills .nav-link.active,
.vertical-tabs-wrapper .nav-pills .nav-link.active:focus,
.vertical-tabs-wrapper .nav-pills .nav-link.active:hover,
.vertical-tabs-wrapper .nav-pills .nav-link.active,
.vertical-tabs-wrapper .nav-pills .nav-link.active:focus,
.vertical-tabs-wrapper .nav-pills .nav-link.active:hover,
.vertical-tabs-wrapper .nav-pills .nav-link.active.active,
.vertical-tabs-wrapper .nav-pills .nav-link.active.active:focus,
.vertical-tabs-wrapper .nav-pills .nav-link.active.active:hover {
	border: none;
	background: #fff;
	color: #000;
	text-align: left;
}

.vertical-tabs-wrapper .nav-pills .nav-link {
	font-size: 14px;
	text-decoration: none;
	color: #000;
	text-align: left;
}

.vertical-tabs-wrapper .nav-pills .nav-link:hover {
	color: #432143;
}

button.build-grid {
	border: 2px dashed #432135;
	background: #fff;
	border-radius: 4px;
	width: 100%;
	min-height: 196px;
	padding: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 16px;
	font-family: NiveauGroteskBold;
	color: #432135;
}

button.build-grid:hover,
button.build-grid:focus {
	border: 2px dashed #432143;
	background-color: #ddd;
	cursor: pointer;
	outline: none;
}

.vault-wrapper {
	user-select: none;
}

.vault-wrapper .card-footer {
	border-top: 1px solid #ddd;
	height: 100%;
}

.vault-wrapper .card-footer .title {
	white-space: nowrap;
	width: calc(100% - 94px);
	overflow: hidden;
	text-overflow: ellipsis;
}

.vault-wrapper .card-footer a {
	min-width: 24px;
	height: 24px;
	display: inline-block;
	margin-left: 7px;
	border: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	text-decoration: none !important;
}

/* .vault-wrapper .vault-action-btns{
	  background-color: #fff;
	  position: absolute;
	  right: 10px;
	  bottom: 10px;
	  z-index: 2;
  } */
.vault-wrapper .vault-action-btns a {
	min-width: 24px;
	height: 24px;
	display: inline-block;
	margin-left: 7px;
	border: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	text-decoration: none !important;
}

.vault-wrapper .item {
	position: relative;
	max-height: 193px;
}

.vault-wrapper .item .image {
	min-height: 137px;
}

.vault-wrapper .item .image img {
	height: 137px;
	user-select: none;
	object-fit: contain;
	object-position: center;
}

.vault-wrapper .card.card-border .card-body .count {
	width: 20px;
	height: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: #000;
	color: #fff;
	font-size: 12px;
	border-radius: 4px;
	position: absolute;
	top: 8px;
	left: 8px;
}

.vault-wrapper .card-footer i {
	cursor: pointer;
}

.vault-wrapper .hidden-card .card-body {
	opacity: 0.2;
}

.modal-body .vault-wrapper .card {
	cursor: pointer;
}

.modal-body .vault-wrapper .card .overlay {
	display: none;
}

.modal-body .vault-wrapper .card.selected .overlay {
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 9;
	border-radius: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.btn-toggle {
	margin: 0 82px;
	padding: 0;
	position: relative;
	border: 1px solid #432135;
	height: 20px;
	width: 35px;
	border-radius: 100px;
	color: #432135;
	background: #fff;
	color: #000;
}

.btn-filter {
	width: 26px;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 26px;
	background-color: #ddd;
}

.btn-filter.active {
	background-color: #432135;
	color: #fff;
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
	outline: none;
}

.btn-toggle:before,
.btn-toggle:after {
	line-height: 1.5rem;
	width: 4rem;
	text-align: center;
	font-size: 0.75rem;
	text-transform: uppercase;
	position: absolute;
	bottom: -3px;
	-webkit-transition: opacity 0.25s;
	-o-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.btn-toggle-1:before {
	content: "Quarterly";
	left: -80px;
}

.btn-toggle-1:after {
	content: "Monthly";
	right: -73px;
	opacity: 0.5;
}

.btn-toggle-2:before {
	content: "Function";
	left: -80px;
}

.btn-toggle-2:after {
	content: "Business unit";
	right: -73px;
	opacity: 0.5;
}

.btn-toggle-3:before {
	content: "Capabilities";
	left: -92px;
}

.btn-toggle-3:after {
	content: "Project types";
	right: -73px;
	opacity: 0.5;
}

.btn-toggle>.handle {
	position: absolute;
	top: 4px;
	left: 4px;
	width: 10px;
	height: 10px;
	border-radius: 100px;
	background: #432135;
	-webkit-transition: left 0.25s;
	-o-transition: left 0.25s;
	transition: left 0.25s;
}

.btn-toggle.active {
	-webkit-transition: background-color 0.25s;
	-o-transition: background-color 0.25s;
	transition: background-color 0.25s;
}

.btn-toggle.active>.handle {
	left: 18px;
	-webkit-transition: left 0.25s;
	-o-transition: left 0.25s;
	transition: left 0.25s;
}

.btn-toggle.active:before {
	opacity: 0.5;
}

.btn-toggle.active:after {
	opacity: 1;
}

input::-ms-reveal,
input::-ms-clear {
	display: none;
}

::-ms-clear {
	display: none;
}

.modal-body .card.project-info-grid {
	-webkit-box-shadow: none;
	box-shadow: none;
	margin: 0;
	border: 1px solid #ddd;
}

.modal-body .tittle {
	display: none;
}

.modal-body .card.query_filter {
	-webkit-box-shadow: none;
	box-shadow: none;
	margin: 0px;
}

.modal-body .card.query_filter .card-header {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.modal-body .card.query_filter .pcoded-micon {
	visibility: hidden;
}

.modal-body .card.query_filter .card-body.sst {
	max-height: initial;
	overflow-x: hidden;
}

.modal-body .card.query_filter .card-body.sst .flex-nowrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}

/*SEARCH FILTER*/
/*multiselect css*/
.advance-filter-wrapper .dropdown {
	position: relative;
	display: inline-block;
	vertical-align: bottom;
	line-height: 1.5;
}

.advance-filter-wrapper .dropdown-toggle {
	background: #ffffff;
	display: inline-block;
	text-decoration: none;
	position: relative;
}

.advance-filter-wrapper .dropdown-menu-wrapper {
	overflow: hidden;
}

.advance-filter-wrapper .dropdown-menu-container {
	position: relative;
	width: 300px;
	max-width: 100%;
}

.advance-filter-wrapper .dropdown-menu {
	top: 0;
	left: 0;
	width: inherit;
}

.advance-filter-wrapper .dropdown-menu-main,
.advance-filter-wrapper .dropdown-menu-open {
	display: block;
}

.advance-filter-wrapper .dropdown-menu-open {
	position: relative;
	padding: 0;
	margin: 0;
	border-radius: 4px;
	background-color: #fff;
	border: 1px solid #ddd;
}

.advance-filter-wrapper .dropdown-menu-wrapper {
	position: absolute;
	z-index: 9;
	display: none;
	opacity: 0;
}

.advance-filter-wrapper .dropdown-above .dropdown-menu-wrapper {
	bottom: 100%;
	top: auto;
	margin: 0 0 3px;
}

.advance-filter-wrapper .dropdown-mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	display: none;
}

.advance-filter-wrapper .dropdown-animating .dropdown-mask {
	display: block;
}

.advance-filter-wrapper .dropdown-overlay {
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	opacity: 0;
}

.advance-filter-wrapper .dropdown-menu-main .dropdown-header {
	display: none;
}

.advance-filter-wrapper .dropdown-close .dropdown-text,
.advance-filter-wrapper .dropdown-back .dropdown-text {
	display: none;
}

.advance-filter-wrapper .dropdown-back {
	left: 0;
}

.advance-filter-wrapper .dropdown-back .dropdown-icon:after {
	content: "";
	display: inline-block;
	width: 0;
	height: 0;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	border-right: 4px solid;
}

.advance-filter-wrapper .dropdown-menu-main .dropdown-back {
	display: none;
}

.advance-filter-wrapper .dropdown-close {
	display: none;
	right: 0;
}

.dropdown-close .dropdown-icon:after {
	content: "\00d7";
}

.advance-filter-wrapper .dropdown-list,
.advance-filter-wrapper .dropdown-item {
	list-style-type: none;
	font-size: inherit;
}

.advance-filter-wrapper .dropdown-item:hover {
	background-color: transparent;
}

.advance-filter-wrapper .dropdown-list {
	overflow: hidden;
	text-align: left;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	margin: 0;
	max-height: 140px !important;
}

.advance-filter-wrapper .dropdown-item {
	padding: 0;
	margin: 0 0 1px;
}

.advance-filter-wrapper .dropdown-item:last-child {
	margin-bottom: 0;
}

.advance-filter-wrapper .dropdown-item .checkbox-fade {
	word-break: break-word;
	white-space: normal;
}

.advance-filter-wrapper .dropdown-label {
	text-transform: uppercase;
	font-family: NiveauGroteskBold;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.4);
	padding: 10px 20px;
	margin: 20px 0 0;
}

.advance-filter-wrapper .dropdown-label:first-child {
	margin-top: 0;
}

.advance-filter-wrapper .dropdown-divider {
	border-bottom: 1px solid #e0e0e0;
	margin: 10px 0;
}

.advance-filter-wrapper .dropdown-divider:first-child,
.advance-filter-wrapper .dropdown-divider:last-child {
	display: none;
}

.advance-filter-wrapper .dropdown-divider+.dropdown-label {
	margin-top: 10px;
}

.advance-filter-wrapper .dropdown-divider:first-child+.dropdown-label {
	margin-top: 0;
}

.advance-filter-wrapper .dropdown-parent .dropdown-link:after {
	content: "";
	opacity: 0.6;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -4px;
	display: inline-block;
	vertical-align: middle;
	border-left: 4px solid;
	border-bottom: 4px solid transparent;
	border-top: 4px solid transparent;
}

.advance-filter-wrapper .dropdown>a {
	min-width: 320px;
	max-width: 320px;
	box-shadow: none;
	border-bottom: 1px solid #ddd;
	padding: 0.375rem 0.75rem;
	padding-left: 0px;
	font-size: 14px;
	padding-top: 0px;
}

.advance-filter-wrapper .dropdown>a .dropdown-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 95%;
	color: #000;
	padding-bottom: 3.2px;
	margin-top: 3px;
}

.advance-filter-wrapper .dropdown-toggle .dropdown-icon:after,
.advance-filter-wrapper .dropdown-toggle::after {
	display: none;
}

.advance-filter-wrapper .dropdown-link {
	display: block;
	position: relative;
	font-size: 14px;
	text-decoration: none;
	padding: 6px;
}

.advance-filter-wrapper .dropdown-item.active,
.advance-filter-wrapper .dropdown-item:active {
	background-color: #fff;
}

.advance-filter-wrapper .dropdown-item.dropdown-selected {
	background-color: #ddd;
}

.advance-filter-wrapper .dropdown-title {
	font-size: 13px;
	text-align: left;
	padding: 0;
	margin: 0;
	color: #432135;
}

.advance-filter-wrapper .dropdown-close,
.advance-filter-wrapper .dropdown-back {
	position: absolute;
	text-decoration: none;
	top: 0;
	font-family: NiveauGroteskBold;
	color: #565656;
	font-size: 14px;
	width: 28px;
	height: 28px;
	line-height: 28px;
	text-align: center;
}

.advance-filter-wrapper .dropdown-header {
	position: relative;
	padding: 6px 25px;
	margin: 0;
	border-bottom: 1px solid #ddd;
	box-shadow: none;
}

.advance-filter-wrapper .dropdown-below .dropdown-menu-wrapper {
	top: 100%;
	bottom: auto;
	margin: 0;
	height: auto !important;
}

.badge-default {
	background-color: #ddd;
}

.project-info-grid,
.proj-progress-card {
	margin-bottom: 10px;
}

.applied-filters::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.applied-filters::-webkit-scrollbar-thumb {
	border-top: 3px solid #fff;
	border-bottom: 3px solid #fff;
	border-radius: 4px;
}

/* Breaker screen css  */
.svg-wrapper .standing-person {
	width: 80px;
}

.lock-bg {
	width: 250px;
	height: 170px;
	border-radius: 50px;
	background-color: #ddd;
	position: relative;
}

.lock-bg i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.1);
	font-size: 70px;
	color: #432135;
	visibility: hidden;
	animation: show-dollar 1s linear forwards;
	animation-delay: 5s;
	transform-origin: 0;
}

@keyframes show-dollar {
	to {
		visibility: visible;
		transform: translate(-50%, -50%) scale(1);
	}
}

.lock-bg .lock {
	position: relative;
	width: 80px;
	height: 80px;
	border-radius: 1em;
	top: 35%;
	left: 50%;
	transform: translateX(-50%);
	background: #432135;
	animation: scale-0 1s linear forwards;
	animation-delay: 3s;
	transform-origin: 0;
}

@keyframes scale-0 {
	to {
		transform: scale(0.1);
		visibility: hidden;
		transition: all 1s linear;
	}
}

.lock-bg .lock:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	border: 10px solid #432135;
	border-bottom: none;
	width: 50px;
	height: 40px;
	left: 15px;
	top: -35px;
	border-top-left-radius: 7em;
	border-top-right-radius: 7em;
	transform-origin: 14% 40%;
	animation: unlock 2s linear 1s forwards;
}

.lock-bg .lock:after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	border: 10px solid #ddd;
	width: 15px;
	height: 15px;
	border-radius: 100px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

/* Top section unlock motion */
@keyframes unlock {
	to {
		transform: rotateY(200deg);
	}
}

/*Welcome Screens*/
.welcome-wrapper {
	height: 100vh;
	background-color: #432135;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.welcome-wrapper .wolli-section {
	display: flex;
	justify-content: center;
	padding: 30px;
	align-items: center;
}

.welcome-wrapper .wolli-section .wolli {
	width: 200px;
	height: 200px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	margin-right: 30px;
}

.welcome-wrapper .wolli-section .wolli img {
	width: 100px;
}

.welcome-wrapper .wolli-section .message h2 {
	font-family: NiveauGroteskRegular;
	font-size: 40px;
	color: #fff;
	margin-bottom: 7px;
	margin-top: 0px;
}

.welcome-wrapper .wolli-section .message h4 {
	max-width: 600px;
	font-size: 22px;
	line-height: 30px;
	color: #fff;
	margin-bottom: 0;
}

.welcome-wrapper .grid-component {
	background: #fff;
	padding: 20px;
	border-radius: 5px;
	width: 230px;
	margin: 0 10px 0 10px;
	border: 1px solid #fff;
	height: 160px;
	display: flex;
	/* align-items: center; */
	justify-content: center;
}

.welcome-wrapper .grid-component .disabled {
	opacity: 0.5;
	cursor: pointer !important;
}

.welcome-wrapper .grid-component .icon {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

.welcome-wrapper .grid-component i {
	font-size: 50px;
	color: #432135;
}

.welcome-wrapper .grid-component h4 {
	font-size: 18px;
	text-transform: capitalize;
}

.welcome-wrapper .grid-component:hover {
	background: #ddd;
	border: 1px solid #ddd;
	cursor: pointer;
}

.welcome-wrapper .grid-component.seleted {
	background: #ddd;
	border: 1px solid #ddd;
	cursor: pointer;
}

.welcome-wrapper .card.sub-value {
	width: 250px;
	margin: 5px 10px;
}

.welcome-container-fluid {
	max-width: 1100px;
	margin: auto;
}

.welcome-wrapper .btn {
	padding: 7px 20px;
	color: #000;
	font-size: 18px;
	margin: 10px;
}

.welcome-wrapper .btn-primary {
	background: #84566b;
	border-color: #84566b;
}

/*hide header, sidebar, footer*/
.main-wrapper.welcome-layout .navbar-inner,
.main-wrapper.welcome-layout .toggle-arrow,
.main-wrapper.welcome-layout+.soft-footer {
	display: none;
}

.main-content.welcome-layout {
	width: 100%;
}

/* login loader  */
.dot-flashing {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #fff;
	color: #fff;
	animation: dotFlashing 1s infinite linear alternate;
	animation-delay: 0.5s;
	margin: auto;
}

.dot-flashing::before,
.dot-flashing::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
}

.dot-flashing::before {
	left: -15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #fff;
	color: #fff;
	animation: dotFlashing 1s infinite alternate;
	animation-delay: 0s;
}

.dot-flashing::after {
	left: 15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #fff;
	color: #fff;
	animation: dotFlashing 1s infinite alternate;
	animation-delay: 1s;
}

@keyframes dotFlashing {
	0% {
		background-color: #fff;
	}

	50%,
	100% {
		background-color: #ffffff20;
	}
}

/* circular processbar  */
.progress-circular {
	width: 70px;
	height: 70px;
	background: #e9b5cd;
	margin: 0 auto;
	box-shadow: none;
	position: relative;
	border-radius: 50px;
}

.progress-circular:after {
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 7px solid transparent;
	position: absolute;
	top: 0;
	left: 0;
}

.progress-circular>span {
	width: 50%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 1;
}

.progress-circular .progress-left {
	left: 0;
}

.progress-circular .progress-bar {
	width: 100%;
	height: 100%;
	background: none;
	border-width: 7px;
	border-style: solid;
	position: absolute;
	top: 0;
	border-color: #432135;
}

.progress-circular .progress-left .progress-bar {
	left: 100%;
	border-top-right-radius: 75px;
	border-bottom-right-radius: 75px;
	border-left: 0;
	-webkit-transform-origin: center left;
	transform-origin: center left;
}

.progress-circular .progress-right {
	right: 0;
}

.progress-circular .progress-right .progress-bar {
	left: -100%;
	border-top-left-radius: 75px;
	border-bottom-left-radius: 75px;
	border-right: 0;
	-webkit-transform-origin: center right;
	transform-origin: center right;
}

.progress-circular .progress-value {
	display: flex;
	font-size: 30px;
	text-align: center;
	align-items: center;
	justify-content: center;
	height: 100%;
	color: #432135;
}

.progress-circular .progress-value div {
	margin-top: 10px;
}

.progress-circular .progress-value span {
	font-size: 12px;
	text-transform: uppercase;
}

.progress-circular[data-percentage="10"] .progress-right .progress-bar {
	animation: loading-1 1.5s linear forwards;
}

.progress-circular[data-percentage="10"] .progress-left .progress-bar {
	animation: 0;
}

.progress-circular[data-percentage="20"] .progress-right .progress-bar {
	animation: loading-2 1.5s linear forwards;
}

.progress-circular[data-percentage="20"] .progress-left .progress-bar {
	animation: 0;
}

.progress-circular[data-percentage="30"] .progress-right .progress-bar {
	animation: loading-3 1.5s linear forwards;
}

.progress-circular[data-percentage="30"] .progress-left .progress-bar {
	animation: 0;
}

.progress-circular[data-percentage="40"] .progress-right .progress-bar {
	animation: loading-4 1.5s linear forwards;
}

.progress-circular[data-percentage="40"] .progress-left .progress-bar {
	animation: 0;
}

.progress-circular[data-percentage="50"] .progress-right .progress-bar {
	animation: loading-5 1.5s linear forwards;
}

.progress-circular[data-percentage="50"] .progress-left .progress-bar {
	animation: 0;
}

.progress-circular[data-percentage="60"] .progress-right .progress-bar {
	animation: loading-5 1.5s linear forwards;
}

.progress-circular[data-percentage="60"] .progress-left .progress-bar {
	animation: loading-1 1.5s linear forwards 1.5s;
}

.progress-circular[data-percentage="70"] .progress-right .progress-bar {
	animation: loading-5 1.5s linear forwards;
}

.progress-circular[data-percentage="70"] .progress-left .progress-bar {
	animation: loading-2 1.5s linear forwards 1.5s;
}

.progress-circular[data-percentage="80"] .progress-right .progress-bar {
	animation: loading-5 1.5s linear forwards;
}

.progress-circular[data-percentage="80"] .progress-left .progress-bar {
	animation: loading-3 1.5s linear forwards 1.5s;
}

.progress-circular[data-percentage="90"] .progress-right .progress-bar {
	animation: loading-5 1.5s linear forwards;
}

.progress-circular[data-percentage="90"] .progress-left .progress-bar {
	animation: loading-4 1.5s linear forwards 1.5s;
}

.progress-circular[data-percentage="100"] .progress-right .progress-bar {
	animation: loading-5 1.5s linear forwards;
}

.progress-circular[data-percentage="100"] .progress-left .progress-bar {
	animation: loading-5 1.5s linear forwards 1.5s;
}

@keyframes loading-1 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(36);
		transform: rotate(36deg);
	}
}

@keyframes loading-2 {
	0% {
		-webkit-transform: rotate(36deg);
		transform: rotate(36deg);
	}

	100% {
		-webkit-transform: rotate(72);
		transform: rotate(72deg);
	}
}

@keyframes loading-3 {
	0% {
		-webkit-transform: rotate(72deg);
		transform: rotate(72deg);
	}

	100% {
		-webkit-transform: rotate(108);
		transform: rotate(108deg);
	}
}

@keyframes loading-4 {
	0% {
		-webkit-transform: rotate(108deg);
		transform: rotate(108deg);
	}

	100% {
		-webkit-transform: rotate(144);
		transform: rotate(144deg);
	}
}

@keyframes loading-5 {
	0% {
		-webkit-transform: rotate(144deg);
		transform: rotate(144deg);
	}

	100% {
		-webkit-transform: rotate(180);
		transform: rotate(180deg);
	}
}

.popsearchsticky {
	position: sticky;
	top: 0px;
	z-index: 2;
	background: #fff;
}

.search-faq button.clear-icon {
	top: 7px;
	right: 12px;
}

.faq-count {
	width: 18px;
	height: 18px;
	background-color: #432135;
	color: #fff;
	text-align: center;
	line-height: 18px;
	display: inline-block;
	border-radius: 30px;
	font-size: 12px;
}

.autocomplete-items .list-group-item {
	padding: 3px 10px;
	border-top: 1px solid #ddd;
	border-bottom: none;
	border-left: none;
	border-right: none;
	word-break: break-word;
}

.letterError {
	color: #ff0000;
	font-size: 12px;
	margin-top: 5px;
}

.poprightticky {
	position: sticky;
	position: -webkit-sticky;
	top: 38px;
}

/* slider css react  */
.react-slideshow-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
}

.react-slideshow-container .nav {
	z-index: 10;
}

.react-slideshow-container .default-nav {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	height: 80px;
	width: 34px;
	border-radius: 0;
	width: 30px;
	text-align: center;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #432135;
	border: none;
	box-shadow: none;
	color: #fff;
}

.react-slideshow-container .default-nav.disabled {
	display: none;
}

.react-slideshow-container .each-slide .btn-icon {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 99;
	background-color: #432135;
	border-color: #432135;
	color: #fff;
}

.react-slideshow-container .each-slide .btn-icon:hover {
	background-color: #84566b;
	border-color: #84566b;
	color: #fff;
}

.react-slideshow-container .default-nav svg {
	width: 18px;
	fill: #fff;
}

.react-slideshow-container .default-nav:hover,
.react-slideshow-container .default-nav:focus {
	background-color: #84566b;
	border-color: #84566b;
}

.react-slideshow-container .default-nav:hover svg,
.react-slideshow-container .default-nav:focus svg {
	fill: #fff;
}

.react-slideshow-container .default-nav.disabled:hover {
	cursor: not-allowed;
}

.react-slideshow-container .default-nav:first-of-type {
	left: 0;
}

.react-slideshow-container .default-nav:last-of-type {
	right: 0;
}

.react-slideshow-wrapper.slide {
	width: 100%;
	overflow: hidden;
}

.react-slideshow-wrapper .images-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.react-slideshow-wrapper .each-slide {
	position: relative;
	text-align: center;
}

.react-slideshow-wrapper .each-slide .form-group-note {
	width: 85%;
	margin: 0px auto;
}

.react-slideshow-wrapper .each-slide .image-wrapper {
	height: calc(100vh - 170px);
	width: 92%;
	margin: 0px auto;
	border: 1px solid #ddd;
	padding: 12px;
	overflow-y: auto;
}

.react-slideshow-wrapper .each-slide .image-wrapper>img {
	max-width: 100%;
	height: auto;
	object-position: center;
	user-select: none;
	pointer-events: none;
}

/*My collection -team assessment*/
.player-row .images {
	white-space: nowrap;
}

.player-row .images img {
	width: 30px;
	height: 30px;
	overflow: hidden;
	border-radius: 100px;
	border: 2px solid #fff;
}

.player-row .images img+img {
	margin-left: -12px;
}

.player-row textarea:read-only {
	width: 100%;
	height: auto;
	border: none;
	resize: none;
	padding: 0px;
}

.player-row textarea {
	width: 100%;
	height: auto;
	resize: none;
	padding: 5px;
	border-color: #ddd;
}

.player-row .add-rank {
	position: absolute;
	top: 4px;
	width: 100%;
	display: none;
	z-index: 9;
}

.player-row:hover .add-rank {
	display: block;
}

.rank-slider {
	width: 50%;
	margin-left: 80px;
}

.other-tab {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
}

.other-tab img {
	width: 30%;
}

.actorDesign.error .form-control {
	border-color: #ff0000;
}

.conflict-wrapper .card {
	background: #dddddd;
	box-shadow: none;
	border: none;
}

.conflict-wrapper .card-header {
	margin: 0;
	border: none;
	border-bottom: 1px solid #fff;
	font-size: 16px;
}

.conflict-wrapper .card ul li {
	background: #fff;
	padding: 5px 15px 5px 5px;
	border-radius: 4px;
	display: inline-block;
	margin-bottom: 6px;
	width: 100%;
	cursor: pointer;
}

.conflict-wrapper .card ul li p {
	margin: 0px;
}

.conflict-wrapper .card .selected {
	color: #fff;
	background-color: #432135 !important;
}

.duplicate-detail+.duplicate-detail {
	margin-top: 10px;
	border-top: 1px solid #ddd;
}

.ruleVoilated {
	background-color: #84566b;
	color: #fff;
}

/* sidebar content  */
.main-wrapper.hidden-sidebar .navbar-inner {
	transform: translateX(-230px);
	width: 0px;
	transition: 0.25s linear;
}

.main-wrapper.hidden-sidebar .toggle-arrow {
	transform: translateX(-230px);
	transition: 0.25s linear;
}

.main-wrapper.hidden-sidebar .toggle-arrow i {
	transform: rotate(-180deg);
}

.main-wrapper.hidden-sidebar .main-content {
	width: 100%;
	transition: 0.25s linear;
}

.navbar-inner {
	width: 230px;
	height: calc(100vh - 80px);
	background-color: #432135;
	position: relative;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	float: left;
	transition: 0.25s linear;
}

.navbar-inner .nav-group {
	margin: 3px 0px;
}

.toggle-arrow {
	height: 35px;
	color: #fff;
	background: #432135;
	width: 35px;
	position: absolute;
	left: 229px;
	border-radius: 0 4px 4px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 99;
	transition: 0.25s linear;
}

.navbar-inner h5 button {
	border: none;
	background-color: transparent;
	font-size: 14px;
	color: #fff;
	cursor: pointer;
	text-align: left;
	padding: 10px;
	border-radius: 0px;
	display: flex;
	position: relative;
	width: 100%;
	align-items: center;
}

.navbar-inner h5 button:hover {
	background-color: #84566b;
}

.navbar-inner h5 button.active {
	background-color: #84566b;
}

.navbar-inner h5 button i {
	font-size: 16px;
	min-width: 35px;
	text-align: center;
}

.navbar-inner .sub-nav-group button {
	opacity: 1;
	cursor: pointer;
	pointer-events: initial;
}

.navbar-inner .sub-nav-group button:disabled {
	opacity: 0.2;
	cursor: not-allowed;
	pointer-events: none;
}

.navbar-inner .sub-nav-group button.active {
	background-color: transparent;
	color: #432135;
	background: #fff;
	border-radius: 4px;
}

.navbar-inner .nav-group .card-body {
	padding: 5px 5px 5px 20px;
	background: #84566b;
}

.navbar-inner h5 button .nav-icon {
	width: 30px;
	text-align: center;
	display: inline-block;
}

.navbar-inner h5 button .nav-icon img {
	width: 18px;
}

.navbar-inner .sub-nav-group ul li button {
	border: none;
	background-color: transparent;
	font-size: 14px;
	color: #fff;
	cursor: pointer;
	text-align: left;
	padding: 5px 10px;
	border-radius: 0px;
	display: block;
	position: relative;
	width: 100%;
}

.navbar-inner .sub-nav-group .card-body {
	padding-left: 30px;
}

.navbar-inner .sub-nav-group .card-body .sub-nav-group .card-body {
	padding-left: 10px;
}

.navbar-inner h5 button.no-arrow::after {
	display: none;
}

.bottom-navigation {
	margin-top: auto;
}

.navbar-inner .nav-group h5 button:after {
	content: "\f078";
	font-family: "FontAwesome";
	position: absolute;
	right: 10px;
	font-size: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.navbar-inner .nav-group h5>button[aria-expanded="true"]:after {
	content: "\f077";
}

.type-conflict>span {
	height: 18px;
	background: #ffffff;
	width: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #432135;
	font-size: 11px;
	border-radius: 3px;
	margin-left: 3px;
}

.conflict-wrapper .footer-widget {
	cursor: pointer;
}

.animated-steps .multi-ripple {
	position: absolute;
	top: -15px;
	left: -17px;
}

.white-overlay {
	position: absolute;
	background: #fff;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 99;
}

.project-detail-modal p {
	font-size: 12px;
	color: #565656 !important;
	margin-bottom: 0;
}

.project-detail-modal .modal-body h5 {
	font-size: 14px;
	margin: 0;
}

.project-detail-modal .logo-wrapper {
	width: 130px;
	min-width: 130px;
	padding: 10px;
	border: 1px solid #ddd;
	max-width: 130px;
	overflow: hidden;
}

.Toastify__toast--info {
	background: #432135 !important;
}

.Toastify__toast-container--bottom-right {
	bottom: 20px !important;
	right: 20px !important;
}

.Toastify__toast-container {
	width: 480px !important;
}

.Toastify__toast-body {
	color: #fff;
}

.Toastify__close-button--light {
	color: #fff !important;
}

.Toastify--animate-icon svg {
	fill: #fff;
}

.toast-wolli {
	width: 60px;
	height: 60px;
	min-width: 60px;
	border-radius: 100%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.toast-wolli>img {
	width: 55px;
	height: 55px;
	object-fit: contain;
}

/* commented by navneet .main-body.vault-layout .page-wrapper {
	height: 100vh;
} */

.main-body.vault-layout .table-auto {
	max-height: initial;
}

.main-body.vault-layout .tooltip .tooltip__content {
	bottom: initial;
	top: 60px;
	z-index: 999;
}

.main-body.vault-layout .tooltip .tooltip__content::after {
	top: initial;
	bottom: 100%;
	transform: rotate(180deg);
}

body.ReactModal__Body--open {
	overflow: hidden;
}

.slider-handle {
	width: 18px;
	height: 18px;
	display: "flex";
	background: #000;
	border-radius: 5px;
	cursor: pointer;
	border: 1px solid #565656;

	background-color: #fff;
	border-color: #565656;
}

.user-actions {
	display: flex;
	justify-content: flex-end;
	left: 0;
	right: 0;
	position: absolute;
	flex-direction: column;
	align-items: flex-end;
}

.column-list li {
	min-width: 33.33%;
	flex-basis: 33.33%;
	margin-bottom: 7px;
}

mark {
	background-color: #84566b;
	color: #fff;
	padding: 0em;
}

/* .modal-body {
	user-select: none;
}

.modal-header {
	user-select: none;
} */

.contract-list-row label {
	margin: 0 0 0 0;
	font-size: 12px;
	letter-spacing: 0.2px;
	color: #565656;
}

.my-collection .tooltip .tooltip__content {
	bottom: initial;
	top: 60px;
	z-index: 999;
}

.my-collection .tooltip .tooltip__content::after {
	top: initial;
	bottom: 100%;
	transform: rotate(180deg);
}

.flex-flow-column {
	flex-flow: column;
}

.overflowY-auto {
	overflow-y: auto !important;
}

.overflowX-hidden {
	overflow-x: hidden !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.beta-icon {
	font-weight: 600;
	top: 5px;
	right: -12px;
	position: absolute;
	color: #432135;
	text-transform: initial;
}

.logo-wrapper {
	display: inline-block;
}

.logo-text {
	position: absolute;
	bottom: 36px;
	font-size: 11px;
	left: 70px;
	color: #565656;
}

.navbar-wrapper .logo-text {
	bottom: 25px;
	font-size: 10px;
	white-space: nowrap;
	left: 52px;
}

.navbar-wrapper .beta-icon {
	top: -2px;
	right: -10px;
	text-transform: initial;
}

.ReactModal__Content .wolli-img {
	background: #fff;
	width: 75px;
	min-width: 75px;
	height: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
}

.bg-light {
	background-color: #ddd !important;
}

.financials-grid {
	border-radius: 4px;
}

.financials-grid h5 {
	font-size: 20px;
	font-family: NiveauGroteskBold;
}

.other-user-grid .img-circle {
	width: 45px;
	height: 45px;
	object-fit: cover;
}

.compare-product-table th,
.compare-product-table td {
	padding: 10px 12px;
}

.compare-product-table tbody th {
	background-color: #fff;
	font-size: 14px;
}

.compare-product-table th {
	font-size: 14px;
}

.compare-product-table td {
	font-size: 14px;
}

.compare-product-table td.click:hover {
	color: #000;
	background-color: #f0f0f0;
	cursor: pointer;
}

.mis-top-info {
	background: #fff;
	padding: 7px;
	border-radius: 4px;
	margin-right: 10px;
	cursor: pointer;
	border: 1px solid #ddd;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.mis-top-info:hover {
	background-color: #f3f3f3;
}

.mis-top-info.highlight {
	background-color: #ddd;
}

.btn-toggle-priority:before {
	content: "First priority";
	left: -90px;
	text-align: right;
	width: auto;
}

.btn-toggle-priority:after {
	content: "Second priority";
	right: -110px;
	opacity: 0.5;
	text-align: left;
	width: auto;
}

.navigation-button {
	background: transparent;
	border: none;
	color: #432135;
	padding: 5px;
}

.navigation-button:hover {
	color: #84566b;
	cursor: pointer;
}

/* upcoming css  */

.we-exist .grid h6 {
	font-family: NiveauGroteskBold;
}

.sideBarCurrentProject {
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block !important;
	padding-right: 24px !important;
}

.market-highlights .grid {
	padding: 5px 10px;
	font-size: 14px;
	border-radius: 4px;
	background-color: #ddd;
	color: #432135;
	line-height: 20px;
}

.market-highlights .grid>i {
	font-size: 20px;
}

.bg-primary {
	background-color: #432135 !important;
}

.we-exist .line {
	border-top: 2px dashed #432135;
	position: absolute;
	left: 20px;
	right: 30px;
	top: 12px;
}

.we-exist .grid {
	-ms-flex-preferred-size: 25%;
	flex-basis: 25%;
}

.we-exist .grid>span {
	width: 25px;
	height: 25px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	border-color: #432135;
	background: #e69bbe;
	border: 2px solid;
	border-radius: 100px;
	position: relative;
}

.we-exist .grid small {
	font-size: 12px;
	color: #000;
	text-transform: uppercase;
	font-family: NiveauGroteskBold;
}

.we-exist .grid p {
	margin-bottom: 0;
	font-size: 13px;
	color: #000;
	padding-right: 15px;
}

.footer-widget.selected {
	background-color: #432135;
	color: #fff;
}

.footer-widget.selected .toast-header .leftWidth strong {
	color: #fff;
}

.show-more-height {
	max-height: 72px;
	overflow: hidden;
}

.show-more-btn {
	background-color: transparent;
	padding: 5px 0 0 0;
	border: none;
	color: #432135;
	cursor: pointer;
	font-size: 13px;
}

.show-more-btn-dropdown {
	background-color: transparent;
	padding: 5px 10px 8px 10px;
	border: none;
	color: #432135;
	cursor: pointer;
	font-size: 13px;
	text-transform: capitalize;
	display: inline-block;
	margin-top: -5px;
}

.show-more-btn:hover {
	color: #84566b;
}

.cursor-pointer {
	cursor: pointer;
}

.nowrap {
	white-space: nowrap !important;
}

.hover-image-wrapper {
	overflow: hidden;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border: 1px solid #ddd;
	position: fixed;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	top: 50%;
	background-color: #fff;
	/* z-index: -1; */
	width: 30%;
	height: 50%;
	/* opacity: 0;
	visibility: hidden; */
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.hover-image-wrapper>img {
	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: contain;
}

.hover-wrapper>a:hover+.hover-image-wrapper {
	display: block;
	z-index: 9;
	width: 35%;
	height: 70%;
	opacity: 1;
	visibility: visible;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.wheel-lagends {
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.wheel-lagends li {
	color: #565656;
	font-size: 12px;
	white-space: nowrap;
	margin-right: 20px;
	margin-bottom: 5px;
}

.wheel-lagends li i {
	margin-right: 5px;
}

.actualValue {
	color: #565656;
}

.d-flex.align-items-center.imp-tip-wrapper {
	position: relative;
	max-width: 100%;
	left: 0;
	top: 0;
	transform: initial;
	-webkit-transform: initial;
	-moz-transform: initial;
	-ms-transform: initial;
	-o-transform: initial;
}

.d-flex.align-items-center.imp-tip-wrapper.module-3 {
	height: 0px;
	overflow: hidden;
}

.d-flex.align-items-center.imp-tip-wrapper.imp_popup {
	position: absolute;
	z-index: 9999;
	border-radius: 100px;
	color: #fff;
	background-color: #432135;
	padding: 12px;
	width: 60%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transition: all 0.5s linear;
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	margin-left: -115px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
	height: auto;
	overflow: visible;
}

.d-flex.align-items-center.imp-tip-wrapper.imp_popup .wolli-message {
	margin-left: 0;
}

.main-wrapper.hidden-sidebar .main-content .d-flex.align-items-center.imp-tip-wrapper.imp_popup {
	margin-left: 0px;
}

.d-flex.align-items-center.imp-tip-wrapper.imp_popup .wolli-img {
	background: #fff;
	width: 75px;
	min-width: 75px;
	height: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
}

.d-flex.align-items-center.imp-tip-wrapper.imp_popup .talk-bubble .talktext {
	background-color: transparent;
}

.d-flex.align-items-center.imp-tip-wrapper.imp_popup .tri-right.left-in #basicWonk:after,
.d-flex.d-flex.align-items-center.imp-tip-wrapper.imp_popup .tri-right.left-in .talktext:after {
	border-color: transparent transparent transparent transparent;
}

.imp_pop_cross {
	font-size: 20px;
	cursor: pointer;
	color: #fff;
}

.accordion-filter {
	background-color: #fff;
	padding: 5px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	position: absolute;
	z-index: 9;
	top: 35px;
	border: 1px solid #ddd;
	width: 100%;
}

.accordion-filter .card,
.accordion-filter .card-header {
	border: none;
	padding: 0px;
	margin: 0px;
	box-shadow: none;
	background-color: transparent;
}

.accordion-filter .card h2 {
	border: none;
	padding: 0px;
	margin: 0px;
	box-shadow: none;
	background-color: transparent;
}

.accordion-filter .card h2 .btn-link {
	background-color: transparent;
	font-size: 13px;
	text-decoration: none;
	padding: 3px 3px;
	font-family: NiveauGroteskBold;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.accordion-filter .card h2 .btn-link::after {
	content: "";
	border-bottom: 0;
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-left: 0.3em solid transparent;
}

.accordion-filter .card h2 .btn-link[aria-expanded="true"]::after {
	content: "";
	border-top: 0;
	border-bottom: 0.3em solid;
}

.applied-filter-wrapper-advance .applied-filters {
	position: absolute;
	top: 5px;
	width: 100%;
	overflow-x: auto;
}

.rbc-event {
	background-color: #432135 !important;
}

.rbc-today {
	background-color: #84566b !important;
}

.rotate-180 {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
}

.map-view {
	width: 70px;
	min-width: 70px;
	height: 60px;
	border-radius: 4px;
	overflow: hidden;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	border: 1px solid #ddd;
	-o-border-radius: 4px;
	margin-left: 20px;
	cursor: pointer;
	position: relative;
}

.map-view img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
}

.map-view span {
	color: #565656;
	font-size: 10px;
	text-align: center;
	background-color: #ddd;
	display: block;
	position: absolute;
	padding: 3px 0px;
	left: 0;
	right: 0;
	bottom: 0;
}

/* Insight Module css  */
.insight-tabs.nav-pills .nav-link.active,
.insight-tabs.nav-pills .nav-link.active:focus,
.insight-tabs.nav-pills .nav-link.active:hover,
.insight-tabs.nav-pills .nav-link.active,
.insight-tabs.nav-pills .nav-link.active:focus,
.insight-tabs.nav-pills .nav-link.active:hover,
.insight-tabs.nav-pills .nav-link.active.active,
.insight-tabs.nav-pills .nav-link.active.active:focus,
.insight-tabs.nav-pills .nav-link.active.active:hover {
	padding: 0;
	padding-bottom: 0px;
	text-decoration: none;
	border-bottom: 2px solid #432135;
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	-ms-border-radius: 0px;
	-o-border-radius: 0px;
}

.insight-tabs.nav-pills .nav-link,
.insight-tabs.nav-pills .nav-link:focus,
.insight-tabs.nav-pills .nav-link:hover,
.insight-tabs.nav-pills .nav-link,
.insight-tabs.nav-pills .nav-link:focus,
.insight-tabs.nav-pills .nav-link:hover,
.insight-tabs.nav-pills .nav-link,
.insight-tabs.nav-pills .nav-link:focus,
.insight-tabs.nav-pills .nav-link:hover {
	padding: 0;
	padding-bottom: 0px;
	text-decoration: none;
	border-radius: 0px;
	border-bottom: 2px solid transparent;
	color: #777;

	margin-right: 20px;
}

.insight-content .image img {
	height: 35px;
	width: 35px;
}

.insight-content .graph-grid {
	width: 100%;
	cursor: pointer;
	max-width: 150px;
}

.insight-content .graph-grid h5 {
	font-size: 14px;
}

.btn-icon {
	width: 26px;
	min-width: 26px;
	height: 26px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: none;
	color: #777;
	cursor: pointer;
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #fff;
}

.btn-icon.selected {
	background-color: #432135;
	color: #fff;
	border-color: #432135;
}

.btn-icon.disabled {
	opacity: .5;
}

.btn-icon.disabled:hover,
.btn-icon.disabled:focus {
	color: #777;
	outline: none;
	box-shadow: none;
}

.btn-icon:hover,
.btn-icon:focus {
	color: #000;
	outline: none;
	box-shadow: none;
}

.btn-icon.selected:hover,
.btn-icon.selected:focus {
	color: #fff;
	outline: none;
	box-shadow: none;
}

.graph-grid.selected .card.card-border {
	border: 1px solid #432135;
}

.insight-content .graph-grid.selected h5 {
	color: #432135 !important;
}

.applied-filters::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.applied-filters::-webkit-scrollbar-thumb {
	border-top: 3px solid #fff;
	border-bottom: 3px solid #fff;
	border-radius: 4px;
}

.regu-lables {
	position: absolute;
	height: 170px;
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: center;
}

.scrollwidth-td {
	width: calc(100% - 35px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	text-align: center;
}

.sentenceCase::first-letter {
	text-transform: capitalize;
}

.toUpperCase {
	text-transform: uppercase;
}

.cluster-row {
	margin-bottom: 2px;
	background-color: #ddd;
	border-radius: 4px;
	padding: 5px 10px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.allWordsFirstCaps {
	text-transform: capitalize;
}

.gmnoprint,
.gm-style-cc {
	display: none;
}

.rbc-day-slot .rbc-event {
	display: block !important;
	font-size: 10px !important;
	border: none !important;
	padding: 2px !important;
	height: auto !important;
}

.rbc-event-label {
	font-size: 10px !important;
	white-space: normal !important;
	overflow: visible;
	padding-right: 0px !important;
}

.rbc-day-slot .rbc-event-content {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.rbc-day-slot .rbc-events-container {
	margin-right: 0px !important;
}

.rbc-timeslot-group {
	min-height: 60px !important;
}

select.form-control.varient-select {
	width: 230px;
	height: 26px !important;
	padding: 0px;
	background-position-y: 4px !important;
}

.insight-poc-name {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	position: relative;
}

.playerHeading {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: relative;
}

.insight-poc-name>span:first-child {
	color: #565656;
	text-decoration: underline;
}

.insight-poc-name>span:first-child:hover {
	color: #432135;
}

.insight-poc-name-right {
	color: #565656;
	position: relative;
}

.insight-poc-name-right span:first-child {
	width: calc(100% - 14px);
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.poc-saved-icon {
	position: absolute;
	right: 0px;
	z-index: 2;
	background-color: #fff;
}

.poc-saved-icon .fa-check-circle {
	color: #432135;
	cursor: pointer;
}

.poc-saved-icon .fa-check-circle.disabled {
	opacity: .7;
}

.poc-saved-icon .fa-circle {
	color: #ddd;
	cursor: pointer;
}

.header-capabiltity-name {
	text-align: left;
	line-height: normal;
}

.header-capabiltity-name label {
	color: #565656;
	margin-bottom: 0;
}

.backendSummary ul {
	list-style-type: disc;
	padding-left: 15px;
}

.backendSummary ol {
	list-style-type: decimal;
	padding-left: 15px;
}

.backendSummary h2 {
	font-size: 32px;
}

.advance-filter-wrapper .accordion-filter ul {
	max-height: 135px;
	overflow-y: auto;
}

.table-list,
.table-list-modal {
	list-style: disc;
	padding-left: 15px;
}

.table-list li:not(:first-child) {
	display: none;
}

.table-list li:first-child {
	white-space: nowrap;
	/* overflow: hidden; */
	text-overflow: ellipsis;
}

.np-symbol {
	width: 16px;
	height: 16px;
	display: inline-block !important;
	border: 1px solid #432135;
	font-size: 9px !important;
	font-weight: 600;
	border-radius: 50px;
	line-height: 16px !important;
	text-align: center;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	-o-border-radius: 50px;
}

/*Added by Sanjay Sir*/
.booked {
	cursor: default !important;
	background-color: #E69BBE !important;
}

/* square graphs html  */
.html-graph-wrapper {
	display: flex;
	width: 100%;
}

.html-graph-wrapper-value {
	height: calc(100vh - 358px);
	padding: 10px;
	display: flex;
	min-width: calc(100% - 125px);
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 0;
}

.html-graph-wrapper-value.bubble-graph {
	height: calc(100vh - 350px);
	padding-bottom: 0;
}

.graph-toggle .html-graph-wrapper-value.bubble-graph {
	height: calc(100vh - 106px);
}

.graph-column-y {
	height: calc(100vh - 426px);
	padding: 10px;
	display: flex;
	min-width: 125px;
	max-width: 125px;
	width: 125px;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
}

.graph-toggle .html-graph-wrapper-value {
	height: calc(100vh - 114px);
}

.graph-toggle .graph-column-y {
	height: calc(100vh - 180px);
}

.graph-column-y .graph-cell {
	flex-grow: 1;
	min-height: 40px;
	border-bottom: 1px solid #fff;
	position: relative;
	width: 100%;
	text-align: left;
	margin-top: -10px;
	display: flex;
}

.graph-column-y .graph-cell .graph-cell-name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
	width: 100%;
}

.graph-column-y .graph-cell .graph-cell-name>span {
	/* position: absolute;
  top: -8px;
  right: 0;
  text-align: right; */
	white-space: nowrap;
	color: #565656;
	cursor: pointer;
	text-decoration: underline;
}

.graph-column-y .graph-cell .graph-cell-name>span:hover {
	color: #432135;
}

.graph-column-y .graph-cell .graph-cell-name>span.selected {
	color: #432135;
}

.graph-icon-filter .filter span>label>span {
	font-size: 16px;
	background-color: #fff;
	border: 1px solid #fff;
	width: 26px;
	height: 26px;
}

.graph-icon-filter .filter span>label>span.disabled {
	opacity: .6;
}

.graph-icon-filter .filter span>label>span:hover {
	background-color: #ddd;
}

.graph-icon-filter .filter span>label>span.disabled:hover {
	background-color: #fff;
}

.graph-icon-filter .filter span>label>span .lagend-symbol {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-size: 14px;
	height: 100%;
	color: #565656;
	margin-top: 1px;
}

.graph-icon-filter .filter label span.selected {
	background-color: #fff;
	border: 1px solid #432135;
}

.graph-column-y .graph-cell>i {
	font-size: 12px;
	color: #432135;
	cursor: pointer;

}

.guide-checkbox .checkbox-fade .cr {
	width: 16px;
}

.guide-checkbox label>span:last-child {
	line-height: initial;
	width: 100%;
	white-space: initial;
	text-align: left;
}


.graph-column-y .graph-cell>i:hover {
	color: #84566b;
}

.graph-row-x {
	display: flex;
	justify-content: space-between;
}

.graph-row-x .graph-cell-x {
	flex-grow: 1;
	margin-left: -3px;
	color: #565656;
	font-size: 13px;
}

.graph-row {
	display: flex;
	justify-content: space-between;
	flex-basis: 100%;
}

.graph-row .graph-cell {
	border-bottom: 1px solid #ddd;
	border-left: 1px solid #ddd;
	position: relative;
	flex-grow: 1;
	min-height: 30px;
}

.graph-row .graph-cell:last-child {
	border-right: 1px solid #ddd;
}

/* .graph-row:first-child .graph-cell:after{
	content: '';
	position: absolute;
    width: 1px;
    height: 10px;
    background: #ddd;
    top: -10px;
    left: -1px;
} */
/* .graph-row .graph-cell:first-child::before{
	content: '';
	position: absolute;
    width: 10px;
    height: 1px;
    background: #ddd;
    top: -1px;
    left: -10px;
} */
.graph-row:first-child .graph-cell {
	border-top: 1px solid #ddd;
}

.graph-row .graph-cell .span-wrapper {
	position: absolute;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	z-index: 2;
}

.graph-row .graph-cell .span-wrapper>span {
	display: inline-block;
	margin: .5px;
	width: 16px;
	height: 16px;
	background-color: #432135;
	color: #fff;
	text-transform: uppercase;
	font-size: 13px;
	text-align: center;
	line-height: 16px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	cursor: pointer;
}

.graph-row .graph-cell .span-wrapper>span.selected {
	background-color: #84566b;
}

.html-graph-lagends {
	display: flex;
	justify-content: center;
}

.html-graph-lagends li {
	margin: 10px;
	color: #565656;
	font-size: 12px;
}

.html-graph-lagends li span {
	min-width: 16px;
	height: 16px;
	display: inline-block;
	background-color: #432135;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 16px;
	font-size: 12px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	margin-right: 5px;
	vertical-align: text-bottom;
}

/* bubble graph html  */
.graph-row .graph-cell.bubble-graph-cell>span {
	position: absolute;
	width: 16px;
	height: 16px;
	background-color: #432135;
	opacity: .9;
	color: #fff;
	z-index: 2;
	text-transform: initial;
	font-weight: initial;
	margin-left: -8px;
	margin-top: -8px;
	text-align: center;
	line-height: initial;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
	cursor: pointer;
	transform: scale(.7);
	-webkit-transform: scale(.7);
	-moz-transform: scale(.7);
	-ms-transform: scale(.7);
	-o-transform: scale(.7);
}

.graph-row .graph-cell.bubble-graph-cell>span:hover {
	opacity: 1;
}

.graph-row .graph-cell.bubble-graph-cell>span.selected {
	background-color: #84566b;
}

.graph-row-x .graph-cell-x.bubble-graph-cell-x {
	margin-left: 0;
	position: relative;
	flex-grow: initial;
	height: 18px;
}

.graph-row-x .graph-cell-x.bubble-graph-cell-x>span {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	color: #565656;
	font-size: 13px;
}

.graph-column-y.bubble-graph-column-y {
	height: calc(100vh - 380px);
}

.graph-toggle .graph-column-y.bubble-graph-column-y {
	height: calc(100vh - 134px);
}

.show-more-height-dropdown {
	max-height: 42px;
	overflow: hidden;
}

.pagination {
	margin: 0;
	padding: 0;
	text-align: center
}

.pagination li {
	display: inline-block;
	margin: 0px 2px;

}

.pagination li a {
	display: inline-block;
	text-decoration: none;
	padding: 0px 7px;
	color: #000;
	font-size: 13px;
	font-family: "NiveauGroteskRegular";
}

/* Active and Hoverable Pagination */
.pagination li a {
	border-radius: 5px;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s
}

.pagination li a.active {
	background-color: #432135;
	color: #fff
}

.pagination li a:hover:not(.active) {
	background-color: #fff;
}

.vm-graph-portfolio-side .card-body {
	height: calc(100vh - 270px);
	overflow-y: auto;
	overflow-x: hidden;
}

.vm-graph-portfolio-side-scouting .card-body {
	height: calc(100vh - 270px);
	overflow-y: auto;
	overflow-x: hidden;
}

.re-graph-portfolio-side .card-body {
	height: calc(100vh - 376px);
	overflow-y: auto;
	overflow-x: hidden;
}

.graph-toggle .vm-graph-portfolio-side .card-body {
	height: calc(100vh - 132px);
	overflow-y: auto;
}

.vm-graph-market-side .card-body {
	height: calc(100vh - 236px);
	overflow-y: auto;
	overflow-x: hidden;
}

.vm-graph-market-side-st .card-body {
	height: calc(100vh - 310px);
	/* //height: calc(100vh - 278px); */
	overflow-y: auto;
	overflow-x: hidden;
}

.re-graph-market-side .card-body {
	height: calc(100vh - 342px);
	overflow-y: auto;
	overflow-x: hidden;
}

.ps-graph-right-side .card-body {
	height: calc(100vh - 342px);
	overflow-y: auto;
	overflow-x: hidden;
}

.ps-graph-right-side.show-btn .card-body {
	height: calc(100vh - 368px);
	overflow-y: auto;
	overflow-x: hidden;
}

.graph-toggle .vm-graph-market-side .card-body {
	height: calc(100vh - 98px);
	overflow-y: auto;
}

.graph-toggle .vm-graph-market-side-st .card-body {
	/* height: calc(100vh - 175px); */
	height: calc(100vh - 205px);
	overflow-y: auto;
}

.graph-toggle .vm-graph-market-side-st.filter-applied .card-body {
	height: calc(100vh - 227px);
	/* height: calc(100vh - 196px); */
	overflow-y: auto;
}

.graph-toggle .re-graph-market-side .card-body {
	height: calc(100vh - 98px);
	overflow-y: auto;
}

.graph-toggle .ps-graph-right-side .card-body {
	height: calc(100vh - 98px);
	overflow-y: auto;
}

.graph-toggle .ps-graph-right-side.show-btn .card-body {
	height: calc(100vh - 124px);
	overflow-y: auto;
}

.graph-toggle .re-graph-portfolio-side .card-body {
	height: calc(100vh - 132px);
	overflow-y: auto;
}

/* slider css  */
.slick-next:before,
.slick-prev:before {
	color: #432135;
	opacity: 1;
	font-size: 24px;
}

.slick-next,
.slick-prev {
	top: initial;
	bottom: -32px;
}

.slick-prev {
	left: 20%;
}

.slick-next {
	right: 20%;
}

.slick-dots {
	width: 50%;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	bottom: -45px;
	height: 50px;
}

.slick-dots li {
	margin: 2px;
	width: 14px;
	height: 14px;

}

.slick-dots li button {
	padding: 0px;
	width: 14px;
	height: 14px;
}

.slick-dots li button:before {
	font-size: 12px;
	width: 14px;
	height: 14px;
	opacity: 1;
	color: #ddd;
}

.slick-dots li.slick-active button:before {
	color: #432135;
	opacity: 1;
}

.y-line {
	border-right: 1px solid #ddd;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	margin: 20px 0px 25px 0px;
	position: relative;
}

.y-line::after {
	content: "";
	position: absolute;
	width: 8px;
	height: 8px;
	border-left: 1px solid #ddd;
	border-top: 1px solid #ddd;
	top: 0;
	z-index: 2;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	left: 31.5px;
}

.y-line>span {
	color: #565656;
	background-color: #fff;
	padding-right: 10px;
	font-size: 12px;
	display: inline-block;
}

.x-line {
	border-top: 1px solid #ddd;
	display: flex;
	justify-content: flex-end;
	margin: -25px 60px 0px 35px;
	position: relative;
}

.x-line>span {
	color: #565656;
	background-color: #fff;
	padding: 5px;
	font-size: 12px;
	display: inline-block;
	margin-top: -16px;
}

.x-line::before {
	content: "";
	position: absolute;
	width: 8px;
	height: 8px;
	border-left: 1px solid #ddd;
	border-top: 1px solid #ddd;
	right: 36px;
	z-index: 2;
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	top: -4.5px;
}

.y-high {
	position: absolute;
	color: #565656;
	font-size: 12px;
	top: 50px;
	left: 50px;
}

.y-low {
	position: absolute;
	color: #565656;
	font-size: 12px;
	left: 50px;
	bottom: 80px;
}

.x-high {
	position: absolute;
	color: #565656;
	font-size: 12px;
	right: 60px;
	bottom: 80px;
}

/* .x-label{
	position: absolute;
	color: #565656;
	font-size: 12px;
	left: 50%;
	bottom: 20px;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
}
.y-label{
	position: absolute;
	color: #565656;
	font-size: 12px;
	left: -10px;
	bottom: 50%;
	transform: translateY(50%) rotate(-90deg);
	-webkit-transform: translateY(50%) rotate(-90deg);
	-moz-transform: translateY(50%) rotate(-90deg);
	-ms-transform: translateY(50%) rotate(-90deg);
	-o-transform: translateY(50%) rotate(-90deg);
} */
.oc-original-value {
	width: 10px;
	height: 10px;
	background-color: #ddd;
	display: inline-block;
	margin-right: 3px;
	vertical-align: middle;
}

.oc-new-value {
	width: 10px;
	height: 10px;
	background-color: #432135;
	display: inline-block;
	margin-right: 3px;
	vertical-align: middle;
}


/* brand roll-out css  */
.brand-roll-out .tab-content {
	width: calc(100% - 185px);
}

.brand-roll-out .graph-grid {
	width: 50%;
	flex-basis: 50%;
	height: 29vh;
	border: 1px solid #ddd;
}

.new-fun-badge {
	font-size: 10px;
	color: #fff;
	border-radius: 4px;
	background-color: #3dae15;
	display: inline-block;
	padding: 2px 3px;
	line-height: 11px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.brand-roll-out .vertical-tabs-wrapper .nav-pills {
	background: #fff;
	border: 1px solid #ddd;
	padding: 7px 0px;
}

.brand-roll-out .vertical-tabs-wrapper .nav-pills .nav-link {
	padding-left: 4px;
	padding-right: 7px;
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	-ms-border-radius: 0px;
	-o-border-radius: 0px;
	border-left: 3px solid #fff;
}

.new-formulation-case-tag {
	border-left: 3px solid #3dae15 !important;
}

/* query slider css  */
.query-slider .toast-body {
	height: 60px;
	overflow-y: auto;
	border: 1px solid #ddd;
}

/* .query-slider .slick-next:before, .query-slider .slick-prev:before{
	color: #432135;
	opacity: .4;
	font-size: 20px;
}
.query-slider .slick-next:hover:before, .query-slider .slick-prev:hover:before{
	opacity: 1;
}
.query-slider .slick-next, .query-slider .slick-prev{
	top: initial;
	bottom: 5px;
	z-index: 2;
}
.query-slider .slick-prev {
    right: 40px;
	left:initial;
}
.query-slider .slick-next {
    right: 15px;
} */

/* graph applied filter css  */
.re-graph-portfolio-side.filter-applied .card-body {
	height: calc(100vh - 396px);
}

.re-graph-market-side.filter-applied .card-body {
	height: calc(100vh - 362px);
}

.graph-column-y.bubble-graph-column-y.filter-applied {
	height: calc(100vh - 398px);
}

.html-graph-wrapper-value.bubble-graph.filter-applied {
	height: calc(100vh - 370px);
}

.graph-toggle .re-graph-portfolio-side.filter-applied .card-body {
	height: calc(100vh - 152px);
	overflow-y: auto;
}

.graph-toggle .graph-column-y.bubble-graph-column-y.filter-applied {
	height: calc(100vh - 154px);
}

.graph-toggle .re-graph-market-side.filter-applied .card-body {
	height: calc(100vh - 118px);
}

.graph-toggle .html-graph-wrapper-value.bubble-graph.filter-applied {
	height: calc(100vh - 126px);
}

.graph-column-y.filter-applied {
	height: calc(100vh - 446px);
}

.html-graph-wrapper-value.filter-applied {
	height: calc(100vh - 378px);
}

.graph-toggle .html-graph-wrapper-value.filter-applied {
	height: calc(100vh - 134px);
}

.graph-toggle .graph-column-y.filter-applied {
	height: calc(100vh - 200px);
}

.pc-graph-height.filter-applied {
	height: calc(100vh - 362px);
}

.graph-toggle .pc-graph-height.filter-applied {
	height: calc(100vh - 118px);
}

.vm-graph-portfolio-side.filter-applied .card-body {
	height: calc(100vh - 300px);
}

.vm-graph-market-side.filter-applied .card-body {
	height: calc(100vh - 266px);
}

.vm-graph-market-side-scouting.filter-applied .card-body {
	height: calc(100vh - 230px);
}

.vm-graph-market-side-st.filter-applied .card-body {
	/* height: calc(100vh - 304px); */
	height: calc(100vh - 334px);
}

.vm-graph-height.filter-applied {
	height: calc(100vh - 274px);
}

.vm-graph-height-st.filter-applied {
	height: calc(100vh - 310px);
}

.graph-toggle .vm-graph-portfolio-side .card-body {
	height: calc(100vh - 152px);
}

.graph-toggle .vm-graph-height {
	height: calc(100vh - 126px);
}

.graph-toggle .vm-graph-height-st {
	height: calc(100vh - 180px);
}

.graph-toggle .vm-graph-market-side .card-body {
	height: calc(100vh - 118px);
}



/* table sticky css  */

table.sticky-table {
	border-collapse: separate;
	border: none;
	border-spacing: 0px;
}

table.sticky-table th {
	border-top: 1px solid #cac7c7;
	border-bottom: 1px solid #cac7c7;
	border-right: 1px solid #cac7c7;
}

.table.sticky-table td,
.table.sticky-table th {
	border-right: 1px solid #cac7c7;
	border-bottom: 1px solid #cac7c7;
	border-left: none;
}

.table.sticky-table td {
	border-top: none;
}

.table.sticky-table td:first-child,
.table.sticky-table th:first-child {
	border-left: 1px solid #cac7c7;
}

table.sticky-table thead th {
	position: sticky;
	top: 0;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #dddddd;
}

.team-assessment-card {
	overflow: hidden;
}

.row .col-md-12:first-child .team-assessment-card {
	overflow: visible;
}

.table.module-3-table tbody tr td .guide-box {
	display: none;
}

.table.module-3-table tbody tr:first-child td .guide-box {
	display: block;
}

.info-pop-card {
	display: flex;
	align-items: center;
}

.info-pop-card+.info-pop-card {
	margin-top: 15px;
}

.info-pop-card .icon {
	flex-basis: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 100px;
	background-color: #f5f5f5;
	border-radius: 20px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
	padding: 12px;
}

.info-pop-card .icon img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.info-pop-card .statement {
	flex-grow: 1;
	padding: 0px 15px;
	font-size: 16px;
	line-height: 24px;
}

.brand-carrier-filter {
	cursor: pointer;
	margin-left: 3px;
	vertical-align: middle;
	font-size: 12px;
	visibility: hidden;
	color: #565656;
}

.brand-carrier-filter-wrapper:hover .brand-carrier-filter {
	visibility: visible;
}

.status-label {
	position: absolute;
	top: -30px;
	right: -1px;
	border: 1px solid #ddd;
	border-left: none;
	border-bottom: none;
	border-top-right-radius: 4px;
	padding: 4px 15px 4px 10px;
	font-weight: bold;
	color: #432135;
}

.status-label:after {
	content: '';
	height: 31px;
	width: 1px;
	background: #ddd;
	position: absolute;
	left: -7px;
	transform: rotate(25deg);
	top: -2px;
}

.suggestion-tabs .nav-link .fa-question-circle {
	opacity: .7;
	font-family: FontAwesome !important;
	font-weight: normal;
}

form label .fa-question-circle,
.st-heading .fa-question-circle,
.card .card-header .fa-question-circle,
.st-sub-heading .fa-question-circle {
	color: #565656 !important;
	opacity: .7;
	font-family: FontAwesome !important;
	font-weight: normal;
}

form label .fa-question-circle:before,
.suggestion-tabs .nav-link .fa-question-circle::before,
.st-heading .fa-question-circle::before,
.card .card-header .fa-question-circle::before,
.st-sub-heading .fa-question-circle::before {
	content: "\f29c";
}

.option-grid input {
	display: none;
}

.option-grid label {
	min-width: 170px;
	width: 170px;
	height: 140px;
	background-color: #f3f3f3;
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 15px 10px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	cursor: pointer;
	position: relative;
}

.option-grid.module-three label {
	height: auto;
	min-width: 130px;
	width: 130px;
	padding: 12px 5px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border: 2px solid #f3f3f3;
}

.option-grid input:checked+label {
	border: 2px solid #432135;
}

.option-grid label img {
	width: 60px;
	height: 50px;
	object-fit: contain;
	object-position: center center;
}

.option-grid label .name {
	font-size: 16px;
	margin-top: 10px;
	color: #565656;
}

.option-grid.module-three label .name {
	margin-top: 0px;
}

.option-grid label small {
	font-size: 12px;
	color: #565656;
}

.option-grid input:checked+label .name,
.option-grid input:checked+label small {
	color: #432135;
}

.option-grid .custom-color-tooltip {
	max-width: 200px !important;
}

.filter-separator {
	/* padding: 0px 20px; */
	padding-right: 20px;
	font-size: 20px;
	color: #ddd;
}

.state-wrapper {
	position: absolute;
	z-index: 9;
	cursor: auto;
	top: 10px;
	left: 12px;
}

.state-wrapper span {
	cursor: auto;
	color: #565656;
	opacity: .7;
	font-size: 16px;
	display: inline-block;
	margin-right: 16px;
}

.query-lg-btns {
	font-size: 14px;
	padding: 8px 10px;
}

.query-lg-btns>i {
	opacity: .4;
}

.grid-component-small.selected {
	background: #43213520;
	border: 1px solid #432143;
}

.item-box {
	width: 30px;
	height: 30px;
	border-radius: 4px;
	margin: 3px;
	position: relative;
}

.item-box:hover {
	-webkit-box-shadow: 0 1px 7px 0 rgb(69 90 100 / 24%);
	box-shadow: 0 1px 7px 0 rgb(69 90 100 / 24%);
	cursor: pointer;
	transform: scale(1.1);
}

.item-box.primary-20 {
	background: #43213520;
}

.item-box.primary-40 {
	background: #43213540;
}

.item-box.primary-60 {
	background: #43213560;
}

.item-box.primary-80 {
	background: #43213580;
}

.item-box.primary-100 {
	background: #432135;
}

.item-box.selected {
	color: #fff;
	background-color: #777;
}

.item-box.selected::after {
	content: '\f00c';
	font-family: 'FontAwesome';
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

}

.project-detail-box {
	background-color: #f5f5f5;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	padding: 15px 20px;
}

.project-detail-box p {
	margin: 0 0 0 0;
	font-size: 12px;
	letter-spacing: 0.2px;
	color: #565656;
}

.project-detail-box h5 {
	color: #000;
	margin-bottom: 0;
	font-size: 14px;
	margin-top: 3px;
}

.project-detail-box .logomark img {
	width: 60px;
}

.insight-tabs .nav-link.dropdown-toggle::after {
	margin-left: 10px;
	vertical-align: 2px;
}


/* new welcome screen  */
.welcome-wrapper .grid-wrapper-box {
	width: 260px;
	margin: 0 20px 0 20px;

}

.welcome-wrapper .grid-wrapper-box .grid-component-new {
	height: 178px;
	width: 100%;
	display: flex;
	justify-content: center;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
	background: rgba(255, 255, 255, 0.1);
	padding: 20px;
	border-radius: 20px;
}

.welcome-wrapper .grid-wrapper-box .grid-component-new:hover {
	background: rgba(255, 255, 255, 0.02);
	cursor: pointer;
}

.welcome-wrapper .grid-wrapper-box .disabled {
	opacity: 0.5;
	cursor: pointer !important;
}

.welcome-wrapper .grid-wrapper-box h4 {
	font-size: 18px;
	text-transform: capitalize;
	color: #fff;
}

.welcome-wrapper .grid-wrapper-box p small {
	color: rgba(255, 255, 255, .7);
}

.icon-wrapper-welcome {
	display: flex;
	/* justify-content: space-between; */
	width: 100%;
	flex-wrap: wrap;
	align-content: space-between;
}

.icon-wrapper-welcome .icon {
	min-width: 60px;
	width: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	border-radius: 15px;
	background-color: #fff;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	-ms-border-radius: 15px;
	-o-border-radius: 15px;
	/* cursor: pointer; */
	margin-right: 20px;
}



.icon-wrapper-welcome .icon img {
	width: 30px;
	height: 30px;
	object-fit: contain;
	object-position: center;
}

.icon-wrapper-welcome .icon.disabled {
	opacity: .3;
	/* cursor: not-allowed !important;
	pointer-events: none; */
}

.icon-wrapper-welcome .icon:nth-child(3n) {
	margin-right: 0px;
}

.blur-popup .modal-content {
	background-color: transparent;
	border-radius: 20px;
}

.blur-popup .modal-content .modal-body {
	width: 520px;
	padding: 40px;
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: 20px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
	height: 370px;
	display: flex;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
	.blur-popup .modal-content .modal-body {
		-webkit-backdrop-filter: blur(40px);
		backdrop-filter: blur(40px);
		background-color: rgba(255, 255, 255, 0.12);
	}
}

.blur-popup .icon-wrapper-welcome .icon {
	min-width: 90px;
	width: 90px;
	height: 90px;
	margin: 0 auto;
}

.blur-popup .icon-wrapper-welcome .icon:hover {
	background-color: #ddd;
	cursor: pointer;
}

.blur-popup .icon-wrapper-welcome .icon.disabled {
	background-color: #fff;
}

.blur-popup .icon-wrapper-welcome .icon img {
	width: 45px;
	height: 45px;
}

.icon-wrapper-welcome .wrapper-popup {
	width: 33.33%;
}

.icon-wrapper-welcome .wrapper-popup p {
	color: #fff;
	margin-top: 5px;
	padding: 0px 10px;
	text-align: center;
}

.project-files .tab-content>.active {
	display: flex;
	flex-direction: column;
	height: 100%;
}



table.card-style {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0px 7px;
}

.card-style tr {

	box-shadow: none;
	border: 1px solid #ddd;
	border-radius: 3px;
}

.card-style tr td {
	padding: 4px 7px;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.card-style tr td:first-child {
	border-left: 1px solid #ddd;

	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;

}

.card-style tr td:last-child {
	border-right: 1px solid #ddd;

	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;

}

.card-style tr td .card-td-name {
	display: flex;
	align-items: center;
}

.card-style tr td .card-table-cnt label {
	font-size: 14px;
	letter-spacing: 0.2px;
	color: #565656;
	margin: 0;
	white-space: nowrap;
}

.card-style tr td .card-table-cnt p {
	font-size: 16px;
}

.graph-nav-expend {
	display: none !important;
}

.graph-toggle .graph-nav-expend {
	display: flex !important;
}

#fullScreen .insight-top-bar .btn-group {
	width: auto;
}

.selectedFullScreenGraph {
	border: 1px solid #432135;
}

/*Shiv Designer Started from here*/
.fillters-fixed-height {
	max-height: 80px;
	overflow: scroll;
}

/*By Shiv*/

.player-progress-bar {
	display: flex;
	justify-content: space-between;
}

.opportunity-progress-bar {
	width: 80%;
}

p.progress-bar-lable {
	background-color: #432135;
	height: 26px;
	width: 26px;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
}

.player-progress-bar .btn-filter {
	background: transparent;
}

.tooltipOverlap thead {
	z-index: 0;
	position: relative;
}


.problem-statement-guide {
	top: -54px;
	left: 132px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}


.problem-statement-guide.fadeup-animation {
	top: -54px;
	left: 132px;
	opacity: 1;
	visibility: visible;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.upload-file-guide {
	top: 23px;
	right: 0;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}


.upload-file-guide.fadeup-animation {
	top: 23px;
	right: 0;
	opacity: 1;
	visibility: visible;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.key-chatchwords-guide {
	top: -59px;
	left: 171px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}


.key-chatchwords-guide.fadeup-animation {
	top: -59px;
	left: 171px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.preferred-solution-guide {
	top: 26px;
	left: 0;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}






.preferred-solution-guide.fadeup-animation {
	top: 26px;
	left: 0;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}


.key-solution-guide {
	top: -59px;
	left: 184px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}


.key-solution-guide.fadeup-animation {
	top: -59px;
	left: 184px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}


.save-opportunity-guide2 {
	right: 65px;
	bottom: 41px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.save-opportunity-guide2.fadeup-animation {
	right: 65px;
	bottom: 41px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.submit-btn-guide {
	top: -150px;
	right: 16px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.submit-btn-guide.fadeup-animation {
	top: -150px;
	right: 16px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.navbar-inner .sub-nav-group button img {
	width: 17px;
	margin: 0 9px;
	filter: brightness(0) invert(1);
	-webkit-filter: brightness(0) invert(1);
	;
}

.filter button img {
	width: 17px;
	margin: 0 9px;
	filter: brightness(0) invert(1);
	-webkit-filter: brightness(0) invert(1);
	;
}



.navbar-inner .sub-nav-group button.active img {
	filter: brightness(0) invert(0);
	-webkit-filter: brightness(0) invert(0);
}


.header-option-area {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.header-search {
	position: relative;
	margin-right: 5px;
}


.opportunities-name-guide {
	top: -33px;
	left: 165px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.opportunities-name-guide.fadeup-animation {
	top: -33px;
	left: 165px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.relevance-percentage-guide {
	top: 50px;
	right: 113px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.relevance-percentage-guide.fadeup-animation {
	top: 50px;
	right: 113px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.view-details-guide {
	right: 34px;
	top: 44px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.view-details-guide.fadeup-animation {
	right: 34px;
	top: 44px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.view-matrix-guide {
	right: -4px;
	transition: all 0.25s linear;
	bottom: -153px;
}

.view-matrix-guide.fadeup-animation {
	right: -4px;
	bottom: -153px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.weightage-optimizer-guide {
	right: 13px;
	transition: all 0.25s linear;
	bottom: -115px;
}

.weightage-optimizer-guide.fadeup-animation {
	right: 13px;
	bottom: -115px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.save-opportunity-guide {
	right: 30px;
	top: 36px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.save-opportunity-guide.fadeup-animation {
	right: 30px;
	top: 36px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.export-button-guide {
	left: 28px;
	bottom: 41px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
}

.export-button-guide.fadeup-animation {
	left: 28px;
	bottom: 41px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.vault-button-guide {
	left: 0px;
	bottom: 41px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.vault-button-guide.fadeup-animation {
	left: 0px;
	bottom: 41px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.project-collection-button-guide {
	right: 65px;
	bottom: 41px;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.project-collection-button-guide.fadeup-animation {
	right: 65px;
	bottom: 41px;
	opacity: 1;
	visibility: visible !important;
	transition: all 0.25s linear;
	-webkit-transition: all 0.25s linear;
	-o-transition: all 0.25s linear;
	-moz-transition: all 0.25s linear;
	-ms-transition: all 0.25s linear;
}

.table-card-height {
	height: 62vh;
	max-height: 62vh;
	Overflow-y: scroll;
	Overflow-x: hidden;
}

.round-checkbox input[type="checkbox"]:disabled+label {
	background-color: #432135;
	cursor: no-drop;
}

/* .keySolutionTable .card-style tr td:first-child{
	vertical-align: baseline;
}

.keySolutionTable .card-style tr td .card-td-name{
	align-items: flex-start;
} */

.keySolutionGrid tr td .card-td-name {
	align-items: flex-start;
}

.keySolutionGrid tr td {
	vertical-align: top;
}

.dataDetailScouting {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.card-style-button {
	width: 100%;
	max-width: 250px;
	height: 180px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	font-size: 22px;
	flex-direction: column;
	overflow: hidden;
	overflow-wrap: break-word;
	white-space: normal;
}

.card-style-button>i {
	font-size: 30px;
	margin-bottom: 10px;
}

/*scouting by nav*/
.scouting-opp-height {
	height: calc(100vh - 260px);
	width: 100%;
	overflow-y: auto;
	overflow-x: auto;
}

.scouting-opp-height.filter-applied {
	height: calc(100vh - 295px);
	width: 100%;
	overflow-y: auto;
	overflow-x: auto;
}

.scouting-opp-height-vault {
	height: calc(100vh - 225px);
	width: 100%;
	overflow-y: auto;
	overflow-x: auto;
}

.scouting-details {
	height: calc(100vh - 250px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.scouting-details-vault {
	height: calc(100vh - 140px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.strategic-details {
	height: calc(100vh - 140px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.strategic-saved {
	height: calc(100vh - 225px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.strategic-saved-vault {
	height: calc(100vh - 200px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.formulaOutcome {
	height: calc(100vh - 143px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.formulaOutcome1 {
	height: calc(100vh - 185px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}


.formulaGraph {
	height: calc(100vh - 410px);
	position: relative;
}

.lineTruncate {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.table-rollOut {
	height: calc(100vh - 210px);
	position: relative;
	overflow: auto;
	display: block;
}

.disableDiv {
	pointer-events: none;
	opacity: 0.3;
}

.ps-graph-height-vault {
	height: calc(100vh - 210px);
	position: relative;
}

.ps-graph-height-vault.filter-applied {
	height: calc(100vh - 230px);
	position: relative;
}

.ps-graph-right-side1 .card-body {
	height: calc(100vh - 185px);
	overflow-y: auto;
	overflow-x: hidden;
}

.ps-graph-right-side1.show-btn .card-body {
	height: calc(100vh - 215px);
	overflow-y: auto;
	overflow-x: hidden;
}

.graph-height-vault {
	height: calc(100vh - 215px);
	position: relative;
}

.graph-height-vault.filter-applied {
	height: calc(100vh - 235px);
	position: relative;
}

.graph-height-demoSub-vault {
	height: calc(100vh - 245px);
	position: relative;
}

.opportunity-capability-eco-vault {
	height: calc(100vh - 200px);
	width: 100%;
	overflow: hidden;
}

.opportunity-capability-eco-vault.filter-applied {
	height: calc(100vh - 220px);
	width: 100%;
	overflow: hidden;
}

.opportunity-capability-eco-comside-vault .card-body {
	height: calc(100vh - 170px);
	overflow-y: auto;
	overflow-x: hidden;
}


/*---Navneet New 11-07-2023*/
.btnSelectDeselect {
	height: 26px;
	padding: 2px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;
	border: none;
	color: #777;
	cursor: pointer;
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #fff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.btnSelectDeselect.selected {
	background-color: #432135;
	color: #fff;
	border-color: #432135;
}

.btnSelectDeselect:hover,
.btnSelectDeselect:focus {
	color: #000;
	outline: none;
	box-shadow: none;
}

.btnSelectDeselect.selected:hover,
.btnSelectDeselect.selected:focus {
	color: #fff;
	outline: none;
	box-shadow: none;
}


.pagination-container {
	display: flex;
	list-style-type: none;
	justify-content: center;
	margin-top: 2px;
}

.pagination-container .pagination-item {
	padding: 0 12px;
	height: 32px;
	text-align: center;
	margin: auto 4px;
	color: #847979;
	/* color: rgba(0, 0, 0, 0.87); */
	display: flex;
	box-sizing: border-box;
	align-items: center;
	letter-spacing: 0.01071em;
	border-radius: 16px;
	line-height: 1.43;
	font-size: 13px;
	min-width: 32px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	-ms-border-radius: 16px;
	-o-border-radius: 16px;
}

.pagination-container .pagination-item.dots:hover {
	/* color: #421c32; */
	/* //background-color: transparent; */
	cursor: default;
}

.pagination-container .pagination-item:hover {
	color: #421c32;
	/* background-color: rgba(0, 0, 0, 0.04); */
	cursor: pointer;
}

.pagination-container .pagination-item.selected {
	color: #421c32;
	/* background-color: rgba(0, 0, 0, 0.08); */
	text-decoration: underline;
}


.arrow::before {
	position: relative;
	/* top: 3pt; Uncomment this to lower the icons as requested in comments*/
	content: '';
	/* By using an em scale, the arrows will size with the font */
	display: inline-block;
	width: 0.4em;
	height: 0.4em;
	border-right: 0.12em solid rgba(0, 0, 0, 0.87);
	border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.arrow.left {
	transform: rotate(-135deg) translate(-50%);
	-webkit-transform: rotate(-135deg) translate(-50%);
	-moz-transform: rotate(-135deg) translate(-50%);
	-ms-transform: rotate(-135deg) translate(-50%);
	-o-transform: rotate(-135deg) translate(-50%);
}

.arrow.right {
	transform: rotate(45deg);
}

.pagination-item.disabled {
	pointer-events: none;
}

.pagination-item.disabled .arrow::before {
	border-right: 0.12em solid rgba(0, 0, 0, 0.43);
	border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-item:hover {
	background-color: transparent;
	cursor: default;
}


.adhocSolutions {
	max-height: calc(100vh -190px);
}

.adhocSolutions1 {
	max-height: calc(100vh - 190px);
	overflow-y: auto;
}

.inquiries .card-body {
	height: calc(100vh - 225px);
	overflow-y: auto;
	overflow-x: hidden;
}

.unactiveSort {
	-webkit-filter: brightness(0) invert(0.6);
}

.consumerTable {
	height: calc(100vh - 360px);
	overflow-y: auto;
}

.consumerTable1 {
	height: calc(100vh - 150px);
	overflow-y: auto;
}

.consumerFilter {
	height: calc(100vh - 290px);
	overflow-y: auto;
	overflow-x: hidden;
}

.consumerFilter-vault {
	height: calc(100vh - 210px);
	overflow-y: auto;
	overflow-x: hidden;
}

.consumerFilter-graph {
	height: calc(100vh - 335px);
	overflow-y: auto;
	overflow-x: hidden;
}

.graph-toggle .consumerFilter-graph {
	height: calc(100vh - 115px);
	overflow-y: auto;
	overflow-x: hidden;
}


.brandNames {
	height: calc(100vh - 260px);
	overflow-y: auto;
	overflow-x: hidden;
}

.graph-height-consumer {
	height: calc(100vh - 350px);
	position: relative;
}

.graph-height-consumer.filter-applied {
	height: calc(100vh - 375px);
	position: relative;
}

.graph-height-consumer-vault {
	height: calc(100vh - 215px);
	position: relative;
}

.graph-height-consumer-vault.filter-applied {
	height: calc(100vh - 235px);
	position: relative;
}

.graph-toggle .graph-height-consumer {
	/* height: calc(100vh - 102px); */
	height: calc(100vh - 120px);
}

.graph-toggle .graph-height-consumer.filter-applied {
	height: calc(100vh - 140px);
	/* height: calc(100vh - 128px); */
}

.remove-btn1 {
	position: absolute;
	bottom: 5px;
	font-size: 9px;
	padding: 2px;
	right: 0px;
	top: 0px;
	color: #fff;
	background-color: #ff0000;
	cursor: pointer;
	width: 13px;
	height: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: initial;
}

.graph-height-scouting {
	height: calc(100vh - 270px);
	position: relative;
}

.graph-height-scouting.filter-applied {
	height: calc(100vh - 300px);
	position: relative;
}

.graph-height-scouting-vault {
	height: calc(100vh - 215px);
	position: relative;
}

.graph-height-scouting-vault.filter-applied {
	height: calc(100vh - 235px);
	position: relative;
}

.graph-toggle .graph-height-scouting {
	/* height: calc(100vh - 102px); */
	height: calc(100vh - 120px);
}

.graph-toggle .graph-height-scouting.filter-applied {
	height: calc(100vh - 140px);
	/* height: calc(100vh - 128px); */
}

/*new*/
.chat-icon {
	/*position: fixed;
	*/
	/*bottom: 20px;
	right: 20px;
	*/
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #432135;
	color: white;
	border: none;
	/*font-size: 28px;
	*/
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	transition: transform 0.3s ease;
	-webkit-border-radius: ;
	-moz-border-radius: ;
	-ms-border-radius: ;
	-o-border-radius: ;
	-webkit-transition: transform 0.3s ease;
	-moz-transition: transform 0.3s ease;
	-ms-transition: transform 0.3s ease;
	-o-transition: transform 0.3s ease;
}

.chat-icon.disable {
	opacity: 0.5;

}

.chat-icon.enable:hover {
	transform: scale(1.1);
}

.chat-icon.disable:hover {
	cursor: not-allowed;

}

.chat-window {
	position: fixed;
	width: 90%;
	height: 80%;
	display: flex;
	border: 1px solid #ddd;
	background: #ffffff;
	flex-direction: column;
	font-size: 13px;
	overflow: hidden;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
	z-index: 999999;
	/*display: none;
	position: fixed;
	bottom: 10px;
	right: 10px;
	width: 400px;
	height: 500px;
	border: 1px solid #ddd;
	border-radius: 12px;
	/*display: flex;
	flex-direction: column;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.6);
	font-size: 13px;
	overflow: hidden;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
	background: white;
	:;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);*/
}

.chat-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 10px;
	/* background-color: #3e1e35;
	color: white; */
	/* border-top-left-radius: 12px;
	border-top-left-radius: 12px; */
}

.chat-header h3 {
	margin: 0;
	font-size: 18px;
	font-weight: 400;
}

.close-button {
	background: none;
	border: none;
	border-radius: 0;
	color: white;
	font-size: 22px;
	cursor: pointer;
	transition: opacity 0.3s ease;
	padding-top: 0px;
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
}

.close-button:hover {
	opacity: 0.8;
}

.chat-history {
	width: 250px;
	min-width: 250px;
	background-color: #ddd;
	display: flex;
	flex-direction: column;
}
.chat-history.nonActive{
	width: 150px;
	min-width: 150px;
}

.history-list {
	flex-grow: 1;
	overflow-y: auto;
	padding: 10px;
	border-radius: 0px !important;
}

.history-item {
	/* padding: 10px; */
	margin-bottom: 5px;
	/* background-color: white; */
	border-radius: 5px;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: background-color 0.2s ease;
}

.history-item.deleteBotHistory{
	display: none;
}

.history-item:hover {
	background-color: #e9ecef;
	display: block;
}

.history-item:hover .deleteBotHistory {
	display: block;
  }

.history-item.active {
	background-color: #ffffff;
	display: block;
	/* color: white; */
}



.chat-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	/* height: 100%; */
	overflow: hidden;
}




.chat-messages {
	display: flex;
	flex-direction: column-reverse;
	flex-grow: 1;
	padding: 10px;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #888 #f1f1f1;
}

.chat-messages::-webkit-scrollbar {
	width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.chat-messages::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
}

.chat-message {
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;
	position: relative;
	animation: fadeIn 0.3s ease-in-out;
	-webkit-animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);
		-moz-transform: translateY(10px);
		-ms-transform: translateY(10px);
		-o-transform: translateY(10px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
	}
}

.chat-message.bot {
	flex-direction: row;
	padding-left: 40px;
}

.chat-message.user {
	flex-direction: row-reverse;
	padding-right: 40px;
}

.chat-avatar {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	position: absolute;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.chat-avatar.bot {
	left: 0;
	width: 50px;
	height: 50px;
}

.chat-avatar.user {
	right: 0;
}

.message-content {
	padding: 10px 12px;
	border-radius: 18px;
	/* max-width: 80%; */
	/* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
	word-wrap: break-word;
	overflow-wrap: break-word;
	hyphens: auto;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
	-ms-border-radius: 18px;
	-o-border-radius: 18px;
}


.chat-message.bot .message-content {
	margin-right: 36px;
	/* background-color: #e1f5fe;
	border-bottom-left-radius: 5px; */
}

.chat-message.user .message-content {
	margin-left: 36px;
	background-color: #dddddd;
	/* color: white; */
	border-bottom-right-radius: 5px;
}

.chat-input {
	display: flex;
	padding: 10px;
	background-color: #f5f5f5;
	border-top: 1px solid #ddd;
}

.chat-input textarea {
	flex-grow: 1;
	padding: 10px 15px;
	border: none;
	border-radius: 10px;
	font-size: 12px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	resize: none;
	overflow-y: auto;
	min-height: 15px;
	max-height: 100px;
	width: 100px;
	font-family: Arial, Helvetica, sans-serif;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.chat-input button {
	background-color: #3e1e35;
	border: none;
	border-radius: 10%;
	width: 50px;
	height: 40px;
	margin-left: 10px;
	color: white;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.3s ease;
	-webkit-transition: background-color 0.3s ease;
	-moz-transition: background-color 0.3s ease;
	-ms-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	-webkit-border-radius: 10%;
	-moz-border-radius: 10%;
	-ms-border-radius: 10%;
	-o-border-radius: 10%;
}

.chat-input button:hover {
	background-color: #2e1625;
}

.bot-info {
	display: flex;
	align-items: center;
}

.bot-avatar-container {
	position: relative;
	margin-right: 10px;
}

.bot-avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	filter: brightness(0) invert(1);
	-webkit-filter: brightness(0) invert(1);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.online-indicator {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 5px;
	height: 5px;
	background-color: #008000;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.header-actions {
	display: flex;
	align-items: start;
}

.menu-container {
	position: relative;
	margin-right: 10px;
	margin-top: -1px;
}

.menu-button {
	background: none;
	border: none;
	color: white;
	font-size: 24px;
	cursor: pointer;
	padding: 0px;
}

.hamburger-icon {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 16px;
	height: 13px;
}

.hamburger-icon span {
	display: block;
	width: 100%;
	height: 2px;
	background-color: white;
	transition: all 0.3s ease;
}

.menu-button:hover .hamburger-icon span {
	background-color: #e0e0e0;
}

.truncatedToolTip {
	position: "absolute";
	left: "0";
	background-color: "#000000";
	color: "#ffffff";
	border: "1px solid #ccc";
	padding: "5px";
	max-height: "100px";
	z-index: 99999;
	overflow-y: "auto";
	min-width: "300px";
	max-width: "400px";
	word-wrap: "break-word";
	box-shadow: "0 1px 2.94px 0.06px rgb(4 26 55 / 16%)";
}



.deleteBotHistory:hover {
cursor: pointer;
	transform: scale(1.1);
}